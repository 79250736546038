// React

// Components
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

// @mui material components
import { Card, CircularProgress, Icon, Tab, Tabs } from "@mui/material";

import { gql, useMutation } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import dayjs from "dayjs";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cotizacion from "../components/Cotizacion";
import Fases from "../components/Fases";
import ProyectoDetalles from "../components/ProyectoDetalles";
import ModalEnviarPresupuesto from "../components/ModalEnviarPresupuesto";
import ModalMensajeFinal from "../components/ModalMensajeFinal";

// Data

function NuevoProyecto() {
  const [proyecto, setProyecto] = useState();
  const [presupuesto,setPresupuesto] = useState();
  const [proyectoOld, setProyectoOld] = useState();
  const [openModalPresupuestoCreado, setOpenModalPresupuestoCreado] = useState(false);
  const [openModalEnviarPresupuesto, setOpenModalEnviarPresupuesto] = useState(false);
  const [openModalPresupuestoEnviado, setOpenModalPresupuestoEnviado] = useState(false);
  const { handleSnackbar } = useContext(MessageManager);
  const [modalMensajeFinal,setModalMensajeFinal] = useState(false);
  const [textoFinal,setTextoFinal] = useState({
    titulo: "",
    descripcion: "",
  });

  const [step, setStep] = useState(0);
  const steps = [
    {
      title: "Detalles del proyecto",
      icon: "description",
    },
    {
      title: "Fases",
      icon: "format_list_numbered",
    },
    {
      title: "Cotización",
      icon: "price_check",
    },
  ];
  const navigate = useNavigate();

  const [saveProyecto, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveProyecto($input: ProyectoInput!) {
        saveProyecto(input: $input) {
          id
        }
      }
    `
  );

  const [saveProyectoAll, { loading: loadingSaveAll }] = useMutation(
    gql`
      mutation saveProyectoAll($input: ProyectoAllInput) {
        saveProyectoAll(input: $input){
          proyecto{
            id
          }
        }
      }
    `
  );
  
  const formatoManoDeObra = (manoDeObra) => {
    let manoDeObraFormateada = [];
    manoDeObra?.forEach((item) => {
      manoDeObraFormateada.push({
        nombre: item?.nombre ? item?.nombre : null,
        horas: item?.horas ? parseFloat(item?.horas) : null,
        tipoMoneda: item?.tipoMoneda ? item?.tipoMoneda : null,
        precio: item?.precio ? parseFloat(item?.precio) : null,
        cotizacion: item?.cotizacion ? parseFloat(item?.cotizacion) : 0,
      })
    });

    return manoDeObraFormateada;
  }

  const formatoMateriales = (materiales)=>{
    let materialesFormateados = [];
    materiales?.forEach((item) => {
      materialesFormateados.push({
        idProducto: item?.producto ? item?.producto?.id : null,
        nombre: item?.nombre ? item?.nombre : item?.producto?.nombre ? item?.producto?.nombre : null,
        cantidad: item?.cantidad ? item?.cantidad : null,
        tipoMoneda: item?.tipoMoneda ? item?.tipoMoneda : null,
        precio: item?.precio ? parseFloat(item?.precio) : null,
        cotizacion: item?.cotizacion ? parseFloat(item?.cotizacion) : 0,
      })
    });

    return materialesFormateados;
  }

  const formatoConcepto = (conceptos)=>{
    let conceptosFormateados = [];
    conceptos?.forEach((item) => {
      conceptosFormateados.push({
        nombre: item?.nombre ? item?.nombre : null,
        tipoMoneda: item?.tipoMoneda ? item?.tipoMoneda : null,
        precio: item?.precio ? parseFloat(item?.precio) : null,
        cotizacion: item?.cotizacion ? parseFloat(item?.cotizacion) : 0,
      })
    });
    return conceptosFormateados;
  }

  const formatoPresupuesto = (presupuestos)=>{
    let presupuestosFormateados = [];
    presupuestos?.forEach((item) => {
      presupuestosFormateados.push({
        fechaPago: item?.fechaPago ? item?.fechaPago : null,
        tipoMoneda: item?.tipoMoneda ?item?.tipoMoneda : null,
        precio: item?.precio ? parseFloat(item?.precio) : null,
        cotizacion: item?.cotizacion ? parseFloat(item?.cotizacion) : null,
        medioPago: item?.medioPago ? item?.medioPago : null,
        observacionPago: item?.observacionPago ? item?.observacionPago : null,
        montoAnticipo: item?.montoAnticipo ? parseFloat(item?.montoAnticipo) : null,
        porcentajeAnticipo: item?.porcentajeAnticipo ? parseFloat(item?.porcentajeAnticipo) : null,
        nroPresupuesto: item?.nroPresupuesto ? item?.nroPresupuesto : null,
        idProveedor: item?.nombre ? item?.nombre?.id : null,
        nombre: item?.nombre?.razonSocial ? item?.nombre?.razonSocial : item?.nombre?.nombre ? item?.nombre?.nombre+" "+item?.nombre?.apellido : null,
        motivoAprobacion: item?.motivoAprobacion ? item?.motivoAprobacion : null,
        observaciones: item?.observaciones ? item?.observaciones : null,
        file: item?.file ? item.file : null,
        aprobado: item?.aprobado ? item?.aprobado : false,
      })
    });
    return presupuestosFormateados;
  }

  const handleSave = () => {
        
    let formatoFases = [];
    if(proyecto?.fases?.length > 0){
      proyecto?.fases?.forEach((fase) => {
        formatoFases.push({
          nombre: fase?.nombre ?? null,
          descripcion: fase?.descripcion ?? null,
          nombreUnidad: fase?.nombreUnidad ?? null, 
          fechaInicioEstimado: fase?.fechaInicioEstimado ?? null,
          fechaFinEstimado: fase?.fechaFinEstimado ?? null,
          cantidad: fase?.cantidad ? Number(fase?.cantidad) : null,
          porcentajeGanancia: fase?.porcentajeGanancia ? parseFloat(fase?.porcentajeGanancia) : null,
          subtotal: fase?.subtotal ?? null,
          numero: null,
          idEtapa: fase?.idEtapa ?? null,
          manoDeObra: fase?.manoDeObra?.length > 0 ? formatoManoDeObra(fase?.manoDeObra) : [],
          materiales: fase?.materiales?.length > 0 ? formatoMateriales(fase?.materiales) : [],
          concepto: fase?.concepto?.length > 0 ? formatoConcepto(fase?.concepto) : [],
          presupuesto: fase?.presupuesto?.length > 0 ? formatoPresupuesto(fase?.presupuesto) : [],
        })
      });
    }
    
    const infoProyecto = {
      proyecto: {
        nombre: proyecto?.nombre ? proyecto?.nombre : "",
        idCliente: proyecto?.idCliente ? proyecto?.idCliente : null,
        tipo: proyecto?.tipo ? proyecto?.tipo : null,
        idVendedor: proyecto?.idVendedor ? proyecto?.idVendedor : null,
        idResponsable: proyecto?.idResponsable ? proyecto?.idResponsable : null,
        idLocalidad: proyecto?.idLocalidad ? proyecto?.idLocalidad : null,
        idProvincia: proyecto?.idProvincia ? proyecto?.idProvincia : null,
        idSucursal: proyecto?.idSucursal ? proyecto?.idSucursal : null,
        direccion: proyecto?.direccion ?  proyecto?.direccion : "",
        descripcion: proyecto?.tipo ? proyecto?.tipo : "",
        estado: "Pendiente",
        costoEstimado: null,
        tipoMoneda: proyecto?.tipoMoneda ? proyecto?.tipoMoneda : null,
        cotizacion: proyecto?.cotizacion ? parseFloat(proyecto?.cotizacion) : 0,
      },
      etapas: proyecto?.etapas,
      fases: formatoFases,
      presupuesto: {
        ...presupuesto,
        version: 1,
      },
    }

    saveProyectoAll({
      variables: {
        input: {
          ...infoProyecto,
        },
      },
    })
    .then(() => {
      // setOpenModalPresupuestoCreado(true);
      setTextoFinal({
        titulo: "Proyecto creado con éxito!",
        descripcion: "El proyecto fue creado correctamente, puedes ver el estado en la sección de 'Proyectos'.",
      });
      setModalMensajeFinal(true);
    })
    .catch((e) => {
      console.log(e);
      handleSnackbar("Error al guardar el proyecto", "error");
    }).finally(() => {
      setOpenModalEnviarPresupuesto(false);
    });
  };

  const saveAndSend = () => {

    let formatoFases = [];
    if(proyecto?.fases?.length > 0){
      proyecto?.fases?.forEach((fase) => {
        formatoFases.push({
          nombre: fase?.nombre ?? null,
          descripcion: fase?.descripcion ?? null,
          nombreUnidad: fase?.nombreUnidad ?? null, 
          fechaInicioEstimado: fase?.fechaInicioEstimado ?? null,
          fechaFinEstimado: fase?.fechaFinEstimado ?? null,
          cantidad: fase?.cantidad ? Number(fase?.cantidad) : null,
          porcentajeGanancia: fase?.porcentajeGanancia ? parseFloat(fase?.porcentajeGanancia) : null,
          subtotal: fase?.subtotal ?? null,
          numero: null,
          idEtapa: fase?.idEtapa ?? null,
          manoDeObra: fase?.manoDeObra?.length > 0 ? formatoManoDeObra(fase?.manoDeObra) : [],
          materiales: fase?.materiales?.length > 0 ? formatoMateriales(fase?.materiales) : [],
          concepto: fase?.concepto?.length > 0 ? formatoConcepto(fase?.concepto) : [],
          presupuesto: fase?.presupuesto?.length > 0 ? formatoPresupuesto(fase?.presupuesto) : [],
        })
      });
    }
    
    const infoProyecto = {
      proyecto: {
        nombre: proyecto?.nombre ? proyecto?.nombre : "",
        idCliente: proyecto?.idCliente ? proyecto?.idCliente : null,
        tipo: proyecto?.tipo ? proyecto?.tipo : null,
        idVendedor: proyecto?.idVendedor ? proyecto?.idVendedor : null,
        idResponsable: proyecto?.idResponsable ? proyecto?.idResponsable : null,
        idLocalidad: proyecto?.idLocalidad ? proyecto?.idLocalidad : null,
        idProvincia: proyecto?.idProvincia ? proyecto?.idProvincia : null,
        idSucursal: proyecto?.idSucursal ? proyecto?.idSucursal : null,
        direccion: proyecto?.direccion ?  proyecto?.direccion : "",
        descripcion: proyecto?.tipo ? proyecto?.tipo : "",
        estado: "Pendiente",
        costoEstimado: null,
        tipoMoneda: proyecto?.tipoMoneda ? proyecto?.tipoMoneda : null,
        cotizacion: proyecto?.cotizacion ? parseFloat(proyecto?.cotizacion) : 0,
      },
      etapas: proyecto?.etapas,
      fases: formatoFases,
      presupuesto: {
        ...presupuesto,
        version: 1,
      },
    }

    saveProyectoAll({
      variables: {
        input: {
          ...infoProyecto,
        },
      },
    })
    .then(() => {
      // setOpenModalPresupuestoEnviado(true);
      setTextoFinal({
        titulo: "Presupuesto enviado con éxito!",
        descripcion: "El proyecto fue creado y enviado correctamente, puedes ver el estado en la sección de 'Proyectos'.",
      });
      setModalMensajeFinal(true);
    })
    .catch((e) => {
      console.log(e);
      handleSnackbar("Error al guardar el proyecto", "error");
    }).finally(() => {
      setOpenModalEnviarPresupuesto(false);
    });
    
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox p={3}>
              <SoftBox pb={3}>
                {step === 0 && (
                  <ProyectoDetalles proyecto={proyecto} setProyecto={setProyecto} large />
                )}
                {step === 1 && <Fases proyecto={proyecto} setProyecto={setProyecto} large />}
                {step === 2 && <Cotizacion presupuesto={presupuesto} setPresupuesto={setPresupuesto} large />}
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between" mt={2}>
                <SoftBox
                  mr={2}
                  sx={{
                    width: {
                      sm: "100%",
                      xl: "60%",
                      xxl: "50%",
                    },
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  }}
                >
                  <Tabs
                    value={step}
                    onChange={(e, value) => {
                      value <= step ? setStep(value) : null;
                    }}
                  >
                    {steps.map((thisStep, index) => (
                      <Tab
                        key={index}
                        icon={
                          <Icon
                            fontSize="small"
                            color={step === index ? "primary" : "dark"}
                            sx={{
                              verticalAlign: "middle",
                            }}
                          >
                            {thisStep.icon}
                          </Icon>
                        }
                        iconPosition="start"
                        label={
                          <SoftTypography
                            variant="body2"
                            fontWeight={step === index ? "bold" : "normal"}
                          >
                            {thisStep.title}
                          </SoftTypography>
                        }
                        sx={{
                          cursor: step < index ? "default" : "pointer",
                        }}
                      />
                    ))}
                  </Tabs>
                </SoftBox>
                <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="end"
                  alignItems="center"
                >
                  <SoftBox
                    mr={{
                      xs: 0,
                      sm: 2,
                    }}
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftButton
                      color="primary"
                      circular
                      onClick={step === 0 ? () => navigate("/proyectos") : () => setStep(step - 1)}
                      fullWidth={{
                        xs: true,
                        sm: false,
                      }}
                      disabled={loadingSaveAll}
                    >
                      <Icon sx={{ fontWeight: "light" }}>
                        {step === 0 ? "close" : "arrow_back"}
                      </Icon>
                      &nbsp;{step === 0 ? "Cancelar" : "Volver"}
                    </SoftButton>
                  </SoftBox>
                  {step === 2 && (
                    <SoftBox
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                    mr={{
                      xs: 0,
                      sm: 2,
                    }}
                  >
                    <SoftButton
                      color="sistemasGris"
                      circular
                      fullWidth={{
                        xs: true,
                        sm: false,
                      }}
                      onClick={()=>setOpenModalEnviarPresupuesto(true)}
                      disabled={
                        (proyectoOld && !hayCambios(proyectoOld, proyecto)) ||
                        loadingSave ||
                        loadingSaveAll ||
                        !proyecto?.nombre ||
                        !proyecto?.tipo ||
                        !proyecto?.tipoMoneda ||
                        !proyecto?.cotizacion ||
                        (proyecto.tipo === "Cliente" && !proyecto?.idCliente) ||
                        (proyecto?.fechaFinEstimada &&
                          proyecto?.fechaInicioEstimada &&
                          dayjs(proyecto?.fechaInicioEstimada).isAfter(
                            dayjs(proyecto?.fechaFinEstimada)
                          )) ||
                        (proyecto?.fechaFinEstimada && !proyecto?.fechaInicioEstimada) ||
                        (presupuesto?.ocultarFases && !presupuesto?.notasExtra) ||
                        (proyecto?.etapas?.some((etapa) => proyecto?.fases?.every((fase) => fase?.idEtapa !== etapa?.id))) ||
                        (proyecto?.etapas?.some((etapa)=> !etapa?.nombre))
                      }
                    >
                      Visualizar y Enviar&nbsp;
                      {loadingSave ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : (
                        <Icon sx={{ fontWeight: "light" }}>
                          send
                        </Icon>
                      )}
                    </SoftButton>
                  </SoftBox>
                  )}
                  <SoftBox
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftButton
                      color="sistemasGris"
                      circular
                      fullWidth={{
                        xs: true,
                        sm: false,
                      }}
                      onClick={step === 2 ? handleSave : () => setStep(step + 1)}
                      disabled={
                        (proyectoOld && !hayCambios(proyectoOld, proyecto)) ||
                        loadingSaveAll ||
                        !proyecto?.nombre ||
                        !proyecto?.tipo ||
                        !proyecto?.tipoMoneda ||
                        !proyecto?.cotizacion ||
                        (proyecto.tipo === "Cliente" && !proyecto?.idCliente) ||
                        (proyecto?.fechaFinEstimada &&
                          proyecto?.fechaInicioEstimada &&
                          dayjs(proyecto?.fechaInicioEstimada).isAfter(
                            dayjs(proyecto?.fechaFinEstimada)
                          )) ||
                        (proyecto?.fechaFinEstimada && !proyecto?.fechaInicioEstimada) ||
                        (step === 1 && (!proyecto?.fases || proyecto?.fases?.length == 0)) || 
                        (step === 1 && proyecto?.fases?.length > 0 && proyecto?.fases?.some((fase) => (fase?.cantidad && fase?.cantidad <= 0 ))) ||
                        (step === 1 && proyecto?.fases?.length > 0 && proyecto?.fases?.some((fase) => (fase?.nombre == "" || fase?.nombre?.trim() == ""))) ||
                        (presupuesto?.ocultarFases && !presupuesto?.notasExtra) ||
                        (proyecto?.etapas?.some((etapa) => proyecto?.fases?.every((fase) => fase?.idEtapa !== etapa?.id))) ||
                        (proyecto?.etapas?.some((etapa)=> !etapa?.nombre))
                      }
                    >
                     {step === 2 ? "Guardar" : "Siguiente"} &nbsp;
                      {loadingSaveAll ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : (
                        <Icon sx={{ fontWeight: "light" }}>
                          {step === 2 ? "save" : "arrow_forward"}
                        </Icon>
                      )}
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
        <ModalMensajeFinal
        open={modalMensajeFinal}
        handleClose={() =>{
          setModalMensajeFinal(false);
          setTextoFinal({
            titulo: "",
            descripcion: "",
          });
        }}
        titulo={textoFinal?.titulo}
        descripcion={textoFinal?.descripcion}
        />
        <ModalEnviarPresupuesto 
        open={openModalEnviarPresupuesto} 
        handleClose={() => setOpenModalEnviarPresupuesto(false)} 
        proyecto={proyecto}
        presupuesto={presupuesto}
        guardarEnviar={saveAndSend}
        />
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NuevoProyecto;
