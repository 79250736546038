// Kaizen Dashboard layouts
import ErrorHandler from "components/ErrorHandler";
import SignIn from "layouts/authentication/sign-in";
import Compras from "layouts/compras";
import Proyectos from "layouts/proyectos";
import NuevoProyecto from "layouts/proyectos/NuevoProyecto";
import Productos from "layouts/productos";
import Soporte from "layouts/soporte";
import Sucursal from "layouts/sucursal";
import Usuarios from "layouts/usuarios";
import Clientes from "layouts/clientes";
// import Proveedores from "layouts/authentication/proveedores";
import Proveedores from "layouts/proveedor";

//PDFs
import RemitoSedePDF from "layouts/PDF/remitoSede";

// Kaizen Dashboard icons
import { Inventory, ShoppingCart, Task, Person } from "@mui/icons-material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import GroupsIcon from "@mui/icons-material/Groups";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PerfilProveedor from "layouts/perfilProveedor";
import Recepcion from "layouts/recepcion";
import Progreso from "layouts/progreso";
import NuevoPresupuesto from "layouts/proyectos/NuevoPresupuesto";
import EditarPresupuesto from "layouts/proyectos/EditarPresupuesto";
import NuevoCertificado from "layouts/proyectos/NuevoCertificado";
import Ordenes from "layouts/ordenes";
import OrdenPago from "layouts/ordenPago";
import EditarCertificado from "layouts/proyectos/EditarCertificado";

const routes = [
  {
    type: "collapse",
    name: "Proyectos",
    key: "proyectos",
    route: "/proyectos",
    icon: <AccountTreeIcon />,
    component: <Proyectos />,
    noCollapse: true,
    permiso: "Proyectos",
  },
  {
    route: "/proyectos/nuevo",
    component: <NuevoProyecto />,
    permiso: "Proyectos",
  },
  {
    route: "/proyectos/nuevoPresupuesto",
    component: <NuevoPresupuesto />,
    permiso: "Proyectos",
  },
  {
    route: "/proyectos/editarPresupuesto",
    component: <EditarPresupuesto />,
    permiso: "Proyectos",
  },
  {
    route: "/certificados/nuevoCertificado",
    component: <NuevoCertificado />,
    permiso: "Proyectos",
  },
  {
    route: "/certificados/editarCertificado",
    component: <EditarCertificado />,
    permiso: "Proyectos",
  },
  {
    route: "/ordenes/:id",
    component: <Ordenes />,
    permiso: "Proyectos",
  },
  {
    type: "collapse",
    name: "Productos",
    key: "productos",
    route: "/productos",
    icon: <Inventory />,
    component: <Productos />,
    noCollapse: true,
    permiso: "Productos",
  },
  {
    route: "/productos/pdf/:id",
    component: <RemitoSedePDF />,
  },
  {
    route: "/recepcion/:id",
    component: <Recepcion />,
  },
  // {
  //   type: "collapse",
  //   name: "Sucursal",
  //   key: "sucursal",
  //   route: "/sucursal",
  //   icon: <DashboardIcon />,
  //   component: <Sucursal />,
  //   noCollapse: true,
  //   permiso: "Sucursal",
  // },
  {
    type: "collapse",
    name: "Clientes",
    key: "clientes",
    route: "/clientes",
    icon: <GroupsIcon />,
    component: <Clientes />,
    noCollapse: true,
    permiso: "Clientes",
  },
  {
    type: "collapse",
    name: "Proveedores",
    key: "proveedores",
    route: "/proveedores",
    icon: <SupervisorAccountIcon />,
    component: <Proveedores />,
    noCollapse: true,
    permiso: "Proveedores",
  },
  {
    type: "collapse",
    name: "Usuarios",
    key: "usuarios",
    route: "/usuarios",
    icon: <GroupIcon />,
    component: <Usuarios />,
    noCollapse: true,
    permiso: "Usuarios",
  },
  {
    type: "collapse",
    name: "Orden de pago",
    key: "ordenPago",
    route: "/ordenPago",
    icon: <LocalAtmIcon />,
    component: <OrdenPago />,
    noCollapse: true,
    permiso: "OrdenPago",
  },
  // {
  //   type: "collapse",
  //   name: "Progreso",
  //   key: "progreso",
  //   route: "/progreso",
  //   icon: <QueryStatsIcon />,
  //   component: <Progreso />,
  //   noCollapse: true,
  //   permiso: "Progreso",
  // },
  // {
  //   type: "collapse",
  //   name: "Compras",
  //   key: "compras",
  //   route: "/compras",
  //   icon: <ShoppingCart />,
  //   component: <Compras />,
  //   noCollapse: true,
  //   permiso: "Compras",
  // },
  // {
  //   type: "collapse",
  //   name: "Perfil",
  //   key: "perfil",
  //   route: "/perfil",
  //   icon: <Person />,
  //   component: <PerfilProveedor />,
  //   noCollapse: true,
  //   permiso: "PerfilProveedor",
  // },
  {
    type: "collapse",
    name: "Soporte",
    key: "soporte",
    route: "/soporte",
    icon: <SupportAgentIcon />,
    component: <Soporte />,
    noCollapse: true,
  },
  {
    route: "/error",
    component: <ErrorHandler />,
  },
  {
    route: "/login",
    component: <SignIn />,
  },
  // {
  //   route: "/proveedores",
  //   component: <Proveedores />,
  // },
];

export default routes;
