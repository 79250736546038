// React
import PropTypes from "prop-types";

// @mui material components
import { Card, Collapse, Grid, Icon, Tooltip } from "@mui/material";

import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MenuContext } from "context/menuContext";
import { useContext, useEffect, useState } from "react";
import ModalNewFase from "./components/ModalNewFase";
import ModalCantidadFase from "./components/ModalCantidadFase";
import { formatMoneyPunto } from "utils/formatters";
import ModalDelete from "components/Modals/Delete";
import ModalEditFase from "./components/ModalEditarFase";
import SinDatos from "./../../../../components/Carteles/SinDatos/index";
import FaseItem from "./components/FaseItem";
import ModalNewFaseTerceros from "./components/ModalNewFaseTerceros";
import ModalEditarFaseTerceros from "./components/ModalEditarFaseTerceros";
import { KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material";
import SoftInput from "components/SoftInput";
import SoftBadge from "components/SoftBadge";
import ModalCambioEtapa from "./components/ModalCambioEtapa";
import ModalNewFaseGlobal from "./components/ModalNewFaseGlobal";

export default function Fases({ proyecto, setProyecto, large }) {
  const { menu, handleClose } = useContext(MenuContext);
  const [modalNewFase, setModalNewFase] = useState(false);
  const [modalNewFaseTerceros,setModalNewFaseTerceros] = useState(false);
  const [modalCantidadFase, setModalCantidadFase] = useState(false);
  const [faseToAdd, setFaseToAdd] = useState(null);
  const [tipoAdd, setTipoAdd] = useState("");
  const [cantidadPlantilla,setCantidadPlantilla] = useState(false);
  const [openEtapa,setOpenEtapa] = useState(null);
  const [modalNewFaseGlobal,setModalNewFaseGlobal] = useState(false);

  const handleOpenNewFase = (tipo) => {
    setTipoAdd(tipo);
    setModalNewFase(true);
  };

  const handleOpenNewFaseTerceros = ()=>{
    setModalNewFaseTerceros(true);
  }

  const handleOpenNewFaseGlobal = ()=>{
    setModalNewFaseGlobal(true);
  }

  const handleOpenCantidadPlantilla = () => {
    setCantidadPlantilla(true);
    setModalCantidadFase(true);
  }

  const handleAddFase = (dataFase) => {
    let listFases = proyecto?.fases ? [...proyecto?.fases] : [];
    let etapas = dataFase.etapas;
    delete dataFase.etapas;
    listFases.push({ ...dataFase, id: new Date().getTime() });
    setProyecto({ ...proyecto, fases: listFases, etapas: etapas });
  };

  const [faseSelect, setFaseSelect] = useState({});
  const [faseVista,setFaseVista] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalEditFaseTerceros,setOpenModalEditFaseTerceros] = useState(false);
  const [etapaSelect,setEtapaSelect] = useState({});
  const [modalDeleteEtapa,setModalDeleteEtapa] = useState(false);
  const [modalCambioEtapa,setModalCambioEtapa] = useState(false);

  const handleEditFase = (fase, modoVista) => {
    setFaseSelect(fase);
    setFaseVista(modoVista);
    if(fase?.nombreUnidad){
      setOpenModalEdit(true);
    }else{
      setOpenModalEditFaseTerceros(true);
    }
  };

  const handleDeleteFase = (fase,index) => {
    setFaseSelect({
      ...fase,
      index:index
    });
    setOpenModalDelete(true);
  };

  const handleDelete = (posicion) => {
    //filtar por posicion
    let fasesFiltro = proyecto?.fases?.filter((fase,index) => index != posicion);
    setProyecto({ ...proyecto, fases: fasesFiltro });
  };

  const handleDeleteEtapa = (etapa,index) => {
    setEtapaSelect({
      ...etapa,
      index:index
    });
    setModalDeleteEtapa(true);
  }

  const deleteEtapa  = (etapa) => {
    let listFases = proyecto?.fases?.filter((fase)=> fase?.idEtapa !== etapa?.id);
    let etapas = proyecto?.etapas?.filter((item)=> item?.id !== etapa?.id);
    setProyecto({ ...proyecto, fases: listFases, etapas: etapas });
  }

  const getTotal = () => {
    let total = 0;
    proyecto?.fases?.forEach((fase) => {
      const cantidad = parseInt(fase?.cantidad) || 0;
      const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;
      let content = fase?.manoDeObra?.concat(fase?.materiales) || [];
      let subtotal = 0;
      content?.forEach((item) => {
        if (item.horas) {
          subtotal += item.precio * item.horas * cantidad || 0;
        } else {
          subtotal += item.precio * item.cantidad * cantidad || 0;
        }
      });
      subtotal += subtotal * (porcentaje / 100) || 0;
      total += subtotal;
    });
    return total;
  };

  const getTotalTerceros = () => {
    let total = 0;
    proyecto?.fases?.forEach((fase) => {
      let content = fase?.concepto || [];
      content?.forEach((item) => {
        total += item.precio || 0;
      })
    });
    return total;
  }

  const getTotalNuevo = () => {
    let total = 0;

    proyecto?.fases?.forEach((fase) => {
      let subtotal = 0;
      const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;
      const calcularTotal = (items, cantidad = 1) => {
        return items.reduce((acc, item) => {
          if (item.horas) {
            return acc + item.precio * item.horas * cantidad;
          } else if (item.cantidad) {
            return acc + item.precio * item.cantidad * cantidad;
          } else {
            return acc + item.precio;
          }
        }, 0);
      };
    
      if (fase?.nombreUnidad) {
        const cantidad = parseInt(fase?.cantidad) || 0;
        const content = [
          ...(fase?.manoDeObra || []),
          ...(fase?.materiales || []),
          ...(fase?.concepto || []),
        ];
        subtotal = calcularTotal(content, cantidad);
      } else if (fase?.presupuesto?.some((item) => item.aprobado == 1)) {
        subtotal = fase.presupuesto.find((item) => item.aprobado == 1)?.precio || 0;
      } else {
        const content = [...(fase?.concepto || [])];
        subtotal = calcularTotal(content);
      }
    
      subtotal += subtotal * (porcentaje / 100);
      total += subtotal;
    });
  
    return total;
  };

  //total sin ganancia 
  const getTotalCosto = () => {
    let total = 0;

    proyecto?.fases?.forEach((fase) => {
      let subtotal = 0;
      const calcularTotal = (items, cantidad = 1) => {
        return items.reduce((acc, item) => {
          if (item.horas) {
            return acc + item.precio * item.horas * cantidad;
          } else if (item.cantidad) {
            return acc + item.precio * item.cantidad * cantidad;
          } else {
            return acc + item.precio;
          }
        }, 0);
      };

      if (fase?.nombreUnidad) {
        const cantidad = parseInt(fase?.cantidad) || 0;
        const content = [
          ...(fase?.manoDeObra || []),
          ...(fase?.materiales || []),
          ...(fase?.concepto || []),
        ];
        subtotal = calcularTotal(content, cantidad);
      } else if (fase?.presupuesto?.some((item) => item.aprobado == 1)) {
        subtotal = fase.presupuesto.find((item) => item.aprobado == 1)?.precio || 0;
      } else {
        const content = [...(fase?.concepto || [])];
        subtotal = calcularTotal(content);
      }

      total += subtotal;
    });

    return total;
  }

  const getTotalEtapa = (idEtapa)=>{
    let total = 0;

    proyecto?.fases?.forEach((fase) => {
      if(fase?.idEtapa == idEtapa){
        let subtotal = 0;
        const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;
        const calcularTotal = (items, cantidad = 1) => {
          return items.reduce((acc, item) => {
            if (item.horas) {
              return acc + item.precio * item.horas * cantidad;
            } else if (item.cantidad) {
              return acc + item.precio * item.cantidad * cantidad;
            } else {
              return acc + item.precio;
            }
          }, 0);
        };
      
        if (fase?.nombreUnidad) {
          const cantidad = parseInt(fase?.cantidad) || 0;
          const content = [
            ...(fase?.manoDeObra || []),
            ...(fase?.materiales || []),
            ...(fase?.concepto || []),
          ];
          subtotal = calcularTotal(content, cantidad);
        } else if (fase?.presupuesto?.some((item) => item.aprobado == 1)) {
          subtotal = fase.presupuesto.find((item) => item.aprobado == 1)?.precio || 0;
        } else {
          const content = [...(fase?.concepto || [])];
          subtotal = calcularTotal(content);
        }
      
        subtotal += subtotal * (porcentaje / 100);
        total += subtotal;
      }
    });

    return total;
  }

  const cambioDolar = (montoPesos)=>{
    let montoDolar =  (montoPesos / parseFloat(proyecto?.cotizacion || 0));
    return formatMoneyPunto(parseFloat(montoDolar || 0));
  }

  const subTotalMateriales = () => {
    return proyecto?.fases?.reduce((acc, fase) => {
      let total = 0;
      fase?.materiales?.forEach((material) => {
        total += material.precio * material.cantidad * fase.cantidad;
      });
      return acc + total;
    }, 0) || 0;
  }

  const subTotalManoDeObra = () => {
    return proyecto?.fases?.reduce((acc, fase) => {
      let total = 0;
      fase?.manoDeObra?.forEach((manoHobra) => {
        total += manoHobra.precio * manoHobra.horas * fase.cantidad;
      });
      return acc + total;
    }, 0) || 0;
  }

  return (
    <SoftBox>
      {large && (
        <SoftBox display="flex" justifyContent="space-between" mb={2}>
          <SoftTypography variant="h6">{`Nuevo proyecto - Fases`}</SoftTypography>
          <SoftButton
            circular
            color="primary"
            onClick={(event) => {
              menu({
                open: event.currentTarget,
                align: "right",
                options: [
                  {
                    name: "Nueva fase propia",
                    icon: { icon: "add", color: "success" },
                    onClick: () => {
                      handleOpenNewFase("Nuevo");
                      handleClose();
                    },
                  },
                  {
                    name: "Nueva fase terceros",
                    icon: { icon: "person", color: "warning" },
                    onClick: () => {
                      handleOpenNewFaseTerceros();
                      handleClose();
                    },
                  },
                  {
                    name: "Plantilla",
                    icon: { icon: "file_copy", color: "info" },
                    onClick: () => {
                      handleOpenCantidadPlantilla();
                      handleClose();
                    },
                  },
                  // {
                  //   name: "Nueva fase",
                  //   icon: { icon: "add", color: "warning" },
                  //   onClick: () => {
                  //     handleOpenNewFaseGlobal();
                  //     handleClose();
                  //   },
                  // },
                ],
                handleClose: () => {},
              });
            }}
          >
            <Icon>add</Icon>&nbsp;Agregar
          </SoftButton>
        </SoftBox>
      )}
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={3}>
          <MiniStatisticsCard
            title={{ text: "Materiales" }}
            count={`$${formatMoneyPunto(subTotalMateriales())}`}
            precioDolar={proyecto?.tipoMoneda != "Pesos" ? `$${cambioDolar(subTotalMateriales())}` : null}
            icon={{ color: "dark", component: "shopping_cart" }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MiniStatisticsCard
            title={{ text: "Mano de obra" }}
            count={`$${formatMoneyPunto(subTotalManoDeObra())}`}
            precioDolar={proyecto?.tipoMoneda != "Pesos" ? `$${cambioDolar(subTotalManoDeObra())}` : null}
            icon={{ color: "warning", component: "construction" }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MiniStatisticsCard
            title={{ text: "Ganancias" }}
            count={`$${formatMoneyPunto((getTotalNuevo())-(getTotalCosto() || 0))}`}
            // count={`$${formatMoneyPunto(
            //   (getTotal() + getTotalTerceros()) -
            //     proyecto?.fases?.reduce((acc, fase) => {
            //       let total = 0;
            //       fase?.manoDeObra?.forEach((manoHobra) => {
            //         total += manoHobra.precio * manoHobra.horas * fase.cantidad;
            //       });
            //       return acc + total;
            //     }, 0) -
            //     proyecto?.fases?.reduce((acc, fase) => {
            //       let total = 0;
            //       fase?.materiales?.forEach((material) => {
            //         total += material.precio * material.cantidad * fase.cantidad;
            //       });
            //       return acc + total;
            //     }, 0) - 
            //     proyecto?.fases?.reduce((acc, fase) => {
            //       let total = 0;
            //       fase?.concepto?.forEach((concepto) => {
            //         total += concepto?.precio;
            //       });
            //       return acc + total;
            //     }, 0)  || 0
            // )}`}
            precioDolar={proyecto?.tipoMoneda != "Pesos" ? `$${cambioDolar((getTotalNuevo())-(getTotalCosto() || 0))}` : null}
            icon={{ color: "success", component: "paid" }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MiniStatisticsCard
            title={{ text: "Total" }}
            // count={`$${formatMoneyPunto(getTotal() + getTotalTerceros())}`}
            count={`$${formatMoneyPunto(getTotalNuevo())}`}
            precioDolar={proyecto?.tipoMoneda != "Pesos" ? `$${cambioDolar(getTotalNuevo())}` : null}
            icon={{ color: "info", component: "attach_money" }}
          />
        </Grid>
      </Grid>

      <SoftBox py={3} display="flex" flexDirection="column" gap={2}>
        {
          proyecto?.etapas?.length > 0 ? (
            proyecto.etapas.map((etapa, index) => (
              <Card sx={{ p: 3 }} key={index}>
                <SoftBox pb={openEtapa == etapa?.id ? 2 : 0} display="flex" justifyContent="space-between" alignItems="center">
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox mr={2} display="flex" alignItems="center">
                      <SoftTypography variant="h6" color="primary">Etapa Nº{index + 1}</SoftTypography>
                    </SoftBox>
                    <Tooltip placement="top" title={`Nombre de la etapa`}>
                      <SoftBox sx={{width: "275px"}}>
                        <SoftInput 
                        value={etapa?.nombre} 
                        onChange={(e) => {
                          setProyecto(prevProyecto => {
                            let etapas = prevProyecto.etapas.map(item => 
                              item.id === etapa.id ? { ...item, nombre: e.target.value } : item
                            );
                        
                            return { ...prevProyecto, etapas };
                          });
                        }}
                        name="nombre" 
                        error={etapa?.nombre == ""}
                        fullWidth
                        />
                      </SoftBox>
                    </Tooltip>

                    <SoftBox ml={1}>
                      <SoftBadge 
                      color="info"
                      container 
                      badgeContent={`Total de Fases: ${proyecto?.fases?.filter((fase) => fase?.idEtapa === etapa?.id)?.length || 0}`} 
                      />
                    </SoftBox>
                    <SoftBox ml={1} display="flex" alignItems="center" flexDirection="column">
                      <SoftBadge
                        badgeContent={`Total Etapa: $${formatMoneyPunto(getTotalEtapa(etapa?.id))}`}
                        color={"success"}
                        container
                      />
                    </SoftBox>
                  </SoftBox> 

                  <SoftBox display="flex" alignItems="center">
                    <SoftBox mr={1}>
                      <Tooltip placement="top" title="Eliminar etapa">
                        <SoftButton
                          onClick={() => handleDeleteEtapa(etapa, index)}
                          circular
                          iconOnly
                          color="error"
                          disabled={proyecto?.fases?.some((fase) => (fase?.idEtapa === etapa?.id) && (fase?.certificadoActivo == true))}
                        >
                          <Icon>delete</Icon>
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                    <Tooltip placement="top" title="Ver detalle">
                      <SoftButton 
                      onClick={() => setOpenEtapa(openEtapa === etapa?.id ? null : etapa?.id)}
                      sx={{cursor: "pointer"}}
                      circular 
                      iconOnly 
                      color="primary"
                      >
                        <Icon >
                          {openEtapa === etapa?.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </Icon>
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>

                <Collapse in={openEtapa == etapa?.id ? true : false} timeout="auto" >
                  <SoftBox >
                    {
                      proyecto?.fases?.map((fase, indexFase) => (
                        fase?.idEtapa === etapa?.id && (
                          <FaseItem
                            index={indexFase}
                            fase={fase}
                            key={fase.id}
                            handleEdit={(fase,modoVista) => handleEditFase(fase,modoVista)}
                            handleDelete={() => handleDeleteFase(fase,indexFase)}
                            handleChangeFase={(e, idItem) => {
                              let fases = [...proyecto?.fases];
                              let posicion = fases.findIndex((fase) => fase.id === idItem);
                              fases[posicion] = { ...fases[posicion], [e.target.name]: e.target.value };
                              setProyecto({ ...proyecto, fases });
                            }}
                            handleCambiarEtapa={(fase,index)=>{
                              setFaseSelect({
                                ...fase,
                                index: index
                              });
                              setModalCambioEtapa(true);
                            }}
                          />
                        )
                      ))
                    }
                  </SoftBox>
                </Collapse>
              </Card>
            ))
          ) : (
            <SinDatos />
        )}
        {/* {proyecto?.fases?.length > 0 ? (
          proyecto.fases.map((fase, index) => (
            <FaseItem
              index={index}
              fase={fase}
              key={fase.id}
              handleEdit={() => handleEditFase(fase)}
              handleDelete={() => handleDeleteFase(fase,index)}
              handleChangeFase={(e, idItem) => {
                let fases = [...proyecto?.fases];
                let index = fases.findIndex((fase) => fase.id === idItem);
                fases[index] = { ...fases[index], [e.target.name]: e.target.value };
                setProyecto({ ...proyecto, fases });
              }}
            />
          ))
          ) : (
            <SinDatos />
          )} */}
      </SoftBox>

      <ModalNewFaseGlobal
      open={modalNewFaseGlobal}
      handleClose={() => {
        setModalNewFaseGlobal(false);
      }}
      tipoMoneda={proyecto?.tipoMoneda}
      etapas={proyecto?.etapas || []}
      />

      <ModalNewFase
        open={modalNewFase}
        handleClose={() => {
          setModalNewFase(false);
          setFaseToAdd(null);
        }}
        guardarFase={(fase) => {
          setModalCantidadFase(true);
          setFaseToAdd(fase);
        }}
        tipo={tipoAdd}
        tipoMoneda={proyecto?.tipoMoneda}
      />

      <ModalNewFaseTerceros
      open={modalNewFaseTerceros}
      handleClose={()=>{
        setModalNewFaseTerceros(false);
        setFaseToAdd(null);
      }}
      guardarFase={(fase) => {
        handleAddFase(fase);
      }}
      tipoMoneda={proyecto?.tipoMoneda}
      etapas={proyecto?.etapas || []}
      />

      <ModalEditarFaseTerceros
      open={openModalEditFaseTerceros}
      handleClose={() => {
        setOpenModalEditFaseTerceros(false);
        setFaseSelect({});
        setFaseVista(false);
      }}
      dataFase={faseSelect}
      guardarFase={(fase) => {
        let fases = [...proyecto?.fases];
        let index = fases.findIndex((fase) => fase.id === faseSelect.id);
        fases[index] = fase;
        setProyecto({ ...proyecto, fases });
        setOpenModalEditFaseTerceros(false);
        setFaseSelect({});
      }}
      tipoMoneda={proyecto?.tipoMoneda}
      soloVista={faseVista}
      />

      <ModalCantidadFase
        open={modalCantidadFase}
        handleClose={() => {
          setCantidadPlantilla(false);
          setModalCantidadFase(false);
          setFaseToAdd(null);
        }}
        fase={faseToAdd}
        guardarFase={(fase) => {
          setModalCantidadFase(false);
          setModalNewFase(false);
          setCantidadPlantilla(false);
          handleAddFase(fase);
          setFaseToAdd(null);
        }}
        plantilla={cantidadPlantilla}
        etapas={proyecto?.etapas || []}
      />

      <ModalEditFase
        open={openModalEdit}
        handleClose={() => {
          setOpenModalEdit(false);
          setFaseSelect({});
          setFaseVista(false);
        }}
        dataFase={faseSelect}
        guardarFase={(fase) => {
          let fases = [...proyecto?.fases];
          let index = fases.findIndex((fase) => fase.id === faseSelect.id);
          fases[index] = fase;
          setProyecto({ ...proyecto, fases });
          setOpenModalEdit(false);
          setFaseSelect({});
        }}
        tipoMoneda={proyecto?.tipoMoneda}
        soloVista={faseVista}
      />

      <ModalDelete
        open={openModalDelete || modalDeleteEtapa}
        handleClose={() => {
          if(openModalDelete){
            setFaseSelect({});
            setOpenModalDelete(false);
          }else{
            setEtapaSelect({});
            setModalDeleteEtapa(false);
          }
        }}
        nombre={openModalDelete ? `la fase ${faseSelect?.nombre}` : `la etapa ${etapaSelect?.nombre}`}
        functionDelete={() => {
          if(openModalDelete){
            handleDelete(faseSelect?.index);
            setOpenModalDelete(false);
          }else{
            deleteEtapa(etapaSelect);
            setModalDeleteEtapa(false);
          }
        }}
      />

      <ModalCambioEtapa
      open={modalCambioEtapa}
      handleClose={() => {
        setFaseSelect({});
        setModalCambioEtapa(false);
      }}
      etapas={proyecto?.etapas || []}
      fase={faseSelect}
      guardarFase={(nuevaFase) => {
        let fases = [...proyecto?.fases];
        let index = fases.findIndex((item) => item.id === nuevaFase.id);
        fases[index] = nuevaFase;
        setProyecto({ ...proyecto, fases });
        setFaseSelect({});
        setModalCambioEtapa(false);
      }}
      />
    </SoftBox>
  );
}

Fases.propTypes = {
  proyecto: PropTypes.object,
  setProyecto: PropTypes.func,
  large: PropTypes.bool,
};
