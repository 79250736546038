import { Card, Grid, Table, TableBody, TableContainer, TableRow } from "@mui/material";
// import Logo from "assets/images/logoKaizen.png";
import Logo from "assets/images/logo.png";
import boxShadows from "assets/theme/base/boxShadows";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { formatMoneyPunto } from "utils/formatters";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import TableItemPDFCertificado from "./TableItem";
import { nombreEmpresa } from "services/config";

const columns = [
    // { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
    { name: "nombre", desc: "Nombre", align: "left", noOrder: true, width: "20%" },
    { name: "cantidad", desc: "Cant.", align: "left", noOrder: true },
    // { name: "moneda", desc: "Tipo de Moneda", align: "left", noOrder: true },
    { name: "precio", desc: "Precio Unit.", align: "left", noOrder: true },
    // { name: "cotizacion", desc: "Cotización", align: "left", noOrder: true },
    { name: "subtotal", desc: "Subtotal", align: "left", noOrder: true },
  
    { name: "incTotal", desc: "Inc.", align: "left",noOrder: true},
    { name: "incParcial", desc: "Inc. Actual", align: "left",noOrder: true},
  
    { name: "ant", desc: "Anterior", align: "left", noOrder: true },
    { name: "act", desc: "Actual", align: "left", noOrder: true },
    { name: "acm", desc: "Acumulado", align: "left", noOrder: true },
  
    { name: "montoAvance", desc: "Monto", align: "left", noOrder: true },
  ];

const CertificadoPDF = ({ proyecto,dataProyecto, setFile }) => {
  const documentsRef = useRef(null);
  const { lg } = boxShadows;
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const [avanceProyecto, setAvanceProyecto] = useState(0);

  const handleGeneratePdf = async () => {
    const document = documentsRef.current.childNodes[0];

    const doc = new jsPDF({
      format: "a4",
      unit: "px",
      orientation: "landscape",
      hotfixes: ["px_scaling"],
    });
    
    await doc.html(document, {
      callback(doc) {
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const pageSize = doc.internal.pageSize;
          const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          const header = `Presupuesto Nº${dataProyecto?.presupuestoAprobadoLast?.numero}-v${dataProyecto?.presupuestoAprobadoLast?.version}`;
          const brand = nombreEmpresa;
          const footer = `© ${dayjs().year()} Powered by Kaizen Software Factory`;
          const date = dayjs().format("DD/MM/YYYY");

          // Header
          doc.setFontSize(10);

          if (i !== 1) {
            doc.text(header, 40, 15, { baseline: "top" });
            doc.text(brand, pageWidth - 40 - doc.getTextWidth(brand), 15, { baseline: "top" });
          }

          // Footer
          doc.text(footer, 40, pageHeight - 15, { baseline: "bottom" });
          doc.text(date, pageWidth - 40 - doc.getTextWidth(date), pageHeight - 15, {
            baseline: "bottom",
          });
        }
        setFile(doc.output("blob"));
      },
    });
  };

  useEffect(() => {
    if (proyecto && dataProyecto) {
      calculoAvanceProyecto();    
    }else{
        setAvanceProyecto(0);
    }
  }, [proyecto,dataProyecto]);

  useEffect(() => {
      if(avanceProyecto != 0){
        handleGeneratePdf();
      }
  },[avanceProyecto]);

  const getTotal = (fasesPresupuesto) => {
    let total = 0;

    if (fasesPresupuesto?.length > 0) {
      fasesPresupuesto.forEach((fase) => {
        const cantidad = parseInt(fase?.cantidad) || 0;
        let content = [
          ...(fase?.manoDeObra || []),
          ...(fase?.materiales || []),
          ...(fase?.concepto || []),
        ];
        let subtotal = 0;
        content?.forEach((item) => {
          if (item.horas) {
            subtotal += item.precio * item.horas * cantidad || 0;
          } else if (item.cantidad) {
            subtotal += item.precio * item.cantidad * cantidad || 0;
          } else {
            subtotal += item.precio || 0;
          }
        });
        total += subtotal;
      });
    }

    return total;
  };

  const renderColumns = (columns) => {
    return columns.map(({ name, desc, align, width }, key) => {
      let pl;
      let pr;

      if (key === 0) {
        pl = 1;
        pr = 1;
      } else if (key === columns.length - 1) {
        pl = 1;
        pr = 1;
      } else {
        pl = 1;
        pr = 1;
      }

      return (
        <SoftBox
          key={name}
          component="th"
          width={width || "auto"}
          pt={1.5}
          pb={1.25}
          pl={align === "left" ? pl : 1}
          pr={align === "right" ? pr : 1}
          textAlign={align}
          fontSize={size.xxs}
          fontWeight={fontWeightBold}
          color="secondary"
          opacity={0.7}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
        >
          {desc ? desc.toUpperCase() : name.toUpperCase()}
        </SoftBox>
      );
    });
  };

  const calculoAvanceProyecto = ()=>{
    let totalFases = 0;

    if (proyecto?.fases?.length > 0) {
      proyecto?.fases?.forEach((fase) => {
        const arrayFases = [
          ...fase?.manoDeObra || [],
          ...fase?.materiales || [],
          ...fase?.concepto || []
        ];

        let incParcialFase = 0;
        arrayFases?.forEach((item) => {
          incParcialFase += parseFloat(item?.incParcial) || 0;
        });        
        totalFases += incParcialFase || 0;
      });
    }
   
    totalFases = Math.min(100, Math.max(0, parseFloat(totalFases))) || 0;

    setAvanceProyecto(()=>Math.round(totalFases));
    // return Math.round(totalFases);
   }

  return (
    <SoftBox
      p={2}
      display="flex"
      alignItems="center"
      flexDirection="column"
      minHeight="100vh"
      height="100%"
    >
      <div ref={documentsRef}>
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{
            position: "relative",
            overflow: "hidden",
            // height: "794px",
            width: "1110px",
            backgroundColor: "#ffffff",
            boxShadow: lg,
            px: 3,
            pt: 4,
          }}
        >
          <SoftBox
            sx={{
              flexGrow: 1,
            }}
          >
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <img src={Logo} alt="Terra" width="120px" />
              <SoftBox display="flex" flexDirection="column" gap={1} alignItems="end">
                <SoftTypography variant="caption">{nombreEmpresa}</SoftTypography>
                <SoftTypography variant="caption">{`Presupuesto Nº${dataProyecto?.presupuestoAprobadoLast?.numero}-v${dataProyecto?.presupuestoAprobadoLast?.version}`}</SoftTypography>
              </SoftBox>
            </SoftBox>

            <SoftBox>
              <SoftBox mb={3}>
                <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                  Certificado
                </SoftTypography>
                <SoftTypography variant="h6" textAlign="center">
                  {`Proyecto: ${dataProyecto?.nombre}`}
                </SoftTypography>
              </SoftBox>

              <SoftBox mb={3}>
                <SoftTypography variant="h6" fontWeight="bold">
                  Detalles del presupuesto
                </SoftTypography>
                <Grid container>
                  <Grid item xs={6}>
                    <SoftTypography variant="caption" fontWeight="bold">
                      Nombre del proyecto:&nbsp;
                      <SoftTypography variant="caption">{dataProyecto?.nombre}</SoftTypography>
                    </SoftTypography>
                  </Grid>
                  {dataProyecto?.responsable && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Responsable:&nbsp;
                        <SoftTypography variant="caption">{`${dataProyecto?.responsable?.nombre} ${dataProyecto?.responsable?.apellido}`}</SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {dataProyecto?.direccion && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Dirección:&nbsp;
                        <SoftTypography variant="caption">{`${dataProyecto?.direccion}, ${dataProyecto?.provincia?.nombre || dataProyecto?.localidad?.provincia?.nombre}, ${dataProyecto?.localidad?.nombre}`}</SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {dataProyecto?.cliente && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Cliente:&nbsp;
                        <SoftTypography variant="caption">
                          {
                            dataProyecto?.cliente?.tipoCliente == "Particular" ? 
                            `${dataProyecto?.cliente?.nombre} ${dataProyecto?.cliente?.apellido}` : 
                            `${dataProyecto?.cliente?.razonSocial}`
                          }
                          </SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {dataProyecto?.vendedor && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Vendedor:&nbsp;
                        <SoftTypography variant="caption">{`${dataProyecto?.vendedor?.nombre} ${dataProyecto?.vendedor?.apellido}`}</SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {dataProyecto?.presupuestoAprobadoLast?.nroContrato && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Nº Contrato:&nbsp;
                        <SoftTypography variant="caption">{dataProyecto?.presupuestoAprobadoLast?.nroContrato}</SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {dataProyecto?.fechaInicioEstimada && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Fecha de inicio:&nbsp;
                        <SoftTypography variant="caption">
                          {dataProyecto?.fechaInicioEstimada ? dayjs(dataProyecto?.fechaInicioEstimada).format("DD/MM/YYYY") : "-"}
                        </SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {dataProyecto?.fechaFinEstimada && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Fecha de fin:&nbsp;
                        <SoftTypography variant="caption">
                          {dataProyecto?.fechaFinEstimada ? dayjs(dataProyecto?.fechaFinEstimada).format("DD/MM/YYYY") : "-"}
                        </SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {dataProyecto?.presupuestoAprobadoLast?.terminosPago && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Términos de pago:&nbsp;
                        <SoftTypography variant="caption">{dataProyecto?.presupuestoAprobadoLast?.terminosPago}</SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {dataProyecto?.presupuestoAprobadoLast?.importeDeposito && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Depósito:&nbsp;
                        <SoftTypography variant="caption">
                          ${formatMoneyPunto(dataProyecto?.presupuestoAprobadoLast?.importeDeposito)}
                        </SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                </Grid>
              </SoftBox>

              <SoftBox>
                <SoftTypography variant="h6" fontWeight="bold" mb={1}>
                  Etapas del proyecto
                </SoftTypography>
                {
                  proyecto?.etapas?.map((itemEtapa, indexEtapa) => (
                    <SoftBox key={indexEtapa}>
                      <SoftTypography variant="h6" fontWeight="bold" mb={1} ml={2}>
                        {itemEtapa?.nombre}
                      </SoftTypography>
                      {
                        proyecto?.fases?.map((item, indexFase) => (
                          ((item?.etapa?.id == itemEtapa?.id) || 
                          (itemEtapa?.numeroFases?.some((fase) => fase === item?.numero))) && (
                          <Card key={indexFase} sx={{p:2,mb:2}}>
                              <SoftBox display="flex" alignItems="flex-start" justifyContent="space-between" mb={1}>
                                  <SoftBox >
                                      <SoftTypography variant="h6" fontWeight="bold">
                                        {item?.nombre}
                                      </SoftTypography>
                                  </SoftBox>
                                  
                                  <SoftBox display="flex" alignItems="flex-end" gap={1}>
                                      <SoftTypography variant="caption" fontWeight="medium">
                                        Inicio estimado: {item?.fechaInicioEstimado ? dayjs(item?.fechaInicioEstimado).format("DD/MM/YYYY") : "-"}
                                      </SoftTypography>
                                      <SoftTypography variant="caption" fontWeight="medium">
                                        Fin estimado: {item?.fechaFinEstimado ? dayjs(item?.fechaFinEstimado).format("DD/MM/YYYY") : "-"}
                                      </SoftTypography>
                                  </SoftBox>
                              </SoftBox>
      
                              <TableContainer>
                                  <Table>
                                    <SoftBox component="thead">
                                      <TableRow >
                                        {renderColumns(columns)}
                                      </TableRow>
                                    </SoftBox>
                                    <TableBody>
                                      {
                                          [...item?.manoDeObra,...item?.concepto].map((dataFase, index) => {
                                              return (
                                                  <TableItemPDFCertificado
                                                  key={dataFase?.id}
                                                  material={dataFase}
                                                  cantidad={item?.cantidad}
                                                  totalProyecto={getTotal(proyecto?.fases)}
                                                  />
                                              )
                                          })
                                      }
                                    </TableBody>
                                  </Table>
                              </TableContainer>
                          </Card>
                          )
                        ))
                      }
                    </SoftBox>
                  ))
                }
              </SoftBox>

              <SoftBox>
                <SoftBox display="flex" justifyContent="flex-end">
                    <SoftTypography variant="h6" fontWeight="bold" mb={2}>
                        Avance de obra total: {avanceProyecto}%
                    </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </div>
    </SoftBox>
  );
};

CertificadoPDF.propTypes = {
  proyecto: PropTypes.object,
  dataProyecto: PropTypes.object,
  setFile: PropTypes.func,
};

export default CertificadoPDF;
