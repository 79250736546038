// React
import { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";

// Components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import {
  Card,
  Fade,
  Grid,
  Icon,
  Modal,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";

// Data
import dataIndice from "../data/dataIndice";
import Loading from "components/Carteles/Loading";
import DataTable from "components/Tables/DataTable";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflow: "auto",
};

function ModalIndiceCAC({ open, handleClose }) {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const { columns, getRows } = dataIndice;

  const { loading, error, data, refetch } = useQuery(
    gql`
      query getIndices($page: Int, $order: Order) {
        paginationInfo {
          pages
          total
        }
        indices(pagination: { page: $page, limit: 10 }, order: $order) {
          id
          idCAC
          periodo
          general
          porcentajeAumento
          createdAt
        }
      }
    `,
    {
      variables: {
        page: page,
        order: { field: "id", order: "DESC" },
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (data) {
      setRows(getRows(data.indices));
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <Card>
              <Grid container spacing={2} p={3}>
                <Grid item xs={12}>
                  <SoftBox
                    display={{
                      xs: "flex-row",
                      sm: "flex",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                      <SoftTypography variant="h6">Índice CAC</SoftTypography>
                    </SoftBox>

                    <SoftBox>
                        <Tooltip title="Cerrar" placement="top">
                            <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                            close
                            </Icon>
                        </Tooltip>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {loading ? (
                  <Loading />
                ) : rows?.length > 0 ? (
                  <DataTable
                    columns={columns}
                    rows={rows}
                  />
                ) : (
                  <SinDatos />
                )}
              </SoftBox>
              <CustomPagination
                loading={loading}
                length={data?.indices.length}
                total={pagesInfo?.total}
                pages={pagesInfo?.pages}
                page={page}
                setPage={setPage}
                noCard
              />
            </Card>
        
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalIndiceCAC.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default ModalIndiceCAC;
