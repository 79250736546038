// React
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material";
// @mui material components
import {
  Card,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { getUproColor } from "utils/colors";
import MEDIDAS from "utils/medidas";
import SoftBadge from "components/SoftBadge";
import ModalAddProveedor from "layouts/proveedor/components/ModalAdd";
import ModalAprobacion from "../../ModalNewFaseTerceros/ModalAprobacion";
import ModalFormaPago from "../../ModalNewFaseTerceros/ModalFormaPago";
import ModalCargaMateriales from "./ModalCargaMateriales";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
  p: 3,
};

const columnsManoDeObra = [
  { name: "nombre", desc: "Nombre", align: "left", noOrder: true },
  { name: "horas", desc: "Horas", align: "left", noOrder: true, width: "150px" },
  { name: "moneda", desc: "Tipo de Moneda", align: "left", noOrder: true, width: "150px" },
  { name: "precio", desc: "Precio por hora", align: "left", noOrder: true, width: "150px" },
  { name: "cotizacion", desc: "Cotización", align: "left", noOrder: true, width: "150px" },
  { name: "action", desc: " ", align: "left", noOrder: true, width: "1%" },
];

const columnsMaterial = [
  { name: "material", desc: "Material", align: "left", noOrder: true },
  { name: "unidadMedida", desc: "Ud. de Medida", align: "left", noOrder: true, width: "125px" },
  { name: "cantidad", desc: "Cantidad", align: "left", noOrder: true, width: "150px" },
  { name: "moneda", desc: "Tipo de Moneda", align: "left", noOrder: true, width: "150px" },
  { name: "precio", desc: "Precio por unidad", align: "left", noOrder: true, width: "150px" },
  { name: "cotizacion", desc: "Cotización", align: "left", noOrder: true, width: "125px" },
  { name: "action", desc: " ", align: "left", noOrder: true, width: "1%" },
];

const columnsConcepto = [
  { name: "nombre", desc: "Nombre", align: "left", noOrder: true },
  { name: "moneda", desc: "Tipo de Moneda", align: "left", noOrder: true, width: "150px" },
  { name: "precio", desc: "Precio", align: "left", noOrder: true, width: "150px" },
  { name: "cotizacion", desc: "Cotización", align: "left", noOrder: true, width: "150px" },
  { name: "action", desc: " ", align: "left", noOrder: true, width: "1%" },
];

const columnsPresupuesto = [
    { name: "nroPresupuesto", desc: "Numero", align: "left", noOrder: true, width: "8%" },
    { name: "nombre", desc: "Proveedor", align: "left", noOrder: true },
    { name: "observacion", desc: "Observaciones", align: "left", noOrder: true,  },
    { name: "action", desc: " ", align: "left", noOrder: true, width: "2%" },
  ];

function ManoDeObraItem({ manoDeObra, handleUpdate, handleDelete, pt, pb,cotizaciones,tipoMoneda }) {
  const { borderWidth } = borders;
  const { size, fontWeightBold } = typography;

  const [materialData, setMaterialData] = useState({
    ...manoDeObra,
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setMaterialData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleUpdate(materialData);
  }, [materialData]);

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pl={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftInput
          label="nombre"
          value={materialData?.nombre}
          name="nombre"
          onChange={handleChanges}
        />
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
            label="horas"
            value={materialData?.horas}
            name="horas"
            onChange={handleChanges}
            icon={{
              component: "schedule",
              direction: "left",
            }}
            error={materialData?.horas?.length > 0 && materialData?.horas <= 0}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <Select
        name="tipoMoneda"
        value={materialData?.tipoMoneda || ""}
        onChange={(event)=>{
            setMaterialData({
                ...materialData,
                tipoMoneda: event.target.value,
                cotizacion: cotizaciones?.find(ctz => ctz?.nombre === event.target.value)?.venta || ((event.target.value === "Pesos" && tipoMoneda !== "Pesos") ? cotizaciones?.find(ctz => ctz?.nombre === tipoMoneda)?.venta : 1),
            })
        }}
        >
            <MenuItem value="Pesos">Pesos</MenuItem>
            {
                cotizaciones?.map((ctz) => {
                    return (
                        <MenuItem key={ctz?.nombre} value={ctz?.nombre}>
                            {ctz?.nombre}
                        </MenuItem>
                    )
                }) 
            }
        </Select>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
            label="precio"
            value={materialData?.precio}
            name="precio"
            onChange={handleChanges}
            icon={{
              component: "attach_money",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
          name="cotizacion"
          label="cotizacion"
          value={materialData?.cotizacion || ""}
          onChange={handleChanges}
            icon={{
              component: "currency_exchange",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pr={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftButton color="error" circular iconOnly onClick={handleDelete}>
            <Icon>delete</Icon>
          </SoftButton>
        </SoftTypography>
      </SoftBox>
    </TableRow>
  );
}

ManoDeObraItem.propTypes = {
  manoDeObra: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  pb: PropTypes.number,
  pt: PropTypes.number,
  cotizaciones: PropTypes.array.isRequired,
  tipoMoneda: PropTypes.string
};

function MaterialesItem({ material, handleUpdate, handleDelete, pt, pb,cotizaciones,tipoMoneda,listProductos }) {
  const { borderWidth } = borders;
  const { size, fontWeightBold } = typography;

  const [materialData, setMaterialData] = useState({
    ...material,
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setMaterialData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleUpdate(materialData);
  }, [materialData]);

  useEffect(() => {
    if (materialData?.producto) {
      setMaterialData({
        ...materialData,
        precio: materialData?.precio ? materialData?.precio : (materialData?.producto?.precio || ""),
      });
    }
  }, [materialData?.producto]);

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pl={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <Select name="producto" 
        // value={materialData?.producto?.id} 
        value={
          materialData?.producto?.id
            ? listProductos?.find((producto) => producto.id === materialData?.producto?.id)
            : ""
        }
        onChange={handleChanges}>
          {listProductos?.map((producto) => (
            <MenuItem key={producto.id} value={producto}>
              {producto.nombre}
            </MenuItem>
          ))}
        </Select>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftInput
        value={materialData?.producto?.unidadMedida || ""}
        name="unidadMedida"
        label="unidadMedida"
        disabled
        />
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
            label="cantidad"
            value={materialData?.cantidad}
            name="cantidad"
            onChange={handleChanges}
            error={materialData?.cantidad === 0}
            icon={{
              component: "shopping_cart",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <Select
        name="tipoMoneda"
        value={materialData?.tipoMoneda || ""}
        onChange={(event)=>{
            setMaterialData({
                ...materialData,
                tipoMoneda: event.target.value,
                cotizacion: cotizaciones?.find(ctz => ctz?.nombre === event.target.value)?.venta || ((event.target.value === "Pesos" && tipoMoneda !== "Pesos") ? cotizaciones?.find(ctz => ctz?.nombre === tipoMoneda)?.venta : 1),
            })
        }}
        >
            <MenuItem value="Pesos">Pesos</MenuItem>
            {
                cotizaciones?.map((ctz) => {
                    return (
                        <MenuItem key={ctz?.nombre} value={ctz?.nombre}>
                            {ctz?.nombre}
                        </MenuItem>
                    )
                }) 
            }
        </Select>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
            label="precio"
            value={materialData?.precio}
            name="precio"
            onChange={handleChanges}
            icon={{
              component: "attach_money",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
          name="cotizacion"
          label="cotizacion"
          value={materialData?.cotizacion || ""}
          onChange={handleChanges}
            icon={{
              component: "currency_exchange",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pr={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftButton color="error" circular iconOnly onClick={handleDelete}>
            <Icon>delete</Icon>
          </SoftButton>
        </SoftTypography>
      </SoftBox>
    </TableRow>
  );
}

MaterialesItem.propTypes = {
  material: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  pb: PropTypes.number,
  pt: PropTypes.number,
  cotizaciones: PropTypes.array.isRequired,
  tipoMoneda: PropTypes.string,
  listProductos: PropTypes.array.isRequired,
};

function ConceptoItem({ concepto, handleUpdate, handleDelete, pt, pb,cotizaciones,tipoMoneda }) {
  const { borderWidth } = borders;
  const { size, fontWeightBold } = typography;

  const [conceptoData, setConceptoData] = useState({
    ...concepto,
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setConceptoData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleUpdate(conceptoData);
  }, [conceptoData]);

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pl={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftInput
          label="nombre"
          value={conceptoData?.nombre}
          name="nombre"
          onChange={handleChanges}
        />
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <Select
        name="tipoMoneda"
        value={conceptoData?.tipoMoneda || ""}
        onChange={(event)=>{
          setConceptoData({
            ...conceptoData,
            tipoMoneda: event.target.value,
            cotizacion: cotizaciones?.find(ctz => ctz?.nombre === event.target.value)?.venta || ((event.target.value === "Pesos" && tipoMoneda !== "Pesos") ? cotizaciones?.find(ctz => ctz?.nombre === tipoMoneda)?.venta : 1),
          })
        }}
        >
            <MenuItem value="Pesos">Pesos</MenuItem>
            {
                cotizaciones?.map((ctz) => {
                    return (
                        <MenuItem key={ctz?.nombre} value={ctz?.nombre}>
                            {ctz?.nombre}
                        </MenuItem>
                    )
                }) 
            }
        </Select>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
            label="precio"
            value={conceptoData?.precio}
            name="precio"
            onChange={handleChanges}
            icon={{
              component: "attach_money",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
          name="cotizacion"
          label="cotizacion"
          value={conceptoData?.cotizacion || ""}
          onChange={handleChanges}
            icon={{
              component: "currency_exchange",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pr={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftButton color="error" circular iconOnly onClick={handleDelete}>
            <Icon>delete</Icon>
          </SoftButton>
        </SoftTypography>
      </SoftBox>
    </TableRow>
  );
}

ConceptoItem.propTypes = {
  concepto: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  pb: PropTypes.number,
  pt: PropTypes.number,
  cotizaciones: PropTypes.array.isRequired,
  tipoMoneda: PropTypes.string
};

function PresupuestoItem({ presupuesto, handleUpdate, handleDelete, pt, pb,tipoMoneda ,totalConceptos,tipo}) {
    const {handleSnackbar} = useContext(MessageManager);
    const { borderWidth } = borders;
    const { size, fontWeightBold } = typography;
    const fileRef = useRef();
    const [modalAprobacion,setModalAprobacion] = useState(false);
    const [openModalProveedores,setOpenModalProveedores]=useState(false);
    const [modalFormaPago,setModalFormaPago] = useState(false);
    const [modalCargaMateriales ,setModalCargaMateriales]=useState(false);
  
    const [presupuestoData, setPresupuestoData] = useState({
      ...presupuesto,
    });
  
    const handleChanges = (e) => {
      const { name, value } = e.target;
      setPresupuestoData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    useEffect(() => {
      handleUpdate(presupuestoData);
    }, [presupuestoData]);
  
    useEffect(() => {
      setPresupuestoData({...presupuesto});
    }, [presupuesto?.aprobado]);
  
    const handleGuardarMotivo = (motivo) => {
      setPresupuestoData((prevState) => ({
        ...prevState,
        aprobado: true,
        motivoAprobacion: motivo
      }))
      setModalAprobacion(false);
    }
  
    const handleGuardarFormaPago = (forma) => {
      setPresupuestoData((prevState) => ({
        ...prevState,
        ...forma
      }))
      setModalFormaPago(false);
    }
  
    const { data: proveedores, loading, refetch } = useQuery(
      gql`
        query getProveedores {
          proveedores {
            id
            razonSocial
            nombre
            apellido
          }
        }
      `
    );
  
    return (
      <TableRow hover>
        <SoftBox
          component="td"
          p={1}
          pt={pt}
          pb={pb}
          pl={2}
          textAlign={"left"}
          borderBottom={`${borderWidth[1]} solid light`}
          fontSize={size.xxs}
          fontWeight={fontWeightBold}
          sx={{
            verticalAlign: "top",
          }}
        >
          <SoftBox display="flex" alignItems="center" gap={1}>
            <SoftInput
              label="nroPresupuesto"
              value={presupuestoData?.nroPresupuesto}
              name="nroPresupuesto"
              onChange={handleChanges}
            />          
          </SoftBox>
        </SoftBox>
        <SoftBox
          component="td"
          p={1}
          pt={pt}
          pb={pb}
          textAlign={"left"}
          borderBottom={`${borderWidth[1]} solid light`}
          fontSize={size.xxs}
          fontWeight={fontWeightBold}
          sx={{
            verticalAlign: "top",
          }}
        >
          <SoftBox display="flex" alignItems="flex-start" gap={1}>
            {/* <SoftInput
              label="nombre"
              value={presupuestoData?.nombre}
              name="nombre"
              onChange={handleChanges}
            /> */}
            <SoftBox display="flex" flexDirection="column" alignItems="flex-end" flexGrow={1}>
              <Select
              name="nombre"
              value={presupuestoData?.nombre || ""}
              onChange={handleChanges}
              >
                {
                  proveedores?.proveedores?.map((prov)=> {
                    return (
                      <MenuItem key={prov?.id} value={prov}>
                        {prov?.razonSocial ? prov?.razonSocial : prov?.nombre ? prov?.nombre+" "+prov?.apellido : ""}
                      </MenuItem>
                    )
                  })
                }
              </Select>
              <SoftBox>
                <InputLabel htmlFor="proveedor">
                  <SoftTypography
                    variant="caption"
                    color="text"
                    onClick={() => setOpenModalProveedores(true)}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    Agregar proveedor &nbsp;
                    <Icon
                      sx={{
                        verticalAlign: "middle",
                      }}
                      color="primary"
                    >
                      settings
                    </Icon>
                  </SoftTypography>
                </InputLabel>
              </SoftBox>
            </SoftBox>
  
            <SoftBox>
              <Tooltip title={presupuestoData?.aprobado ? "Aprobado" : "No aprobado"} placement="top" >
                <SoftButton
                variant="gradient"
                iconOnly
                circular
                color={presupuestoData?.aprobado ? "success" : "light"}
                onClick={()=>{
                  if(!presupuestoData?.aprobado){
                    setModalAprobacion(true);
                  }else{
                    setPresupuestoData((prevState) => ({
                      ...prevState,
                      aprobado: !presupuestoData?.aprobado,
                      motivoAprobacion: ""
                    }));
                  }
                }}
                >
                  <Icon 
                  fontSize="medium"
                  color={presupuestoData?.aprobado ? "dark" : "secondary"}
                  >
                    check
                  </Icon>
                </SoftButton>
              </Tooltip>
            </SoftBox> 
          </SoftBox>
        </SoftBox>
        <SoftBox
          component="td"
          p={1}
          pt={pt}
          pb={pb}
          textAlign={"left"}
          borderBottom={`${borderWidth[1]} solid light`}
          fontSize={size.xxs}
          fontWeight={fontWeightBold}
          sx={{
            verticalAlign: "top",
          }}
          display="flex"
          alignItems="flex-start"
          gap={1}
        >
          <SoftInput
            label="observaciones"
            value={presupuestoData?.observaciones ? presupuestoData?.observaciones : ""}
            name="observaciones"
            onChange={handleChanges}
          />
        </SoftBox>
        <SoftBox
          component="td"
          p={1}
          pt={pt}
          pb={pb}
          pr={2}
          textAlign={"left"}
          borderBottom={`${borderWidth[1]} solid light`}
          sx={{
            verticalAlign: "top",
          }}
        > 
          <SoftBox display="flex" gap={1}>
            {
              !presupuestoData?.file ? (
                <>
                  <Tooltip title="Adjuntar archivo" placement="top">
                    <SoftButton
                      variant="gradient"
                      color="success"
                      iconOnly
                      circular
                      onClick={() => {
                        fileRef.current.value = null;
                        fileRef.current.click();
                      }}
                    >
                      <Icon sx={{ fontWeight: "regular" }}>add</Icon>
                    </SoftButton>
                  </Tooltip>
                  <input
                    ref={fileRef}
                    type="file"
                    style={{ display: "none" }}
                    accept=".pdf, .docx, .jpg, .jpeg, .png"
                    onChange={(e) => {
                      const file = e.target.files[0];
  
                      if (file) {
                        const maxSize = 10 * 1024 * 1024; // 10MB en bytes
                    
                        if (file.size > maxSize) {
                          handleSnackbar("El archivo supera el tamaño máximo permitido de 10MB.", "error");
                          return;
                        }
                    
                        setPresupuestoData({
                          ...presupuestoData,
                          file: file,
                        });
                      }
  
                     
                    }}
                  />
                </>
              ) : (
                <>
                <Tooltip title="Borrar archivo" placement="top">
                    <SoftButton
                      variant="gradient"
                      color="error"
                      iconOnly
                      circular
                      onClick={() => {
                        setPresupuestoData({
                          ...presupuestoData,
                          file: null,
                        });
                      }}
                    >
                      <Icon sx={{ fontWeight: "regular" }}>folder_off</Icon>
                    </SoftButton>
                  </Tooltip>
                </>
              )
            }

            {
              tipo=="Material" && (
                <Tooltip title="Agregar lista de Materiales" placement="top">
                  <SoftButton color="info" circular iconOnly 
                  onClick={()=>setModalCargaMateriales(true)}
                  >
                    <Icon>inventory_2</Icon>
                  </SoftButton>
                </Tooltip>
              )
            }
            <Tooltip title="Forma de pago" placement="top">
              <SoftButton color="primary" circular iconOnly 
              onClick={()=>setModalFormaPago(true)}
              >
                <Icon>attach_money</Icon>
              </SoftButton>
            </Tooltip>
            {/* <SoftTypography variant="caption" color="dark" fontWeight="bold"> */}
            <Tooltip title="Eliminar" placement="top">
              <SoftButton color="error" circular iconOnly onClick={handleDelete}>
                <Icon>delete</Icon>
              </SoftButton>
            </Tooltip>
            {/* </SoftTypography> */}
          </SoftBox>
        </SoftBox>
        <ModalAprobacion
        open={modalAprobacion}
        handleClose={()=>setModalAprobacion(false)}
        guardarMotivo={handleGuardarMotivo}
        />
        <ModalAddProveedor
         open={openModalProveedores}
         handleClose={() => {
          setOpenModalProveedores(false);
         }}
         data={proveedores?.proveedores}
         refetch={refetch}
        />
        <ModalFormaPago
        open={modalFormaPago}
        handleClose={()=>setModalFormaPago(false)}
        guardarFormaPago={handleGuardarFormaPago}
        tipoMoneda={tipoMoneda}
        totalConceptos={totalConceptos}
        presupuestoData={presupuestoData}
        />
        <ModalCargaMateriales
        open={modalCargaMateriales}
        handleClose={()=>setModalCargaMateriales(false)}
        tipoMoneda={tipoMoneda}
        />
      </TableRow>
    );
  }
  
  PresupuestoItem.propTypes = {
    presupuesto: PropTypes.object.isRequired,
    handleUpdate: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    pb: PropTypes.number,
    pt: PropTypes.number,
    tipoMoneda: PropTypes.string,
    totalConceptos: PropTypes.number,
    tipo: PropTypes.string
  };

export default function ModalAdicionales({ open, handleClose ,tipoMoneda,dataFase}) {
  const [servicio, setServicio] = useState();
  const { handleSnackbar } = useContext(MessageManager);
  const [arrayManoObra, setArrayManoObra] = useState([]);
  const [arrayMateriales, setArrayMateriales] = useState([]);
  const [arrayConceptos, setArrayConceptos] = useState([]);
  const { borderWidth } = borders;
  const { size, fontWeightBold } = typography;
  const { light } = colors;
  const [openPresupuesto,setOpenPresupuesto] = useState(null);

  const [arrayPresupuestosFase, setArrayPresupuestosFase] = useState([]);
  const [arrayPresupuestosMaterial, setArrayPresupuestosMaterial] = useState([]);
  const [arrayPresupuestosManoObra, setArrayPresupuestosManoObra] = useState([]);

  const handleChanges = (e) => {
    setServicio({
      ...servicio,
      [e.target.name]: e.target.value,
    });
  };

  const { data: productos } = useQuery(
    gql`
      query getProductos {
        productos {
          id
          nombre
          precio
          unidadMedida
        }
      }
    `
  );

  const [savePlantillaFase, { loading: loadingSave }] = useMutation(
    gql`
      mutation savePlantillaFase($input: PlantillaFaseInput!) {
        savePlantillaFase(input: $input) {
          id
        }
      }
    `
  );

  const resetModal = () => {
    setServicio({});
    setSelectedPlantilla(null);

    setArrayPresupuestosFase([]);
    setArrayPresupuestosMaterial([]);
    setArrayPresupuestosManoObra([]);

    setOpenPresupuesto(null);
  };

  const [openPlantillaMenu, setOpenPlantillaMenu] = useState(false);
  const [selectedPlantilla, setSelectedPlantilla] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSedeMenu, setOpenSedeMenu] = useState(false);
  const [plantillaSinElegir, setPlantillaSinElegir] = useState(false);

  const [getPlantillas, { data: dataPlantillas, loading: loadingPlantillas }] = useLazyQuery(
    gql`
      query GetPlantillas($filter: JSON) {
        plantillasFases(filter: $filter) {
          id
          nombre
          descripcion
          nombreUnidad
          manoDeObra {
            id
            nombre
            horas
            precio
            tipoMoneda
            cotizacion
          }
          materiales {
            id
            nombre
            cantidad
            precio
            tipoMoneda
            cotizacion  
            producto{
              id
              nombre
              precio
            }
          }
        }
      }
    `
  );

  const {data:cotizacion} = useQuery(
    gql`
        query getCotizaciones{
            cotizaciones{
            agencia
            compra
            decimales
            fechaActualizacion
            nombre
            variacion
            variacion
            venta
            }
        }
    `
  );

    useEffect(() => {
        if (open) {
            getPlantillas();
        }
    }, [open]);

  useEffect(() => {
    if (selectedPlantilla) {
      setPlantillaSinElegir(false);
    } else {
      setPlantillaSinElegir(true);
    }
  }, [selectedPlantilla]);

  const handleOpenSedeMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenSedeMenu(true);
  };
  const handleCloseSedeMenu = () => {
    setAnchorEl(null);
    setOpenSedeMenu(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" mb={2}>
              <SoftBox>
                <SoftTypography variant="h6">Presupuestos de Fase, Materiales y Mano de Obra</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        resetModal();
                        handleClose();
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox pb={4}>
              <SoftBox py={3}  borderBottom={1} borderColor={light.main}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <SoftBox lineHeight={1}>
                        <SoftTypography variant="h6">Presupuesto Fase</SoftTypography>
                        <SoftBox display="flex" alignItems="center" gap={1}>
                            <SoftBadge
                                container
                                color="success"
                                variant="gradient"
                                badgeContent={`Total: ${dataFase?.precio ? '$ '+dataFase?.precio : '$ 0.00'}`}	
                            />
                            <SoftBadge
                            container
                            color={
                                (arrayPresupuestosFase.length > 0 && 
                                    arrayPresupuestosFase.some((item)=> item?.aprobado)
                                ) ? 
                                "info" 
                                : arrayPresupuestosFase.length > 0 ?
                                "warning" 
                                : "secondary"
                            }
                            variant="gradient"
                            badgeContent={
                                (arrayPresupuestosFase.length > 0 && 
                                    arrayPresupuestosFase.some((item)=> item?.aprobado)
                                ) ? 
                                "Presupuesto aprobado" 
                                : arrayPresupuestosFase.length > 0 ?
                                "Presupuestos pendiente" 
                                : "Sin Presupuestos"
                            }
                            />
                        </SoftBox>
                    </SoftBox>

                  <SoftBox display="flex" alignItems="center" gap={1}>
                    <Tooltip placement="top" title="Agregar Presupuesto">
                        <SoftButton
                            iconOnly
                            color="primary"
                            circular
                            onClick={() => {
                            let newArrayFase = [...arrayPresupuestosFase];
                            newArrayFase.push({
                                id: new Date().getTime(),
                                nombre: "",
                                tipoMoneda: "Pesos",
                                precio: "",
                                cotizacion: cotizacion?.cotizaciones?.find((ctz)=> ctz?.nombre == tipoMoneda)?.venta || 1,
                                horas: "",
                            });
                            setArrayPresupuestosFase(newArrayFase);
                            if(openPresupuesto != "fase"){
                                setOpenPresupuesto("fase");
                            }
                            }}
                        >
                            <Icon>add</Icon>
                        </SoftButton>
                    </Tooltip>

                    <Tooltip placement="top" title="Ver detalle">
                      <SoftButton 
                      onClick={() => setOpenPresupuesto(openPresupuesto === "fase" ? null : "fase")}
                      sx={{cursor: "pointer"}}
                      circular 
                      iconOnly 
                      color="sistemasGris"
                      >
                        <Icon >
                          {openPresupuesto === "fase" ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </Icon>
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>

                <Collapse in={openPresupuesto == "fase" ? true : false} timeout="auto" > 
                    <SoftBox my={2}>
                    {arrayPresupuestosFase.length > 0 ? (
                        <TableContainer>
                        <Table>
                            <SoftBox component="thead">
                            <TableRow>
                                {columnsPresupuesto.map(({ name, desc, align, width }, key) => {
                                let pl;
                                let pr;

                                if (key === 0) {
                                    pl = 3;
                                    pr = 3;
                                } else if (key === columnsPresupuesto.length - 1) {
                                    pl = 3;
                                    pr = 3;
                                } else {
                                    pl = 1;
                                    pr = 1;
                                }

                                return (
                                    <SoftBox
                                    key={name}
                                    component="th"
                                    width={width || "auto"}
                                    pt={1.5}
                                    pb={1.25}
                                    pl={align === "left" ? pl : 3}
                                    pr={align === "right" ? pr : 3}
                                    textAlign={align}
                                    fontSize={size.xxs}
                                    fontWeight={fontWeightBold}
                                    color="secondary"
                                    opacity={0.7}
                                    borderBottom={`${borderWidth[1]} solid ${light.main}`}
                                    >
                                    {desc ? desc.toUpperCase() : name.toUpperCase()}
                                    </SoftBox>
                                );
                                })}
                            </TableRow>
                            </SoftBox>
                            <TableBody>
                            {arrayPresupuestosFase.map((presupuesto, index) => {
                                return (
                                <PresupuestoItem
                                    key={presupuesto.id}
                                    presupuesto={presupuesto}
                                    handleUpdate={(item) => {
                                    if(item?.aprobado != arrayPresupuestosFase[index]?.aprobado){
                                        let newArrayPresupuestos = [...arrayPresupuestosFase];
                                        if(!item?.aprobado){
                                        newArrayPresupuestos[index] = item;
                                        }else{
                                        newArrayPresupuestos = newArrayPresupuestos.map((presupuesto) => {
                                            return {...presupuesto, aprobado: presupuesto.id === item.id ? item.aprobado : false, motivoAprobacion: presupuesto.id === item.id ? item.motivoAprobacion : ""};
                                        });
                                        }
                                        setArrayPresupuestosFase(newArrayPresupuestos);
                                    }else{
                                        let newArrayPresupuestos = [...arrayPresupuestosFase];
                                        newArrayPresupuestos[index] = item;
                                        setArrayPresupuestosFase(newArrayPresupuestos);
                                    }
                                    }}
                                    handleDelete={() => {
                                        let newArrayPresupuestos = [...arrayPresupuestosFase];
                                        newArrayPresupuestos.splice(index, 1);
                                        setArrayPresupuestosFase(newArrayPresupuestos);
                                    }}
                                    pb={index === arrayPresupuestosFase.length - 1 ? 2 : null}
                                    pt={index === 0 ? 2 : null}
                                    tipoMoneda={tipoMoneda}
                                    totalConceptos={0}
                                />
                                );
                            })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    ) : (
                        <SinDatos />
                    )}
                    </SoftBox>
                </Collapse>
              </SoftBox>

              <SoftBox pt={3} pb={3} borderBottom={1} borderColor={light.main}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <SoftBox lineHeight={1}>
                        <SoftTypography variant="h6">Presupuesto Mano de obra</SoftTypography>
                        <SoftBox display="flex" alignItems="center" gap={1}>
                            <SoftBadge
                                container
                                color="success"
                                variant="gradient"
                                badgeContent={`Total: ${dataFase?.totalManoObra ? '$ '+dataFase?.totalManoObra : '$ 0.00'}`}
                            />
                            <SoftBadge
                            container
                            color={
                                (arrayPresupuestosManoObra.length > 0 && 
                                    arrayPresupuestosManoObra.some((item)=> item?.aprobado)
                                ) ? 
                                "info" 
                                : arrayPresupuestosManoObra.length > 0 ?
                                "warning" 
                                : "secondary"
                            }
                            variant="gradient"
                            badgeContent={
                                (arrayPresupuestosManoObra.length > 0 && 
                                    arrayPresupuestosManoObra.some((item)=> item?.aprobado)
                                ) ? 
                                "Presupuesto aprobado" 
                                : arrayPresupuestosManoObra.length > 0 ?
                                "Presupuestos pendiente" 
                                : "Sin Presupuestos"
                            }
                            />
                        </SoftBox>
                    </SoftBox>

                    <SoftBox display="flex" alignItems="center" gap={1}>
                        <Tooltip placement="top" title="Agregar Presupuesto">
                            <SoftButton
                                iconOnly
                                color="primary"
                                circular
                                onClick={() => {
                                let newArrayPresupuestosManoObra = [...arrayPresupuestosManoObra];
                                newArrayPresupuestosManoObra.push({
                                    id: new Date().getTime(),
                                    nroPresupuesto: new Date().getTime() % 10000,
                                    nombre: "",
                                    file: "",
                                    aprobado: false,
                                });
                                setArrayPresupuestosManoObra(newArrayPresupuestosManoObra);
                                if(openPresupuesto != "manoObra"){
                                    setOpenPresupuesto("manoObra");
                                }
                                }}
                            >
                                <Icon>add</Icon>
                            </SoftButton>
                        </Tooltip>

                        <Tooltip placement="top" title="Ver detalle">
                        <SoftButton 
                        onClick={() => setOpenPresupuesto(openPresupuesto === "manoObra" ? null : "manoObra")}
                        sx={{cursor: "pointer"}}
                        circular 
                        iconOnly 
                        color="sistemasGris"
                        >
                            <Icon >
                            {openPresupuesto === "manoObra" ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </Icon>
                        </SoftButton>
                        </Tooltip>
                    </SoftBox> 
                </SoftBox>

                <Collapse in={openPresupuesto == "manoObra" ? true : false} timeout="auto" > 
                    <SoftBox my={2}>
                    {arrayPresupuestosManoObra.length > 0 ? (
                        <TableContainer>
                        <Table>
                            <SoftBox component="thead">
                            <TableRow>
                                {columnsPresupuesto.map(({ name, desc, align, width }, key) => {
                                let pl;
                                let pr;

                                if (key === 0) {
                                    pl = 3;
                                    pr = 3;
                                } else if (key === columnsPresupuesto.length - 1) {
                                    pl = 3;
                                    pr = 3;
                                } else {
                                    pl = 1;
                                    pr = 1;
                                }

                                return (
                                    <SoftBox
                                    key={name}
                                    component="th"
                                    width={width || "auto"}
                                    pt={1.5}
                                    pb={1.25}
                                    pl={align === "left" ? pl : 3}
                                    pr={align === "right" ? pr : 3}
                                    textAlign={align}
                                    fontSize={size.xxs}
                                    fontWeight={fontWeightBold}
                                    color="secondary"
                                    opacity={0.7}
                                    borderBottom={`${borderWidth[1]} solid ${light.main}`}
                                    >
                                    {desc ? desc.toUpperCase() : name.toUpperCase()}
                                    </SoftBox>
                                );
                                })}
                            </TableRow>
                            </SoftBox>
                            <TableBody>
                            {arrayPresupuestosManoObra.map((presupuesto, index) => {
                                return (
                                <PresupuestoItem
                                    key={presupuesto.id}
                                    presupuesto={presupuesto}
                                    handleUpdate={(item) => {
                                    if(item?.aprobado != arrayPresupuestosManoObra[index]?.aprobado){
                                        let newArrayPresupuestos = [...arrayPresupuestosManoObra];
                                        if(!item?.aprobado){
                                        newArrayPresupuestos[index] = item;
                                        }else{
                                        newArrayPresupuestos = newArrayPresupuestos.map((presupuesto) => {
                                            return {...presupuesto, aprobado: presupuesto.id === item.id ? item.aprobado : false, motivoAprobacion: presupuesto.id === item.id ? item.motivoAprobacion : ""};
                                        });
                                        }
                                        setArrayPresupuestosManoObra(newArrayPresupuestos);
                                    }else{
                                        let newArrayPresupuestos = [...arrayPresupuestosManoObra];
                                        newArrayPresupuestos[index] = item;
                                        setArrayPresupuestosManoObra(newArrayPresupuestos);
                                    }
                                    }}
                                    handleDelete={() => {
                                        let newArrayPresupuestos = [...arrayPresupuestosManoObra];
                                        newArrayPresupuestos.splice(index, 1);
                                        setArrayPresupuestosManoObra(newArrayPresupuestos);
                                    }}
                                    pb={index === arrayPresupuestosManoObra.length - 1 ? 2 : null}
                                    pt={index === 0 ? 2 : null}
                                    tipoMoneda={tipoMoneda}
                                    totalConceptos={0}
                                />
                                );
                            })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    ) : (
                        <SinDatos />
                    )}
                    </SoftBox>
                </Collapse>
              </SoftBox>

              <SoftBox pt={3} pb={3} borderBottom={1} borderColor={light.main}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <SoftBox lineHeight={1}>
                        <SoftTypography variant="h6">Presupuesto Materiales</SoftTypography>
                        <SoftBox display="flex" alignItems="center" gap={1}>
                            <SoftBadge
                                container
                                color="success"
                                variant="gradient"
                                badgeContent={`Total: ${dataFase?.totalMateriales ? '$ '+dataFase?.totalMateriales : '$ 0.00'}`}
                            />
                            <SoftBadge
                            container
                            color={
                                (arrayPresupuestosMaterial.length > 0 && 
                                    arrayPresupuestosMaterial.some((item)=> item?.aprobado)
                                ) ? 
                                "info" 
                                : arrayPresupuestosMaterial.length > 0 ?
                                "warning" 
                                : "secondary"
                            }
                            variant="gradient"
                            badgeContent={
                                (arrayPresupuestosMaterial.length > 0 && 
                                    arrayPresupuestosMaterial.some((item)=> item?.aprobado)
                                ) ? 
                                `Presupuestos aprobado (${arrayPresupuestosMaterial.filter((item)=> item?.aprobado).length})`
                                : arrayPresupuestosMaterial.length > 0 ?
                                "Presupuestos pendiente" 
                                : "Sin Presupuestos"
                            }
                            />
                        </SoftBox>
                    </SoftBox>

                    <SoftBox display="flex" alignItems="center" gap={1}>
                        <Tooltip placement="top" title="Agregar Presupuesto">
                            <SoftButton
                                iconOnly
                                color="primary"
                                circular
                                onClick={() => {
                                let newArrayPresupuestosMaterial = [...arrayPresupuestosMaterial];
                                newArrayPresupuestosMaterial.push({
                                    id: new Date().getTime(),
                                    nroPresupuesto: new Date().getTime() % 10000,
                                    nombre: "",
                                    file: "",
                                    aprobado: false,
                                });
                                setArrayPresupuestosMaterial(newArrayPresupuestosMaterial);
                                if(openPresupuesto != "materiales"){
                                    setOpenPresupuesto("materiales");
                                }
                                }}
                            >
                                <Icon>add</Icon>
                            </SoftButton>
                        </Tooltip>

                        <Tooltip placement="top" title="Ver detalle">
                        <SoftButton 
                        onClick={() => setOpenPresupuesto(openPresupuesto === "materiales" ? null : "materiales")}
                        sx={{cursor: "pointer"}}
                        circular 
                        iconOnly 
                        color="sistemasGris"
                        >
                            <Icon >
                            {openPresupuesto === "materiales" ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </Icon>
                        </SoftButton>
                        </Tooltip>
                    </SoftBox>
                </SoftBox>

                <Collapse in={openPresupuesto == "materiales" ? true : false} timeout="auto" > 
                    <SoftBox my={2}>
                        {arrayPresupuestosMaterial.length > 0 ? (
                            <TableContainer>
                            <Table>
                                <SoftBox component="thead">
                                <TableRow>
                                    {columnsPresupuesto.map(({ name, desc, align, width }, key) => {
                                    let pl;
                                    let pr;

                                    if (key === 0) {
                                        pl = 3;
                                        pr = 3;
                                    } else if (key === columnsPresupuesto.length - 1) {
                                        pl = 3;
                                        pr = 3;
                                    } else {
                                        pl = 1;
                                        pr = 1;
                                    }

                                    return (
                                        <SoftBox
                                        key={name}
                                        component="th"
                                        width={width || "auto"}
                                        pt={1.5}
                                        pb={1.25}
                                        pl={align === "left" ? pl : 3}
                                        pr={align === "right" ? pr : 3}
                                        textAlign={align}
                                        fontSize={size.xxs}
                                        fontWeight={fontWeightBold}
                                        color="secondary"
                                        opacity={0.7}
                                        borderBottom={`${borderWidth[1]} solid ${light.main}`}
                                        >
                                        {desc ? desc.toUpperCase() : name.toUpperCase()}
                                        </SoftBox>
                                    );
                                    })}
                                </TableRow>
                                </SoftBox>
                                <TableBody>
                                {arrayPresupuestosMaterial.map((presupuesto, index) => {
                                    return (
                                        <PresupuestoItem
                                        tipo={"Material"}
                                        key={presupuesto.id}
                                        presupuesto={presupuesto}
                                        handleUpdate={(item) => {
                                        // if(item?.aprobado != arrayPresupuestosMaterial[index]?.aprobado){
                                        //     let newArrayPresupuestos = [...arrayPresupuestosMaterial];
                                        //     if(!item?.aprobado){
                                        //     newArrayPresupuestos[index] = item;
                                        //     }else{
                                        //     newArrayPresupuestos = newArrayPresupuestos.map((presupuesto) => {
                                        //         return {...presupuesto, aprobado: presupuesto.id === item.id ? item.aprobado : false, motivoAprobacion: presupuesto.id === item.id ? item.motivoAprobacion : ""};
                                        //     });
                                        //     }
                                        //     setArrayPresupuestosMaterial(newArrayPresupuestos);
                                        // }else{
                                            let newArrayPresupuestos = [...arrayPresupuestosMaterial];
                                            newArrayPresupuestos[index] = item;
                                            setArrayPresupuestosMaterial(newArrayPresupuestos);
                                        // }
                                        }}
                                        handleDelete={() => {
                                            let newArrayPresupuestos = [...arrayPresupuestosMaterial];
                                            newArrayPresupuestos.splice(index, 1);
                                            setArrayPresupuestosMaterial(newArrayPresupuestos);
                                        }}
                                        pb={index === arrayPresupuestosMaterial.length - 1 ? 2 : null}
                                        pt={index === 0 ? 2 : null}
                                        tipoMoneda={tipoMoneda}
                                        totalConceptos={0}
                                    />
                                    );
                                })}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        ) : (
                            <SinDatos />
                        )}
                    </SoftBox>
                </Collapse>
              </SoftBox> 
            </SoftBox>

            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="flex-end"
              alignItems="center"
            >
              <SoftBox
                display={{
                  xs: "flex-row",
                  sm: "flex",
                }}
                justifyContent="end"
                alignItems="center"
              >
                <SoftBox
                  mr={{
                    xs: 0,
                    sm: 2,
                  }}
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="primary"
                    circular
                    onClick={() => {
                      handleClose();
                      resetModal();
                    }}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                    &nbsp;Cancelar
                  </SoftButton>
                </SoftBox>
                <SoftBox
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="sistemasGris"
                    circular
                    // disabled={
                    //   !servicio?.nombre ||
                    //   !servicio?.descripcion ||
                    //   !servicio?.nombreUnidad ||
                    //   (arrayManoObra.length === 0 && arrayMateriales.length === 0 && arrayConceptos.length === 0) ||
                    //   (
                    //     arrayManoObra.length > 0 &&
                    //     arrayManoObra.some(
                    //       (item) => !item.nombre || !item.horas || !item.tipoMoneda || !item.precio || !item.cotizacion || item.horas <= 0
                    //     ) 
                    //   ) ||
                    //   (
                    //     arrayMateriales.length > 0 && 
                    //     arrayMateriales.some(
                    //       (item) => !item.producto || !item.cantidad || !item.tipoMoneda || !item.precio || !item.cotizacion
                    //     ) 
                    //   ) ||
                    //   (
                    //     arrayConceptos.length > 0 && 
                    //     arrayConceptos.some(
                    //       (item) => !item.nombre || !item.tipoMoneda || !item.precio || !item.cotizacion
                    //     )
                    //   )
                    // }
                    onClick={() => {
                      handleClose();
                    }}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    {loadingSave ? (
                      <CircularProgress size={15} color="inherit" />
                    ) : (
                      <Icon sx={{ fontWeight: "light" }}>save</Icon>
                    )}
                    &nbsp;Guardar
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalAdicionales.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  tipoMoneda: PropTypes.string,
  dataFase: PropTypes.object
};
