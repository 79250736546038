// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { getUproColor } from "utils/colors";
import MEDIDAS from "utils/medidas";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
  p: 3,
};

const columnsManoDeObra = [
  { name: "nombre", desc: "Nombre", align: "left", noOrder: true },
  { name: "horas", desc: "Horas", align: "left", noOrder: true, width: "150px" },
  { name: "moneda", desc: "Tipo de Moneda", align: "left", noOrder: true, width: "150px" },
  { name: "precio", desc: "Precio por hora", align: "left", noOrder: true, width: "150px" },
  { name: "cotizacion", desc: "Cotización", align: "left", noOrder: true, width: "150px" },
  { name: "action", desc: " ", align: "left", noOrder: true, width: "1%" },
];

const columnsMaterial = [
  { name: "material", desc: "Material", align: "left", noOrder: true },
  { name: "unidadMedida", desc: "Ud. de Medida", align: "left", noOrder: true, width: "125px" },
  { name: "cantidad", desc: "Cantidad", align: "left", noOrder: true, width: "150px" },
  { name: "moneda", desc: "Tipo de Moneda", align: "left", noOrder: true, width: "150px" },
  { name: "precio", desc: "Precio por unidad", align: "left", noOrder: true, width: "150px" },
  { name: "cotizacion", desc: "Cotización", align: "left", noOrder: true, width: "125px" },
  { name: "action", desc: " ", align: "left", noOrder: true, width: "1%" },
];

const columnsConcepto = [
  { name: "nombre", desc: "Nombre", align: "left", noOrder: true },
  { name: "moneda", desc: "Tipo de Moneda", align: "left", noOrder: true, width: "150px" },
  { name: "precio", desc: "Precio", align: "left", noOrder: true, width: "150px" },
  { name: "cotizacion", desc: "Cotización", align: "left", noOrder: true, width: "150px" },
  { name: "action", desc: " ", align: "left", noOrder: true, width: "1%" },
];

function ManoDeObraItem({ manoDeObra, handleUpdate, handleDelete, pt, pb ,cotizaciones,tipoMoneda, vista}) {
  const { borderWidth } = borders;
  const { size, fontWeightBold } = typography;

  const [materialData, setMaterialData] = useState({
    ...manoDeObra,
  });

  // const handleChanges = (e) => {
  //   const { name, value } = e.target;
  //   setMaterialData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  const handleChanges = (e) => {
    const { name, value } = e.target;
   
    let nuevaMaterialData = null;

    if(name == "tipoMoneda"){
      nuevaMaterialData = {
        ...materialData,
        [name]: value,
        cotizacion: cotizaciones?.find(ctz => ctz?.nombre === value)?.venta || (value === "Pesos" ? 1 : 1),
      }
    }else {
      nuevaMaterialData = {
        ...materialData,
        [name]: value,
      }
    }

    setMaterialData((prevState) => (prevState = nuevaMaterialData));
    handleUpdate(nuevaMaterialData);
  };

  // useEffect(() => {
  //   handleUpdate(materialData);
  // }, [materialData]);

  useEffect(() => {
    setMaterialData({
      ...manoDeObra,
    });
  }, [manoDeObra]);

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pl={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftInput
          label="nombre"
          value={materialData?.nombre}
          name="nombre"
          onChange={handleChanges}
          disabled={vista}
        />
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
            label="horas"
            value={materialData?.horas}
            name="horas"
            onChange={handleChanges}
            icon={{
              component: "schedule",
              direction: "left",
            }}
            error={materialData?.horas?.length > 0 && materialData?.horas <= 0}
            disabled={vista}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <Select
        name="tipoMoneda"
        value={materialData?.tipoMoneda || ""}
        onChange={handleChanges}
        // onChange={(event)=>{
        //     setMaterialData({
        //         ...materialData,
        //         tipoMoneda: event.target.value,
        //         cotizacion: cotizaciones?.find(ctz => ctz?.nombre === event.target.value)?.venta || ((event.target.value === "Pesos" && tipoMoneda !== "Pesos") ? 1 : 1),
        //     })
        // }}
        disabled={vista}
        >
            <MenuItem value="Pesos">Pesos</MenuItem>
            {
                cotizaciones?.map((ctz) => {
                    return (
                        <MenuItem key={ctz?.nombre} value={ctz?.nombre}>
                            {ctz?.nombre}
                        </MenuItem>
                    )
                }) 
            }
        </Select>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
            label="precio"
            value={materialData?.precio}
            name="precio"
            onChange={handleChanges}
            icon={{
              component: "attach_money",
              direction: "left",
            }}
            disabled={vista}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
          name="cotizacion"
          label="cotizacion"
          value={materialData?.cotizacion || ""}
          disabled
            icon={{
              component: "currency_exchange",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pr={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftButton color="error" circular iconOnly onClick={handleDelete} disabled={vista}>
            <Icon>delete</Icon>
          </SoftButton>
        </SoftTypography>
      </SoftBox>
    </TableRow>
  );
}

ManoDeObraItem.propTypes = {
  manoDeObra: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  pb: PropTypes.number,
  pt: PropTypes.number,
  cotizaciones: PropTypes.array.isRequired,
  tipoMoneda: PropTypes.string,
  vista: PropTypes.bool,
};

function MaterialesItem({ material, handleUpdate, handleDelete, pt, pb,cotizaciones,tipoMoneda,listProductos, vista }) {
  const { borderWidth } = borders;
  const { size, fontWeightBold } = typography;

  const [materialData, setMaterialData] = useState({
    ...material,
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    let nuevaMaterialData = null;

    // setMaterialData((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));

    if(name == "tipoMoneda"){
      nuevaMaterialData = {
        ...materialData,
        [name]: value,
        cotizacion: cotizaciones?.find(ctz => ctz?.nombre === value)?.venta || (value === "Pesos" ? 1 : 1),
      }
    }else if(name == "producto"){
      nuevaMaterialData = {
        ...materialData,
        [name]: value,
        precio: value?.precio || "",
      }
    } else {
      nuevaMaterialData = {
        ...materialData,
        [name]: value,
      }
    }

    setMaterialData((prevState) => (prevState = nuevaMaterialData));
    handleUpdate(nuevaMaterialData);
  };

  // useEffect(() => {
  //   handleUpdate(materialData);
  // }, [materialData]);

  useEffect(() => {
    setMaterialData({
      ...material,
    });
  }, [material]);

  // useEffect(() => {
  //   if (materialData?.producto) {
  //     setMaterialData({
  //       ...materialData,
  //       // precio: materialData?.producto?.precio || "",
  //       precio: materialData?.precio ? materialData?.precio : (materialData?.producto?.precio || ""),
  //     });
  //   }
  // }, [materialData?.producto]);

  // useEffect(() => {
  //   if (listProductos) {
  //     setMaterialData({
  //       ...materialData,
  //       material: listProductos?.find(
  //         (producto) => producto.id === materialData?.producto?.id
  //       ),
  //     });
  //   }
  // }, [listProductos]);
  

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pl={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <Select
          name="producto"
          value={
            materialData?.producto?.id
              ? listProductos?.find((producto) => producto.id === materialData?.producto?.id)
              : ""
          }
          onChange={handleChanges}
          // onChange={(e)=>{
          //   setMaterialData({
          //     ...materialData,
          //     producto: e.target.value,
          //     precio: e?.target?.value?.precio || "",
          //   });
          // }}
          disabled={vista}
        >
          {listProductos?.map((producto) => (
            <MenuItem key={producto.id} value={producto}>
              {producto.nombre}
            </MenuItem>
          ))}
        </Select>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftInput
        value={materialData?.producto?.unidadMedida || ""}
        name="unidadMedida"
        label="unidadMedida"
        disabled
        />
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
            label="cantidad"
            value={materialData?.cantidad}
            name="cantidad"
            onChange={handleChanges}
            error={materialData?.cantidad === 0}
            icon={{
              component: "shopping_cart",
              direction: "left",
            }}
            disabled={vista}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <Select
        name="tipoMoneda"
        value={materialData?.tipoMoneda || ""}
        onChange={handleChanges}
        // onChange={(event)=>{
        //     setMaterialData({
        //         ...materialData,
        //         tipoMoneda: event.target.value,
        //         cotizacion: cotizaciones?.find(ctz => ctz?.nombre === event.target.value)?.venta || ((event.target.value === "Pesos" && tipoMoneda !== "Pesos") ? 1 : 1),
        //     })
        // }}
        disabled={vista}
        >
            <MenuItem value="Pesos">Pesos</MenuItem>
            {
                cotizaciones?.map((ctz) => {
                    return (
                        <MenuItem key={ctz?.nombre} value={ctz?.nombre}>
                            {ctz?.nombre}
                        </MenuItem>
                    )
                }) 
            }
        </Select>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
            label="precio"
            value={materialData?.precio}
            name="precio"
            onChange={handleChanges}
            icon={{
              component: "attach_money",
              direction: "left",
            }}
            disabled={vista}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
          name="cotizacion"
          label="cotizacion"
          value={materialData?.cotizacion || ""}
            icon={{
              component: "currency_exchange",
              direction: "left",
            }}
          disabled
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pr={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftButton color="error" circular iconOnly onClick={handleDelete} disabled={vista}>
            <Icon>delete</Icon>
          </SoftButton>
        </SoftTypography>
      </SoftBox>
    </TableRow>
  );
}

MaterialesItem.propTypes = {
  material: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  pb: PropTypes.number,
  pt: PropTypes.number,
  cotizaciones: PropTypes.array.isRequired,
  tipoMoneda: PropTypes.string,
  listProductos: PropTypes.array.isRequired,
  vista: PropTypes.bool,
};

function ConceptoItem({ concepto, handleUpdate, handleDelete, pt, pb,cotizaciones,tipoMoneda, vista }) {
  const { borderWidth } = borders;
  const { size, fontWeightBold } = typography;

  const [conceptoData, setConceptoData] = useState({
    ...concepto,
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setConceptoData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleUpdate(conceptoData);
  }, [conceptoData]);

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pl={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftInput
          label="nombre"
          value={conceptoData?.nombre}
          name="nombre"
          onChange={handleChanges}
          disabled={vista}
        />
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <Select
        name="tipoMoneda"
        value={conceptoData?.tipoMoneda || ""}
        onChange={(event)=>{
          setConceptoData({
            ...conceptoData,
            tipoMoneda: event.target.value,
            cotizacion: cotizaciones?.find(ctz => ctz?.nombre === event.target.value)?.venta || ((event.target.value === "Pesos" && tipoMoneda !== "Pesos") ? 1 : 1),
          })
        }}
        disabled={vista}
        >
            <MenuItem value="Pesos">Pesos</MenuItem>
            {
                cotizaciones?.map((ctz) => {
                    return (
                        <MenuItem key={ctz?.nombre} value={ctz?.nombre}>
                            {ctz?.nombre}
                        </MenuItem>
                    )
                }) 
            }
        </Select>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
            label="precio"
            value={conceptoData?.precio}
            name="precio"
            onChange={handleChanges}
            icon={{
              component: "attach_money",
              direction: "left",
            }}
            disabled={vista}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
          name="cotizacion"
          label="cotizacion"
          value={conceptoData?.cotizacion || ""}
          onChange={handleChanges}
            icon={{
              component: "currency_exchange",
              direction: "left",
            }}
            disabled={vista}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pr={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftButton color="error" circular iconOnly onClick={handleDelete} disabled={vista}>
            <Icon>delete</Icon>
          </SoftButton>
        </SoftTypography>
      </SoftBox>
    </TableRow>
  );
}

ConceptoItem.propTypes = {
  concepto: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  pb: PropTypes.number,
  pt: PropTypes.number,
  cotizaciones: PropTypes.array.isRequired,
  tipoMoneda: PropTypes.string,
  vista: PropTypes.bool,
};

export default function ModalNewFase({ open, handleClose, guardarFase, dataFase ,tipoMoneda,soloVista}) {
  const [servicio, setServicio] = useState(dataFase);
  const { handleSnackbar } = useContext(MessageManager);
  const [arrayManoObra, setArrayManoObra] = useState(dataFase?.manoDeObra || []);
  const [arrayMateriales, setArrayMateriales] = useState(dataFase?.materiales || []);
  const [arrayConceptos, setArrayConceptos] = useState(dataFase?.concepto || []);
  const { borderWidth } = borders;
  const { size, fontWeightBold } = typography;
  const { light } = colors;
  const [btonActualizar, setBtonActualizar] = useState(false);

  const {data:cotizacion} = useQuery(
    gql`
        query getCotizaciones{
            cotizaciones{
            agencia
            compra
            decimales
            fechaActualizacion
            nombre
            variacion
            variacion
            venta
            }
        }
    `,{
      fetchPolicy: 'no-cache'
    }
  );
  const {data: indiceCAC} = useQuery(
    gql`
      query getIndiceCAC {
        indiceLast{
          id
          periodo
          general
        }
      }
    `,{
      fetchPolicy: 'no-cache'
    }
  );

  const { data: productos } = useQuery(
    gql`
      query getProductos {
        productos {
          id
          nombre
          precio
          unidadMedida
        }
      }
    `
  );

  const handleChanges = (e) => {
    setServicio({
      ...servicio,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setServicio(dataFase);
    setArrayManoObra(dataFase?.manoDeObra || []);
    setArrayMateriales(dataFase?.materiales || []);
    setArrayConceptos(dataFase?.concepto || []);
  }, [dataFase]);

  const handleActualizarPrecios = () => {
    let arrayManoObraNuevo = [];
    let arrayMaterialesNuevo = [];

    if(arrayManoObra.length > 0){
      arrayManoObra.forEach((manoObra)=>{
        arrayManoObraNuevo.push({
          ...manoObra,
          ...actualizarMoneda(manoObra)
        })
      })
    }

    if(arrayMateriales.length > 0){
      arrayMateriales.forEach((material)=>{
        arrayMaterialesNuevo.push({
          ...material,
          ...actualizarMoneda(material)
        })
      })
    }
    
    setArrayManoObra(arrayManoObraNuevo);
    setArrayMateriales(arrayMaterialesNuevo);
    setBtonActualizar(true);
  }

  const actualizarMoneda = (detalleFase) => {
    const cotizacionActual = cotizacion?.cotizaciones?.find((ctz)=> ctz?.nombre == detalleFase?.tipoMoneda)?.venta || (detalleFase?.tipoMoneda == "Pesos CAC" ? indiceCAC?.indiceLast?.general : 1);
    
    let precioActual = 0;
    if(detalleFase?.tipoMoneda != "Pesos"){
      if(detalleFase?.tipoMoneda == "Pesos CAC"){
        const indiceMensual = (((cotizacionActual - detalleFase?.cotizacion) / detalleFase?.cotizacion) * 100).toFixed(2);
        let precioActualizado = detalleFase?.precio * (1 + (indiceMensual / 100));

        precioActual =  precioActualizado.toFixed(2);
      }else{
        precioActual = parseFloat(detalleFase?.precio * (cotizacionActual / detalleFase?.cotizacion)).toFixed(2);
      }
    }else{
      precioActual = parseFloat(detalleFase?.precio).toFixed(2);
    }
    // let precioActual = (detalleFase?.tipoMoneda == "Pesos" || detalleFase?.tipoMoneda == "Pesos CAC") ? detalleFase?.precio : detalleFase?.precio * (cotizacionActual / detalleFase?.cotizacion);
    
    return({
      tipoMoneda: detalleFase?.tipoMoneda,
      precio: precioActual ? parseFloat(precioActual) : 0,
      cotizacion: cotizacionActual
    });
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Información de la fase</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        handleClose();
                        setBtonActualizar(false);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox mb={3}>
              <SoftBox mb={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={9}>
                    <InputLabel htmlFor="nombre">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Nombre
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="nombre"
                      placeholder="Ej.: Colocación de cimientos para casa"
                      type="text"
                      name="nombre"
                      value={servicio?.nombre || ""}
                      error={servicio?.nombre?.length > 0 && servicio?.nombre.trim() == ""}
                      onChange={handleChanges}
                      disabled={soloVista}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <InputLabel htmlFor="nombreUnidad">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Nombre Unidad
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <Select
                     label="nombreUnidad"
                     placeholder="Ej.: m2"
                     name="nombreUnidad"
                     value={servicio?.nombreUnidad || ""}
                     onChange={handleChanges}
                     disabled={soloVista}
                    >
                      {MEDIDAS.map((medida) => (
                        <MenuItem value={medida.abreviatura} key={medida.abreviatura}>
                          {medida.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <InputLabel htmlFor="descripcion">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Descripción
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="descripcion"
                      placeholder="Inserte el descripcion de servicio"
                      type="text"
                      multiline
                      rows={3}
                      name="descripcion"
                      value={servicio?.descripcion || ""}
                      error={
                        servicio?.descripcion?.length > 0 && servicio?.descripcion.trim() == ""
                      }
                      onChange={handleChanges}
                      disabled={soloVista}
                    />
                  </Grid>
                </Grid>
              </SoftBox>

              <SoftBox mb={3}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                  <SoftTypography variant="h6">Mano de obra por unidad</SoftTypography>
                  {
                    !soloVista && (
                      <SoftButton
                        color="primary"
                        circular
                        onClick={() => {
                          let newArrayManoObra = [...arrayManoObra];
                          newArrayManoObra.push({
                            id: new Date().getTime(),
                            nombre: "",
                            tipoMoneda: "Pesos",
                            precio: "",
                            cotizacion: 1,
                            horas: "",
                          });
                          setArrayManoObra(newArrayManoObra);
                        }}
                      >
                        Agregar &nbsp;
                        <Icon>add</Icon>
                      </SoftButton>
                    )
                  }
                </SoftBox>
                <SoftBox my={2}>
                  {arrayManoObra.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          <TableRow>
                            {columnsManoDeObra.map(({ name, desc, align, width }, key) => {
                              let pl;
                              let pr;

                              if (key === 0) {
                                pl = 3;
                                pr = 3;
                              } else if (key === columnsManoDeObra.length - 1) {
                                pl = 3;
                                pr = 3;
                              } else {
                                pl = 1;
                                pr = 1;
                              }

                              return (
                                <SoftBox
                                  key={name}
                                  component="th"
                                  width={width || "auto"}
                                  pt={1.5}
                                  pb={1.25}
                                  pl={align === "left" ? pl : 3}
                                  pr={align === "right" ? pr : 3}
                                  textAlign={align}
                                  fontSize={size.xxs}
                                  fontWeight={fontWeightBold}
                                  color="secondary"
                                  opacity={0.7}
                                  borderBottom={`${borderWidth[1]} solid ${light.main}`}
                                >
                                  {desc ? desc.toUpperCase() : name.toUpperCase()}
                                </SoftBox>
                              );
                            })}
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          {arrayManoObra.map((manoDeObra, index) => {
                            return (
                              <ManoDeObraItem
                                key={manoDeObra.id}
                                manoDeObra={manoDeObra}
                                handleUpdate={(item) => {
                                  let newArrayManoObra = [...arrayManoObra];
                                  newArrayManoObra[index] = item;
                                  setArrayManoObra(newArrayManoObra);
                                }}
                                handleDelete={() => {
                                  let newArrayManoObra = [...arrayManoObra];
                                  newArrayManoObra.splice(index, 1);
                                  setArrayManoObra(newArrayManoObra);
                                }}
                                pb={index === arrayManoObra.length - 1 ? 2 : null}
                                pt={index === 0 ? 2 : null}
                                cotizaciones={[{nombre: "Pesos CAC", venta: indiceCAC?.indiceLast?.general},...cotizacion?.cotizaciones]}
                                tipoMoneda={tipoMoneda}
                                vista={soloVista}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </SoftBox>

              <SoftBox mb={3}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                  <SoftTypography variant="h6">Materiales por unidad</SoftTypography>
                  {
                    !soloVista && (
                      <SoftButton
                        color="primary"
                        circular
                        onClick={() => {
                          let newArrayMateriales = [...arrayMateriales];
                          newArrayMateriales.push({
                            id: new Date().getTime(),
                            cantidad: "",
                            tipoMoneda: "Pesos",
                            precio: "",
                            cotizacion: 1,
                          });
                          setArrayMateriales(newArrayMateriales);
                        }}
                      >
                        Agregar &nbsp;
                        <Icon>add</Icon>
                      </SoftButton>
                    )
                  }
                </SoftBox>
                <SoftBox my={2}>
                  {arrayMateriales.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          <TableRow>
                            {columnsMaterial.map(({ name, desc, align, width }, key) => {
                              let pl;
                              let pr;

                              if (key === 0) {
                                pl = 3;
                                pr = 3;
                              } else if (key === columnsMaterial.length - 1) {
                                pl = 3;
                                pr = 3;
                              } else {
                                pl = 1;
                                pr = 1;
                              }

                              return (
                                <SoftBox
                                  key={name}
                                  component="th"
                                  width={width || "auto"}
                                  pt={1.5}
                                  pb={1.25}
                                  pl={align === "left" ? pl : 3}
                                  pr={align === "right" ? pr : 3}
                                  textAlign={align}
                                  fontSize={size.xxs}
                                  fontWeight={fontWeightBold}
                                  color="secondary"
                                  opacity={0.7}
                                  borderBottom={`${borderWidth[1]} solid ${light.main}`}
                                >
                                  {desc ? desc.toUpperCase() : name.toUpperCase()}
                                </SoftBox>
                              );
                            })}
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          {arrayMateriales.map((material, index) => {
                            return (
                              <MaterialesItem
                                key={material.id}
                                material={material}
                                handleUpdate={(item) => {
                                  let newArrayMateriales = [...arrayMateriales];
                                  newArrayMateriales[index] = item;
                                  setArrayMateriales(newArrayMateriales);
                                }}
                                handleDelete={() => {
                                  let newArrayMateriales = [...arrayMateriales];
                                  newArrayMateriales.splice(index, 1);
                                  setArrayMateriales(newArrayMateriales);
                                }}
                                pb={index === arrayMateriales.length - 1 ? 2 : null}
                                pt={index === 0 ? 2 : null}
                                cotizaciones={[{nombre: "Pesos CAC", venta: indiceCAC?.indiceLast?.general},...cotizacion?.cotizaciones]}
                                tipoMoneda={tipoMoneda}
                                listProductos={productos?.productos}
                                vista={soloVista}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </SoftBox>

              <SoftBox mb={3}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                  <SoftTypography variant="h6">Conceptos</SoftTypography>
                  {
                    !soloVista && (
                      <SoftButton
                        color="primary"
                        circular
                        onClick={() => {
                          let newArrayConceptos = [...arrayConceptos];
                          newArrayConceptos.push({
                            id: new Date().getTime(),
                            nombre: "",
                            tipoMoneda: "Pesos",
                            precio: "",
                            cotizacion: 1,
                          });
                          setArrayConceptos(newArrayConceptos);
                        }}
                      >
                        Agregar &nbsp;
                        <Icon>add</Icon>
                      </SoftButton>
                    )
                  }
                </SoftBox>
                <SoftBox my={2}>
                  {arrayConceptos.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          <TableRow>
                            {columnsConcepto.map(({ name, desc, align, width }, key) => {
                              let pl;
                              let pr;

                              if (key === 0) {
                                pl = 3;
                                pr = 3;
                              } else if (key === columnsConcepto.length - 1) {
                                pl = 3;
                                pr = 3;
                              } else {
                                pl = 1;
                                pr = 1;
                              }

                              return (
                                <SoftBox
                                  key={name}
                                  component="th"
                                  width={width || "auto"}
                                  pt={1.5}
                                  pb={1.25}
                                  pl={align === "left" ? pl : 3}
                                  pr={align === "right" ? pr : 3}
                                  textAlign={align}
                                  fontSize={size.xxs}
                                  fontWeight={fontWeightBold}
                                  color="secondary"
                                  opacity={0.7}
                                  borderBottom={`${borderWidth[1]} solid ${light.main}`}
                                >
                                  {desc ? desc.toUpperCase() : name.toUpperCase()}
                                </SoftBox>
                              );
                            })}
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          {arrayConceptos.map((concepto, index) => {
                            return (
                              <ConceptoItem
                                key={concepto.id}
                                concepto={concepto}
                                handleUpdate={(item) => {
                                  let newArrayConceptos = [...arrayConceptos];
                                  newArrayConceptos[index] = item;
                                  setArrayConceptos(newArrayConceptos);
                                }}
                                handleDelete={() => {
                                  let newArrayConceptos = [...arrayConceptos];
                                  newArrayConceptos.splice(index, 1);
                                  setArrayConceptos(newArrayConceptos);
                                }}
                                pb={index === arrayConceptos.length - 1 ? 2 : null}
                                pt={index === 0 ? 2 : null}
                                cotizaciones={cotizacion?.cotizaciones}
                                tipoMoneda={tipoMoneda}
                                vista={soloVista}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </SoftBox>
            </SoftBox>

            {
              !soloVista && (
              <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                <SoftBox>
                    {
                     (dataFase?.numero && dataFase?.numero != null) && (
                      <SoftButton  
                      color="info"
                      variant="gradient"
                      circular
                      disabled={btonActualizar}
                      onClick={()=>handleActualizarPrecios()}
                      >
                        <Icon sx={{ fontWeight: "light" }}>currency_exchange</Icon>&nbsp;
                        Actualizar precios
                      </SoftButton>
                     )
                    }
                  </SoftBox>

                  <SoftBox
                    display={{
                      xs: "flex-row",
                      sm: "flex",
                    }}
                    justifyContent="end"
                    alignItems="center"
                  >
                    <SoftBox
                      mr={{
                        xs: 0,
                        sm: 2,
                      }}
                      mt={{
                        xs: 2,
                        sm: 0,
                      }}
                    >
                      <SoftButton
                        color="primary"
                        circular
                        onClick={() => {
                          handleClose();
                          setBtonActualizar(false);
                        }}
                        fullWidth={{
                          xs: true,
                          sm: false,
                        }}
                      >
                        <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                        &nbsp;Cancelar
                      </SoftButton>
                    </SoftBox>
                    <SoftBox
                      mt={{
                        xs: 2,
                        sm: 0,
                      }}
                    >
                      <SoftButton
                        color="sistemasGris"
                        circular
                        disabled={
                          !servicio?.nombre ||
                          !servicio?.descripcion ||
                          !servicio?.nombreUnidad ||
                          (arrayManoObra.length === 0 && arrayMateriales.length === 0 && arrayConceptos.length === 0) ||
                          (
                            arrayManoObra.length > 0 &&
                            arrayManoObra.some(
                              (item) => !item.nombre || !item.horas || !item.tipoMoneda || !item.precio || !item.cotizacion || item.horas <= 0
                            ) 
                          ) ||
                          (
                            arrayMateriales.length > 0 && 
                            arrayMateriales.some(
                              (item) => !item.producto || !item.cantidad || !item.tipoMoneda || !item.precio || !item.cotizacion
                            ) 
                          ) ||
                          (
                            arrayConceptos.length > 0 && 
                            arrayConceptos.some(
                              (item) => !item.nombre || !item.tipoMoneda || !item.precio || !item.cotizacion
                            )
                          )
                        }
                        onClick={() => {
                          if(arrayConceptos?.length > 0 && arrayManoObra?.length == 0 && arrayMateriales?.length == 0){
                            guardarFase({
                              ...servicio,
                              cantidad: 1,
                              manoDeObra: arrayManoObra,
                              materiales: arrayMateriales,
                              concepto: arrayConceptos,
                            });
                          }else{
                            guardarFase({
                              ...servicio,
                              manoDeObra: arrayManoObra,
                              materiales: arrayMateriales,
                              concepto: arrayConceptos,
                            });
                          }
                        }}
                        fullWidth={{
                          xs: true,
                          sm: false,
                        }}
                      >
                        <Icon sx={{ fontWeight: "light" }}>save</Icon>
                        &nbsp;Guardar
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              )
            }
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalNewFase.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  guardarFase: PropTypes.func,
  dataFase: PropTypes.object,
  tipoMoneda: PropTypes.string,
  soloVista: PropTypes.bool,
};
