import { Grid } from "@mui/material";
// import Logo from "assets/images/logoKaizen.png";
import Logo from "assets/images/logo.png";
import boxShadows from "assets/theme/base/boxShadows";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { nombreEmpresa } from "services/config";
import { formatMoneyPunto } from "utils/formatters";

const PresupuestoPDF = ({ proyecto,presupuesto, setFile }) => {
  const documentsRef = useRef(null);
  const { lg } = boxShadows;

  const handleGeneratePdf = async () => {
    const document = documentsRef.current.childNodes[0];

    const doc = new jsPDF({
      format: "a4",
      unit: "px",
      orientation: "portrait",
      hotfixes: ["px_scaling"],
    });

    await doc.html(document, {
      callback(doc) {
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const pageSize = doc.internal.pageSize;
          const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          const header = `Presupuesto Nº${proyecto?.numero}-v${proyecto?.version}`;
          const brand = nombreEmpresa;
          const footer = `© ${dayjs().year()} Powered by Kaizen Software Factory`;
          const date = dayjs().format("DD/MM/YYYY");

          // Header
          doc.setFontSize(10);

          if (i !== 1) {
            doc.text(header, 40, 15, { baseline: "top" });
            doc.text(brand, pageWidth - 40 - doc.getTextWidth(brand), 15, { baseline: "top" });
          }

          // Footer
          doc.text(footer, 40, pageHeight - 15, { baseline: "bottom" });
          doc.text(date, pageWidth - 40 - doc.getTextWidth(date), pageHeight - 15, {
            baseline: "bottom",
          });
        }
        setFile(doc.output("blob"));
      },
    });
  };

  useEffect(() => {
    if (proyecto) {
      handleGeneratePdf();
    }
  }, [proyecto]);

  const getTotal = () => {
    let total = 0;
    proyecto?.fases?.forEach((fase) => {
      const cantidad = parseInt(fase?.cantidad) || 0;
      const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;
      let content = [
        ...(fase?.manoDeObra || []), 
        ...(fase?.materiales || []), 
        ...(fase?.concepto || [])
      ];      
      let subtotal = 0;
      content?.forEach((item) => {
        if (item.horas) {
          subtotal += item.precio * item.horas * cantidad || 0;
        } else if (item.cantidad) {
          subtotal += item.precio * item.cantidad * cantidad || 0;
        } else {
          subtotal += item.precio || 0;
        }
      });
      subtotal += subtotal * (porcentaje / 100) || 0;
      total += subtotal;
    });
    return total;
  };

  const getSubTotal = (fase) => {
    let total = 0;
    const cantidad = parseInt(fase?.cantidad) || 0;
    const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;
    let content = [
      ...(fase?.manoDeObra || []), 
      ...(fase?.materiales || []), 
      ...(fase?.concepto || [])
    ];  
    content?.forEach((item) => {
      if (item.horas) {
        total += item.precio * item.horas * cantidad || 0;
      } else if (item.cantidad) { 
        total += item.precio * item.cantidad * cantidad || 0;
      } else {
        total += item.precio || 0;
      }
    });
    total += total * (porcentaje / 100) || 0;
    return total;
  };

  const getTotalFase = (fase) => {
    const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;
    let total = 0;
  
    const calcularTotal = (items, cantidad = 1) => {
      return items.reduce((acc, item) => {
        if (item.horas) {
          return acc + item.precio * item.horas * cantidad;
        } else if (item.cantidad) {
          return acc + item.precio * item.cantidad * cantidad;
        } else {
          return acc + item.precio;
        }
      }, 0);
    };
  
    if (fase?.nombreUnidad) {
      const cantidad = parseInt(fase?.cantidad) || 0;
      const content = [
        ...(fase?.manoDeObra || []),
        ...(fase?.materiales || []),
        ...(fase?.concepto || []),
      ];
      total = calcularTotal(content, cantidad);
    } else if (fase?.presupuesto?.some((item) => item.aprobado == 1)) {
      total = fase.presupuesto.find((item) => item.aprobado == 1)?.precio || 0;
    } else {
      const content = [...(fase?.concepto || [])];
      total = calcularTotal(content);
    }
  
    total += total * (porcentaje / 100);
    return total;
  };

  const getTotalPresupuesto = () => {
    let total = 0;

    proyecto?.fases?.forEach((fase) => {
      let subtotal = 0;
      const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;
      const calcularTotal = (items, cantidad = 1) => {
        return items.reduce((acc, item) => {
          if (item.horas) {
            return acc + item.precio * item.horas * cantidad;
          } else if (item.cantidad) {
            return acc + item.precio * item.cantidad * cantidad;
          } else {
            return acc + item.precio;
          }
        }, 0);
      };
    
      if (fase?.nombreUnidad) {
        const cantidad = parseInt(fase?.cantidad) || 0;
        const content = [
          ...(fase?.manoDeObra || []),
          ...(fase?.materiales || []),
          ...(fase?.concepto || []),
        ];
        subtotal = calcularTotal(content, cantidad);
      } else if (fase?.presupuesto?.some((item) => item.aprobado == 1)) {
        subtotal = fase.presupuesto.find((item) => item.aprobado == 1)?.precio || 0;
      } else {
        const content = [...(fase?.concepto || [])];
        subtotal = calcularTotal(content);
      }
    
      subtotal += subtotal * (porcentaje / 100);
      total += subtotal;
    });
  
    return total;
  };

  return (
    <SoftBox
      p={2}
      display="flex"
      alignItems="center"
      flexDirection="column"
      minHeight="100vh"
      height="100%"
    >
      <div ref={documentsRef}>
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{
            position: "relative",
            overflow: "hidden",
            width: "794px",
            height: "1110px",
            backgroundColor: "#ffffff",
            boxShadow: lg,
            px: 6,
            pt: 3,
          }}
        >
          <SoftBox
            sx={{
              flexGrow: 1,
            }}
          >
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <img src={Logo} alt="Terra" width="120px" />
              <SoftBox display="flex" flexDirection="column" gap={1} alignItems="end">
                <SoftTypography variant="caption">{nombreEmpresa}</SoftTypography>
                <SoftTypography variant="caption">{`Presupuesto Nº${proyecto?.numero}-v${proyecto?.version}`}</SoftTypography>
              </SoftBox>
            </SoftBox>

            <SoftBox>
              <SoftBox mb={3}>
                <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                  Cotización
                </SoftTypography>
                <SoftTypography variant="h6" textAlign="center">
                  {`Proyecto: ${proyecto?.nombre}`}
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={3}>
                <SoftTypography variant="h6" fontWeight="bold">
                  Detalles del presupuesto
                </SoftTypography>
                <Grid container>
                  <Grid item xs={6}>
                    <SoftTypography variant="caption" fontWeight="bold">
                      Nombre del proyecto:&nbsp;
                      <SoftTypography variant="caption">{proyecto?.nombre}</SoftTypography>
                    </SoftTypography>
                  </Grid>
                  {proyecto?.responsable && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Responsable:&nbsp;
                        <SoftTypography variant="caption">{`${proyecto?.responsable?.nombre} ${proyecto?.responsable?.apellido}`}</SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.direccion && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Dirección:&nbsp;
                        <SoftTypography variant="caption">{`${proyecto?.direccion}, ${proyecto?.provincia?.nombre || proyecto?.localidad?.provincia?.nombre}, ${proyecto?.localidad?.nombre}`}</SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.cliente && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Cliente:&nbsp;
                        <SoftTypography variant="caption">
                          {
                            proyecto?.cliente?.tipoCliente == "Particular" ? 
                            `${proyecto?.cliente?.nombre} ${proyecto?.cliente?.apellido}` : 
                            `${proyecto?.cliente?.razonSocial}`
                          }
                          </SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.vendedor && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Vendedor:&nbsp;
                        <SoftTypography variant="caption">{`${proyecto?.vendedor?.nombre} ${proyecto?.vendedor?.apellido}`}</SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.nroContrato && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Nº Contrato:&nbsp;
                        <SoftTypography variant="caption">{proyecto?.nroContrato}</SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.fechaInicioEstimada && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Fecha de inicio:&nbsp;
                        <SoftTypography variant="caption">
                          {proyecto?.fechaInicioEstimada}
                        </SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.fechaFinEstimada && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Fecha de fin:&nbsp;
                        <SoftTypography variant="caption">
                          {proyecto?.fechaFinEstimada}
                        </SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.terminosPago && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Términos de pago:&nbsp;
                        <SoftTypography variant="caption">{proyecto?.terminosPago}</SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.importeDeposito && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Depósito:&nbsp;
                        <SoftTypography variant="caption">
                          ${formatMoneyPunto(proyecto?.importeDeposito)}
                        </SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.notasExtra && (
                    <Grid item xs={12}>
                      <SoftTypography variant="caption" fontWeight="text">
                        {proyecto?.notasExtra}
                      </SoftTypography>
                    </Grid>
                  )}
                </Grid>
              </SoftBox>
              <SoftBox lineHeight={1}>
                {
                  presupuesto?.ocultarFases ? (
                    <>
                      <SoftTypography variant="h6" fontWeight="bold" mb={1}>
                        Descripción del proyecto
                      </SoftTypography>

                      <SoftTypography variant="caption" fontWeight="text" lineHeight={1}>
                        {presupuesto?.notasExtra}
                      </SoftTypography>
                    </>
                  ) : (
                    <>
                      <SoftTypography variant="h6" fontWeight="bold" mb={2}>
                        Etapas del proyecto
                      </SoftTypography>
                      {
                        proyecto?.etapas?.map((etapa, index) => (
                          <SoftBox key={index} mb={3} borderBottom={"1px solid #dee2e6"}>
                            <SoftTypography  variant="h6" fontWeight="bold">
                              {index+1}. {etapa?.nombre}
                            </SoftTypography>

                            {
                              proyecto?.fases?.map((fase, indexFase) => (
                                fase?.idEtapa === etapa?.id && (
                                  <SoftBox key={indexFase} px={1} py={2}>
                                    <SoftBox display="flex" gap={4}>
                                      <SoftBox display="flex" justifyContent="space-between" alignItems="center" flexGrow={1}>
                                        <SoftTypography variant="caption" fontWeight="bold">
                                          {fase?.nombre} (Cant.: {fase?.cantidad || 1} {fase?.nombreUnidad || "Ud."})
                                        </SoftTypography>

                                        <SoftTypography variant="caption" fontWeight="bold">
                                          $ {formatMoneyPunto(getTotalFase(fase))}
                                        </SoftTypography>
                                      </SoftBox>
                                    </SoftBox>

                                    <SoftBox pl={3} mt={0.5} lineHeight={1}>
                                      <SoftTypography variant="caption" fontWeight="text">
                                        {fase?.descripcion}
                                      </SoftTypography>
                                    </SoftBox>
                                  </SoftBox>
                                )
                              ))
                            }
                          </SoftBox>
                        ))
                      }
                      {/* {
                        proyecto?.fases?.map((fase, index) => (
                          <SoftBox key={index} borderBottom={"1px solid #dee2e6"} px={1} py={3}>
                            <SoftBox display="flex" gap={4}>
                              <SoftTypography variant="caption" fontWeight="bold">
                                {index+1}. 
                              </SoftTypography>

                              <SoftBox display="flex" justifyContent="space-between" alignItems="center" flexGrow={1}>
                                <SoftTypography variant="caption" fontWeight="bold">
                                  {fase?.nombre} (Cant.: {fase?.cantidad || 1} {fase?.nombreUnidad || "Ud."})
                                </SoftTypography>

                                <SoftTypography variant="caption" fontWeight="bold">
                                  $ {formatMoneyPunto(getTotalFase(fase))}
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>

                            <SoftBox pl={5.5} mt={0.5} lineHeight={1}>
                              <SoftTypography variant="caption" fontWeight="text">
                                {fase?.descripcion}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        ))
                      } */}
                      {
                        proyecto?.fases?.length > 0 && (
                          <SoftBox display="flex" justifyContent="flex-end" alignItems="center" mt={3} pr={1}>
                              <SoftTypography variant="caption" fontWeight="bold">
                                Total: $ {formatMoneyPunto(getTotalPresupuesto())}
                              </SoftTypography>
                          </SoftBox>
                        )
                      }
                    </>
                  )
                }  
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </div>
    </SoftBox>
  );
};

PresupuestoPDF.propTypes = {
  proyecto: PropTypes.object,
  presupuesto: PropTypes.object,
  setFile: PropTypes.func,
};

export default PresupuestoPDF;
