// React
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import { Grid, Icon, InputLabel, MenuItem, Select } from "@mui/material";

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import ModalAddDeposito from "layouts/productos/components/ModalSucursales/ModalAddDeposito";
import SoftInputNumber from "components/SoftInputNumberUpgrade";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  overflowY: "auto",
  p: 3,
};

export default function ProyectoDetalles({ proyecto, setProyecto, large, readOnly }) {
  const [modalSucursal,setModalSucursal] = useState(false);
  const { data: clientes } = useQuery(
    gql`
      query getClientes {
        clientes {
          id
          nombre
          apellido
          tipoCliente
          razonSocial
        }
      }
    `
  );

  const { data: usuarios } = useQuery(
    gql`
      query getUsuarios {
        usuarios {
          id
          nombre
          apellido
        }
      }
    `
  );

  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);

  const {data:cotizacion} = useQuery(
    gql`
        query getCotizaciones{
            cotizaciones{
            agencia
            compra
            decimales
            fechaActualizacion
            nombre
            variacion
            variacion
            venta
            }
        }
    `
  );

  const { data: sucursales, refetch: refetchSucursales } = useQuery(
    gql`
      query getSucursales {
        sucursales {
          id
          nombre
          sede {
            id
            nombre
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (sucursales?.sucursales?.length > 0 && !proyecto?.sucursal && !proyecto?.idSucursal && large) {
          setProyecto({
            ...proyecto,
            idSucursal: sucursales?.sucursales[0].id ?? "",
            sucursal: sucursales?.sucursales.find((sucursal) => sucursal.id === sucursales?.sucursales[0].id ),
          })
        }
      }
    }
  );

  useEffect(() => {
    if (proyecto?.idProvincia) {
      getLocalidades({ variables: { provincia: proyecto?.idProvincia } }).then((data) => {
        if (!data?.data?.localidades?.find((localidad) => localidad.id === proyecto?.idLocalidad)) {
          let proyectoNew = { ...proyecto };
          delete proyectoNew.idLocalidad;
          setProyecto(proyectoNew);
        }
      });
    }
  }, [proyecto?.idProvincia]);

  return (
    <SoftBox>
      {large && (
        <SoftBox display="flex" justifyContent="space-between" mb={2}>
          <SoftBox>
            <SoftTypography variant="h6">{`Nuevo proyecto - Detalles del proyecto`}</SoftTypography>
          </SoftBox>
        </SoftBox>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="nombre">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Nombre del proyecto
              {!readOnly && (
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              )}
            </SoftTypography>
          </InputLabel>
          {readOnly ? (
            <SoftTypography variant="h6">{proyecto?.nombre}</SoftTypography>
          ) : (
            <SoftInput
              label="nombre"
              placeholder="Ingrese el nombre del proyecto"
              type="text"
              name="nombre"
              value={proyecto?.nombre}
              onChange={(e) => setProyecto({ ...proyecto, nombre: e.target.value })}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="tipo">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Tipo de proyecto
              {!readOnly && (
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              )}
            </SoftTypography>
          </InputLabel>
          {readOnly ? (
            <SoftTypography variant="h6">{proyecto?.cliente ? "Cliente" : "Propio"}</SoftTypography>
          ) : (
            <Select
              fullWidth
              label="Tipo de proyecto"
              value={proyecto?.tipo || -1}
              onChange={(e) => {
                if (e.target.value === "Propio") {
                  setProyecto({ ...proyecto, idCliente: null, tipo: e.target.value });
                } else {
                  setProyecto({ ...proyecto, tipo: e.target.value });
                }
              }}
            >
              <MenuItem value={-1} disabled>
                Seleccione un tipo
              </MenuItem>
              <MenuItem value={"Propio"}>Proyecto propio</MenuItem>
              <MenuItem value={"Cliente"}>Proyecto a cliente</MenuItem>
            </Select>
          )}
        </Grid>
        {(proyecto?.tipo === "Cliente" || proyecto?.cliente) && (
          <Grid item xs={12} sm={large ? 4 : 6}>
            <InputLabel htmlFor="cliente">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Cliente
                {!readOnly && (
                  <SoftTypography variant="caption" fontWeight="bold" color="primary">
                    &nbsp;*
                  </SoftTypography>
                )}
              </SoftTypography>
            </InputLabel>
            {readOnly ? (
              <SoftTypography variant="h6">
                {
                  proyecto?.cliente?.tipoCliente == "Particular" ?
                    `${proyecto?.cliente?.nombre} ${proyecto?.cliente?.apellido}` :
                    `${proyecto?.cliente?.razonSocial}`
                }
              </SoftTypography>
            ) : (
              <Select
                fullWidth
                label="Cliente"
                value={proyecto?.idCliente || -1}
                onChange={(e) =>
                  setProyecto({
                    ...proyecto,
                    idCliente: e.target.value,
                    cliente: clientes?.clientes.find((cliente) => cliente.id === e.target.value),
                  })
                }
              >
                <MenuItem value={-1} disabled>
                  Seleccione un cliente
                </MenuItem>
                {clientes?.clientes.map((cliente) => (
                  <MenuItem key={cliente.id} value={cliente.id}>
                    {cliente?.tipoCliente == "Particular" ? cliente?.nombre+" "+cliente?.apellido : cliente?.razonSocial}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
        )}
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="tipoMoneda">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Tipo de moneda
              {!readOnly && (
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              )}
            </SoftTypography>
          </InputLabel>
          {(readOnly || !large) ? (
            <SoftTypography variant="h6">
              {proyecto?.tipoMoneda
                ? `${proyecto?.tipoMoneda}`
                : "Sin especificar"}
            </SoftTypography>
          ) : (
            <Select
            fullWidth
            label="tipoMoneda"
            value={proyecto?.tipoMoneda || -1}
            onChange={(e) =>
              setProyecto({
                ...proyecto,
                tipoMoneda: e.target.value,
                cotizacion: cotizacion?.cotizaciones?.find((ctz)=> ctz?.nombre == e.target.value)?.venta || (e.target.value === "Pesos" ? 1 : ""),
              })
            }
            >
              <MenuItem value={-1} disabled>Seleccione una moneda</MenuItem>
              <MenuItem value={"Pesos"} >Pesos</MenuItem>
              {
                cotizacion?.cotizaciones?.map((ctz) => {
                    return (
                        <MenuItem key={ctz?.nombre} value={ctz?.nombre}>
                            {ctz?.nombre}
                        </MenuItem>
                    )
                }) 
              }
            </Select>
          )}
        </Grid>
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="cotizacion">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Cotización
              {!readOnly && (
                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                  &nbsp;*
                </SoftTypography>
              )}
            </SoftTypography>
          </InputLabel>
          {(readOnly || !large) ? (
            <SoftTypography variant="h6">
              {proyecto?.cotizacion
                ? `${proyecto?.cotizacion}`
                : "Sin especificar"}
            </SoftTypography>
          ) : (
            <SoftInputNumber
            name="cotizacion"
            label="cotizacion"
            value={proyecto?.cotizacion || ""}
            disabled={proyecto?.tipoMoneda == "Pesos"}
            onChange={(e)=>{
              setProyecto({
                ...proyecto,
                cotizacion: e.target.value
              })
            }}
              icon={{
                component: "currency_exchange",
                direction: "left",
              }}
            />
          )
          }
        </Grid>
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="idSucursal">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Deposito
            </SoftTypography>
          </InputLabel>
          {(readOnly || !large) ? (
            <SoftTypography variant="h6">{`${proyecto?.sucursal?.nombre} - ${proyecto?.sucursal?.sede?.nombre}`}</SoftTypography>
          ) : (
            <Select
              name="idSucursal"
              value={proyecto?.idSucursal || -1}
              onChange={(e) => {
                if(e.target.value != -2){
                  setProyecto({
                    ...proyecto,
                    idSucursal: e.target.value,
                    sucursal: sucursales?.sucursales.find((sucursal) => sucursal.id === e.target.value),
                  })
                }
              }}  
            >
              <MenuItem value={-1} disabled>
                Seleccione un deposito
              </MenuItem>
              {sucursales?.sucursales.map((sucursal) => (
                <MenuItem key={sucursal.id} value={sucursal.id}>
                  {sucursal.nombre + " - " + sucursal.sede.nombre}
                </MenuItem>
              ))}
              <MenuItem value={-2}>
                <SoftButton circular fullWidth color="sistemasGris" size="small" 
                onClick={() => setModalSucursal(true)}
                >
                  <Icon>add</Icon>&nbsp; Agregar deposito
                </SoftButton>
              </MenuItem>
            </Select>
          )}
        </Grid>
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="responsable">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Responsable
            </SoftTypography>
          </InputLabel>
          {readOnly ? (
            <SoftTypography variant="h6">
              {proyecto?.responsable
                ? `${proyecto?.responsable?.nombre} ${proyecto?.responsable?.apellido}`
                : "Sin asignar"}
            </SoftTypography>
          ) : (
            <Select
              fullWidth
              label="Responsable"
              value={proyecto?.idResponsable || -1}
              onChange={(e) =>
                setProyecto({
                  ...proyecto,
                  idResponsable: e.target.value,
                  responsable: usuarios?.usuarios.find((user) => user.id === e.target.value),
                })
              }
            >
              <MenuItem value={-1} disabled>
                Seleccione un responsable
              </MenuItem>
              {usuarios?.usuarios.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.nombre} {user.apellido}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="vendedor">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Vendedor
            </SoftTypography>
          </InputLabel>
          {readOnly ? (
            <SoftTypography variant="h6">
              {proyecto?.vendedor
                ? `${proyecto?.vendedor?.nombre} ${proyecto?.vendedor?.apellido}`
                : "Sin asignar"}
            </SoftTypography>
          ) : (
            <Select
              fullWidth
              label="Vendedor"
              value={proyecto?.idVendedor || -1}
              onChange={(e) =>
                setProyecto({
                  ...proyecto,
                  idVendedor: e.target.value,
                  vendedor: usuarios?.usuarios.find((user) => user.id === e.target.value),
                })
              }
            >
              <MenuItem value={-1} disabled>
                Seleccione un vendedor
              </MenuItem>
              {usuarios?.usuarios.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.nombre} {user.apellido}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="provincia">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Provincia
            </SoftTypography>
          </InputLabel>
          {readOnly ? (
            <SoftTypography variant="h6">
              {proyecto?.localidad?.provincia
                ? proyecto?.localidad?.provincia?.nombre
                : "Sin especificar"}
            </SoftTypography>
          ) : (
            <Select
              name="provincia"
              value={proyecto?.idProvincia || -1}
              onChange={(e) =>
                setProyecto({
                  ...proyecto,
                  idProvincia: e.target.value,
                  provincia: provincias?.provincias.find(
                    (provincia) => provincia.id === e.target.value
                  ),
                })
              }
            >
              <MenuItem value={-1} disabled>
                Seleccione uno
              </MenuItem>
              {provincias?.provincias?.map((provincia) => (
                <MenuItem key={provincia.id} value={provincia.id}>
                  {provincia.nombre}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="localidad">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Localidad
            </SoftTypography>
          </InputLabel>
          {readOnly ? (
            <SoftTypography variant="h6">
              {proyecto?.localidad ? proyecto?.localidad?.nombre : "Sin especificar"}
            </SoftTypography>
          ) : (
            <Select
              name="localidad"
              value={proyecto?.idLocalidad || -1}
              onChange={(e) =>
                setProyecto({
                  ...proyecto,
                  idLocalidad: e.target.value,
                  localidad: localidades?.localidades.find(
                    (localidad) => localidad.id === e.target.value
                  ),
                })
              }
            >
              <MenuItem value={-1} disabled>
                Seleccione uno
              </MenuItem>
              {localidades?.localidades?.map((localidad) => (
                <MenuItem key={localidad.id} value={localidad.id}>
                  {localidad.nombre}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
        <Grid item xs={12} sm={(!large && proyecto?.tipo == "Cliente") ? 12 : (!large) ? 6 : 4}>
          <InputLabel htmlFor="direccion">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Dirección
            </SoftTypography>
          </InputLabel>
          {readOnly ? (
            <SoftTypography variant="h6">{proyecto?.direccion || "Sin especificar"}</SoftTypography>
          ) : (
            <SoftInput
              label="direccion"
              placeholder="Ingrese la dirección donse se realizará el proyecto"
              type="text"
              name="direccion"
              value={proyecto?.direccion}
              onChange={(e) => setProyecto({ ...proyecto, direccion: e.target.value })}
            />
          )}
        </Grid>
      </Grid>

      <ModalAddDeposito
      open={modalSucursal}
      handleClose={()=>setModalSucursal(false)}
      refetch={refetchSucursales}
      />
    </SoftBox>
  );
}

ProyectoDetalles.propTypes = {
  proyecto: PropTypes.object,
  setProyecto: PropTypes.func,
  large: PropTypes.bool,
  readOnly: PropTypes.bool,
};
