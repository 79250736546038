// Components
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

// @mui material components
import { Card, CircularProgress, Collapse, Icon, Table, TableBody, TableContainer, TableRow, Tooltip,} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate} from "react-router-dom";
import ModalMensajeFinal from "../components/ModalMensajeFinal";
import SoftCircularProgress from "components/SoftCircularProgress";
import TableItem from "./TableItem";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import { KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material";
import SoftProgress from "components/SoftProgress";
import ModalPDFCertificado from "../components/ModalPDFCertificado";

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "nombre", desc: "Nombre", align: "left", noOrder: true },
  { name: "cantidad", desc: "Cantidad", align: "left", noOrder: true },
  { name: "moneda", desc: "Tipo de Moneda", align: "left", noOrder: true },
  { name: "precio", desc: "Precio Unitario", align: "left", noOrder: true },
  { name: "cotizacion", desc: "Cotización", align: "left", noOrder: true },
  { name: "subtotal", desc: "Subtotal", align: "left", noOrder: true },

  { name: "incTotal", desc: "Inc. Total", align: "left",noOrder: true},
  { name: "incParcial", desc: "Inc. Parcial", align: "left",noOrder: true},

  { name: "ant", desc: "Anterior", align: "center", noOrder: true, width: "8%" },
  { name: "act", desc: "Actual", align: "center", noOrder: true, width: "14%" },
  { name: "acm", desc: "Acumulado", align: "center", noOrder: true, width: "8%" },

  { name: "montoAvance", desc: "Monto", align: "center", noOrder: true },
];

function EditarCertificado() {
  const { state } = useLocation();
  const { idProyecto,idPresupuesto,certificadoActual,certificadoAnterior,numeroCertificado} = state || {};
  const [proyecto, setProyecto] = useState();
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const [avanceProyecto, setAvanceProyecto] = useState(0);
  const [modalPDF,setModalPDF]=useState(false);
  const [modalMensajeFinal,setModalMensajeFinal] = useState(false);
  const [textoFinal,setTextoFinal] = useState({
    titulo: "",
    descripcion: "",
  });
  const { handleSnackbar } = useContext(MessageManager);
  const navigate = useNavigate();
  const [openCard,setOpenCard] = useState(null);

//   const { loading: loadingCertificado, data: dataCertificado, refetch } = useQuery(
//     gql`
//       query getCertificadoLastByProyecto($idProyecto: ID!) {
//         certificadoLastByProyecto(idProyecto: $idProyecto) {
//           fecha
//           porcentajeTotal
//           numeroCertificado
//           fases {
//             id
//             nombre
//             descripcion
//             nombreUnidad
//             fechaInicioEstimado
//             fechaFinEstimado
//             cantidad
//             porcentajeGanancia
//             subtotal
//             porcentajeAvance
//             numero
//             etapa{
//               id
//               nombre
//             }
//             manoDeObra {
//               id
//               nombre
//               horas
//               tipoMoneda
//               precio
//               cotizacion
//               porcentajeAvance
//               porcentajeAcumulado
//               incParcial
//               montoAvance
//             }
//             materiales {
//               id
//               producto {
//                 id
//                 nombre
//               }
//               nombre
//               cantidad
//               tipoMoneda
//               precio
//               cotizacion
//               porcentajeAvance
//               porcentajeAcumulado
//               incParcial
//               montoAvance
//             }
//             concepto {
//               id
//               nombre
//               tipoMoneda
//               precio
//               cotizacion
//               porcentajeAvance
//               porcentajeAcumulado
//               incParcial
//               montoAvance
//             }
//             presupuesto {
//               id
//               nombre{
//                 id
//                 razonSocial
//                 nombre
//                 apellido
//               }
//               file
//               aprobado
//               motivoAprobacion
//               observaciones
//               nroPresupuesto
//               fechaPago
//               tipoMoneda
//               precio
//               cotizacion
//               medioPago
//               observacionPago
//               montoAnticipo
//               porcentajeAnticipo
//               porcentajeAvance
//               porcentajeAcumulado
//             }
//           }
//         }
//       }
//     `,
//     {
//       variables: {
//         idProyecto: idProyecto,
//       },
//       fetchPolicy: "no-cache",
//     }
//   );

  const  { data: ordenCompraData } = useQuery(
    gql`
      query getOrdenCompra($filter: JSON) {
        ordenesCompra(
          filter: $filter
        ) {
          id
          numeroFase
          estado
          detalle{
            nombre
            tipo
          }
        }
      }
    `,
    {
      variables: {
        filter: {
          idProyecto: idProyecto,
          estado: "Aprobado",
          tipo: "Concepto",
        },
      },
      fetchPolicy: "no-cache",
    }
  );
 
  const { data: presupuestoData } = useQuery(
    gql`
    query getPresupuesto($id: ID!) {
      presupuesto(id: $id) {
          __typename @skip(if: true)
          nombreContacto
          numero
          ordenCompra
          nroContrato
          fechaInicioEstimada
          fechaFinEstimada
          terminosPago
          importeDeposito
          notasExtra
          version
          fases {
            id
            nombre
            descripcion
            nombreUnidad
            fechaInicioEstimado
            fechaFinEstimado
            cantidad
            porcentajeGanancia
            subtotal
            numero
            etapa{
              id
              nombre
            }
            manoDeObra {
              id
              nombre
              horas
              tipoMoneda
              precio
              cotizacion
            }
            materiales {
              id
              producto {
                id
                nombre
              }
              nombre
              cantidad
              tipoMoneda
              precio
              cotizacion
            }
            concepto {
              id
              nombre
              tipoMoneda
              precio
              cotizacion
            }
            presupuesto {
              id
              nombre{
                id
                razonSocial
                nombre
                apellido
              }
              file
              aprobado
              motivoAprobacion
              observaciones
              nroPresupuesto
              fechaPago
              tipoMoneda
              precio
              cotizacion
              medioPago
              observacionPago
              montoAnticipo
              porcentajeAnticipo
            }
          }
        }
      }
    `,
    {
      variables: {
        id: idPresupuesto
      },
      fetchPolicy: "network-only",
    }
  );

  const [saveCertificadoAll, {loading: loadingCertificadoAll}] = useMutation(
    gql`
      mutation saveCertificadoAll($input: CertificadoAllInput) {
        saveCertificadoAll(input: $input) {
          id
        }
      }
    `
  );

  const formatoManoDeObra = (manoDeObra) => {
    let manoDeObraFormateada = [];
    manoDeObra?.forEach((item) => {
      manoDeObraFormateada.push({
        nombre: item?.nombre ? item?.nombre : null,
        horas: item?.horas ? parseFloat(item?.horas) : null,
        tipoMoneda: item?.tipoMoneda ? item?.tipoMoneda : null,
        precio: item?.precio ? item?.precio : null,
        cotizacion: item?.cotizacion ? parseFloat(item?.cotizacion) : 0,
        porcentajeAvance: item?.nuevoPorcentajeAvance > 0 ? parseFloat(item?.nuevoPorcentajeAvance) : 0,
        porcentajeAcumulado: item?.porcentajeAcumulado ? (parseFloat(item?.porcentajeAcumulado) + parseFloat(item?.nuevoPorcentajeAvance || 0)) : item?.nuevoPorcentajeAvance > 0 ? parseFloat(item?.nuevoPorcentajeAvance) : 0,
        incTotal: item?.incTotal ? parseFloat(item?.incTotal) : 0,
        incParcial: item?.incParcial ? parseFloat(item?.incParcial) : 0,
        montoAvance: item?.montoAvance ? parseFloat(item?.montoAvance) : 0
      });
    });

    return manoDeObraFormateada;
  };

  const formatoMateriales = (materiales) => {
    let materialesFormateados = [];
    materiales?.forEach((item) => {
      materialesFormateados.push({
        idProducto: item?.producto ? item?.producto?.id : null,
        nombre: item?.nombre ? item?.nombre : item?.producto?.nombre ? item?.producto?.nombre : null,
        cantidad: item?.cantidad ? item?.cantidad : null,
        tipoMoneda: item?.tipoMoneda ? item?.tipoMoneda : null,
        precio: item?.precio ? item?.precio : null,
        cotizacion: item?.cotizacion ? parseFloat(item?.cotizacion) : 0,
        porcentajeAvance: item?.nuevoPorcentajeAvance > 0 ? parseFloat(item?.nuevoPorcentajeAvance) : 0,
        porcentajeAcumulado: item?.porcentajeAcumulado ? (parseFloat(item?.porcentajeAcumulado) + parseFloat(item?.nuevoPorcentajeAvance || 0)) : item?.nuevoPorcentajeAvance > 0 ? parseFloat(item?.nuevoPorcentajeAvance) : 0,
        incParcial: item?.incParcial ? parseFloat(item?.incParcial) : 0,
        montoAvance: item?.montoAvance ? parseFloat(item?.montoAvance) : 0
      });
    });

    return materialesFormateados;
  };

  const formatoConcepto = (conceptos)=>{
    let conceptosFormateados = [];
    conceptos?.forEach((item) => {
      conceptosFormateados.push({
        nombre: item?.nombre ? item?.nombre : null,
        tipoMoneda: item?.tipoMoneda ? item?.tipoMoneda : null,
        precio: item?.precio ? item?.precio : null,
        cotizacion: item?.cotizacion ? parseFloat(item?.cotizacion) : 0,
        porcentajeAvance: item?.nuevoPorcentajeAvance > 0 ? parseFloat(item?.nuevoPorcentajeAvance) : 0,
        porcentajeAcumulado: item?.porcentajeAcumulado ? (parseFloat(item?.porcentajeAcumulado) + parseFloat(item?.nuevoPorcentajeAvance || 0)) : item?.nuevoPorcentajeAvance > 0 ? parseFloat(item?.nuevoPorcentajeAvance) : 0,
        incTotal: item?.incTotal ? parseFloat(item?.incTotal) : 0,
        incParcial: item?.incParcial ? parseFloat(item?.incParcial) : 0,
        montoAvance: item?.montoAvance ? parseFloat(item?.montoAvance) : 0
      })
    });
    return conceptosFormateados;
  };

  const formatoPresupuestoNuevo = (presupuestos)=>{
    let presupuestosFormateados = [];
    presupuestos?.forEach((item) => {
      presupuestosFormateados.push({
        nombre: item?.nombre?.razonSocial ? item?.nombre?.razonSocial : item?.nombre?.nombre ? item?.nombre?.nombre+" "+item?.nombre?.apellido : null,
        urlFile: item?.file ? item.file : null,
        aprobado: item?.aprobado ? item?.aprobado : false,
        motivoAprobacion: item?.motivoAprobacion ? item?.motivoAprobacion : null,
        observaciones: item?.observaciones ? item?.observaciones : null,
        idProveedor: item?.nombre ? item?.nombre?.id : null,
        nroPresupuesto: item?.nroPresupuesto ? item?.nroPresupuesto : null,
        fechaPago: item?.fechaPago ? item?.fechaPago : null,
        tipoMoneda: item?.tipoMoneda ?item?.tipoMoneda : null,
        precio: item?.precio ? parseFloat(item?.precio) : null,
        cotizacion: item?.cotizacion ? parseFloat(item?.cotizacion) : null,
        medioPago: item?.medioPago ? item?.medioPago : null,
        montoAnticipo: item?.montoAnticipo ? parseFloat(item?.montoAnticipo) : null,
        observacionPago: item?.observacionPago ? item?.observacionPago : null,
        porcentajeAnticipo: item?.porcentajeAnticipo ? parseFloat(item?.porcentajeAnticipo) : null,
        porcentajeAvance: item?.nuevoPorcentajeAvance ? parseFloat(item?.nuevoPorcentajeAvance) :  0,
        porcentajeAcumulado: item?.porcentajeAcumulado ? parseFloat(item?.porcentajeAcumulado + item?.nuevoPorcentajeAvance) : item?.nuevoPorcentajeAvance ? parseFloat(item?.nuevoPorcentajeAvance) : 0
      })
    });
    return presupuestosFormateados;
  };

  const generarOrdenPago = (arrayTerceros) => {
    let ordenesPago = [];

    arrayTerceros?.forEach((fase) => {
      const presupuestoAprobado = fase?.presupuesto?.find((presupuesto) => presupuesto?.aprobado === true);

      let montoOrden = 0;
      fase?.concepto?.forEach((concepto) => {
        if( concepto?.nuevoPorcentajeAvance > 0 ){
          const montoAvance = (concepto?.precio * concepto?.nuevoPorcentajeAvance) / 100;
          montoOrden += parseFloat(montoAvance);
        }
      });
     
      let dataFase = {
        idProyecto: idProyecto,
        idProveedor: presupuestoAprobado?.nombre?.id,
        precio: parseFloat(montoOrden),
        tipoMoneda: presupuestoAprobado?.tipoMoneda,
        cotizacion: presupuestoAprobado?.cotizacion,
        medioPago: presupuestoAprobado?.medioPago,
        fechaPago: presupuestoAprobado?.fechaPago,
        numeroFase: fase?.numero,
        estado: "Pendiente", 
      };

      if(dataFase?.precio > 0){
        ordenesPago.push(dataFase);
        // if(ordenesPago?.some((item)=> item.idProveedor === dataFase.idProveedor)){
        //   const index = ordenesPago.findIndex((item)=> item.idProveedor === dataFase.idProveedor);
        //   ordenesPago[index] = {
        //     ...dataFase,
        //     precio: parseFloat(ordenesPago[index].precio) + parseFloat(dataFase.precio)
        //   };
        // }else{
        //   ordenesPago.push(dataFase);
        // }
      }
    });

    return ordenesPago;
      
  }

  const handleSave = () => {
    let formatoFases = [];

    let ordenPagoArray = [];
    if(proyecto?.fases?.some((fase)=> fase?.nombreUnidad === null)){
      const proyectosTerceros = proyecto?.fases?.filter((fase)=> fase?.nombreUnidad === null);
      ordenPagoArray = generarOrdenPago(proyectosTerceros);
    }

    if (proyecto?.fases.length > 0) {
      proyecto?.fases.forEach((fase) => {
        formatoFases.push({
          nombre: fase?.nombre ?? null,
          descripcion: fase?.descripcion ?? null,
          nombreUnidad: fase?.nombreUnidad ?? null,
          fechaInicioEstimado: fase?.fechaInicioEstimado ?? null,
          fechaFinEstimado: fase?.fechaFinEstimado ?? null,
          cantidad: fase?.cantidad ? Number(fase?.cantidad) : null,
          porcentajeGanancia: fase?.porcentajeGanancia
            ? parseFloat(fase?.porcentajeGanancia)
            : null,
          subtotal: fase?.subtotal ?? null,
          porcentajeAvance: parseFloat(calcularPorcentajeFase(fase)),
          numero: fase?.numero ?? null,
          idEtapa: fase?.etapa ? fase?.etapa?.id : null,
          manoDeObra: fase?.manoDeObra?.length > 0 ? formatoManoDeObra(fase?.manoDeObra) : [],
          // materiales: fase?.materiales?.length > 0 ? formatoMateriales(fase?.materiales) : [],
          concepto: fase?.concepto?.length > 0 ? formatoConcepto(fase?.concepto) : [],
          presupuesto: fase?.presupuesto?.length > 0 ? formatoPresupuestoNuevo(fase?.presupuesto) : [],
        });
      });
    }

    saveCertificadoAll({
      variables: {
        input: {
          id: certificadoActual.id,
          idProyecto: idProyecto,
          fecha: dayjs().format("YYYY-MM-DD"),
          porcentajeTotal: 0,
          ProyectoFases: formatoFases,
          ordenesPago: ordenPagoArray,
        },
      },
    })
      .then(() => {
        setTextoFinal({
          titulo: "Certificado editado con éxito!",
          descripcion: "El Certificado fue editado correctamente, puedes ver el estado en la sección de 'Proyectos'.",
        });
        setModalMensajeFinal(true);
      })
      .catch((e) => {
        console.log(e);
        handleSnackbar("Error al editar el certificado", "error");
      });    
  };  

  useEffect(() => {
    if (certificadoAnterior && presupuestoData && ordenCompraData) {
      if(certificadoAnterior.length == 0){
        controlOC();
      }else{
        actualizarCertificado();
      }
    }
  }, [certificadoAnterior, presupuestoData,ordenCompraData]);

  const controlOC = ()=>{
    let arrayFasesFinal = [];
    let etapas = [];

    const totalProyecto = getTotal(presupuestoData?.presupuesto?.fases);

    presupuestoData?.presupuesto?.fases?.forEach((fase) => {
      let arrayManoObra=[];
      let arrayConcepto=[];
      
      if(!etapas?.some((item)=> item?.id === fase?.etapa?.id)){
        etapas.push(fase?.etapa);
      }

      let findFase = certificadoActual?.fases?.find((item)=> ((item?.numero == fase?.numero) && (item?.etapa?.nombre == fase?.etapa?.nombre))) || null;
      
      fase?.manoDeObra?.forEach((item) => {
          if(findFase?.manoDeObra?.some((det)=>det?.nombre === item?.nombre)){
            let infoManoObra = findFase?.manoDeObra?.find((manoObra)=>manoObra?.nombre == item?.nombre);
            const incTotal = incidenciaTotal(item,fase?.cantidad,totalProyecto); 
            
            let totalIncParcial =  parseFloat((incTotal * (infoManoObra?.porcentajeAvance)) / 100).toFixed(2);
            
            arrayManoObra.push({
              ...item,
              incTotal: parseFloat(incTotal || 0),
              incParcial: parseFloat(totalIncParcial || 0),
              nuevoPorcentajeAvance: infoManoObra?.porcentajeAvance || 0
            });
          }else{
            arrayManoObra.push(item);
          }   
      });

      fase?.concepto?.forEach((item) => {
        let infoConcepto = {
          ...item,
        };

        if(ordenCompraData?.ordenesCompra?.some((oc)=>oc?.numeroFase == fase?.numero)){
          let ordenesFiltradas = ordenCompraData?.ordenesCompra?.filter((oc)=>oc?.numeroFase == fase?.numero);
          ordenesFiltradas?.forEach((oc)=>{
            if(oc?.detalle?.some((det)=>det?.nombre === item?.nombre)){
              infoConcepto = {
                ...item,
                ordenCompra: true
              }
            }
          })
        }

        if(findFase?.concepto?.some((det)=>det?.nombre === item?.nombre)){
          let conceptoActual = findFase?.concepto?.find((concep)=>concep?.nombre == item?.nombre);
          
          const incTotal = incidenciaTotal(item,fase?.cantidad,totalProyecto);

          const totalIncParcial =  parseFloat((incTotal * (conceptoActual?.porcentajeAvance)) / 100).toFixed(2);

          arrayConcepto.push({
            ...infoConcepto,
            nuevoPorcentajeAvance: conceptoActual?.porcentajeAvance || 0,
            incParcial: totalIncParcial || 0,
            incTotal: incTotal
          });
        }else{
          arrayConcepto.push(infoConcepto);
        }
      });     

      if(arrayManoObra?.length > 0 || arrayConcepto?.length > 0){
        arrayFasesFinal.push({
          ...fase,
          manoDeObra: arrayManoObra,
          concepto: arrayConcepto
        });
      }
    });
    
    setProyecto({
      ...presupuestoData?.presupuesto,
      etapas: etapas,
      fases: arrayFasesFinal
    });
  }

  const actualizarCertificado = ()=>{
    let arrayFases = [];
    let etapas = [];

    presupuestoData?.presupuesto?.fases?.map((fase)=>{
      if(!etapas?.some((item)=> item?.id === fase?.etapa?.id)){
        etapas.push({
          ...fase?.etapa,
          numeroFases: [fase?.numero]
        });
      }else{
        const index = etapas?.findIndex((item)=> item?.id === fase?.etapa?.id);
        etapas[index].numeroFases = [...etapas[index].numeroFases, fase?.numero];
      }

      if(certificadoAnterior.fases?.some((item)=>item.numero == fase?.numero)){
          const index = certificadoAnterior.fases?.findIndex((item)=>item.numero == fase?.numero);
          if(certificadoAnterior.fases[index]?.porcentajeAvance > 0){
            arrayFases.push(certificadoAnterior.fases[index]);
          }else{
            arrayFases.push(fase);
          }
      }else{
          arrayFases.push(fase);
      }
    });
    
    const totalProyecto = getTotal(arrayFases);
    let arrayFasesFinal = [];

    arrayFases?.forEach((fase) => {
      let arrayManoObra=[];
      let arrayConcepto=[];

      let findFase = certificadoActual?.fases?.find((item)=> ((item?.numero == fase?.numero) && (item?.etapa?.nombre == fase?.etapa?.nombre))) || null;
      
      fase?.manoDeObra?.forEach((item) => {
        const incTotal = incidenciaTotal(item,fase?.cantidad,totalProyecto);

        if(findFase?.manoDeObra?.some((manoObra)=>manoObra?.nombre == item?.nombre)){
          let infoManoObra = findFase?.manoDeObra?.find((manoObra)=>manoObra?.nombre == item?.nombre);
    
          let totalIncParcial =  parseFloat((incTotal * (item?.porcentajeAcumulado + infoManoObra?.porcentajeAvance)) / 100).toFixed(2);

          arrayManoObra.push({
            ...item,
            incTotal: parseFloat(incTotal || 0),
            incParcial: parseFloat(totalIncParcial || 0),
            nuevoPorcentajeAvance: infoManoObra?.porcentajeAvance || 0
          });
        }else{
          let totalIncParcial =  parseFloat((incTotal * item?.porcentajeAcumulado) / 100).toFixed(2);
           
          arrayManoObra.push({
            ...item,
            incTotal: parseFloat(incTotal || 0),
            incParcial: parseFloat(totalIncParcial || 0),
          });
        }
      });  

      fase?.concepto?.forEach((item) => {
        const incTotal = incidenciaTotal(item,fase?.cantidad,totalProyecto);

        const totalIncParcial =  parseFloat((incTotal * item?.porcentajeAcumulado) / 100).toFixed(2);

        let infoConcepto = {
          ...item,
          incTotal: parseFloat(incTotal || 0),
          incParcial: parseFloat(totalIncParcial || 0),
        };

        if(ordenCompraData?.ordenesCompra?.some((oc)=>oc?.numeroFase == fase?.numero)){
          let ordenesFiltradas = ordenCompraData?.ordenesCompra?.filter((oc)=>oc?.numeroFase == fase?.numero);
          ordenesFiltradas?.forEach((oc)=>{
            if(oc?.detalle?.some((det)=>det?.nombre === item?.nombre)){
              infoConcepto = {
                ...item,
                ordenCompra: true
              }
            }
          })
        }

        if(findFase?.concepto?.some((concep)=>concep?.nombre == item?.nombre)){
            let conceptoActual = findFase?.concepto?.find((concep)=>concep?.nombre == item?.nombre);
            let incParcialConcepto = parseFloat((incTotal * (item?.porcentajeAcumulado + conceptoActual?.porcentajeAvance)) / 100).toFixed(2);
            arrayConcepto.push({
                ...infoConcepto,
                nuevoPorcentajeAvance: conceptoActual?.porcentajeAvance || 0,
                incTotal: parseFloat(incTotal || 0),
                incParcial: parseFloat(incParcialConcepto || 0),
            });
        }else{
            arrayConcepto.push(infoConcepto);
        }
      });     

      if(arrayManoObra.length > 0 || arrayConcepto.length > 0){
        arrayFasesFinal.push({
          ...fase,
          manoDeObra: arrayManoObra,
          concepto: arrayConcepto
        });
      }
    });

    setProyecto({
      ...presupuestoData?.presupuesto,
      etapas: etapas,
      fases: arrayFasesFinal
    });
  }

  const incidenciaTotal = (material,cantidad,totalProyecto)=>{
    const subTotal = material?.horas ? (material?.precio * material?.horas * cantidad) : material?.cantidad ? (material?.precio * material?.cantidad * cantidad) : (material?.precio || 0);
    const total =  parseFloat((subTotal * 100) / totalProyecto).toFixed(2);
    return parseFloat(total || 0);
  }

  const calcularPorcentajeFase = (fase) => {
    // const subtareas =  [...fase?.manoDeObra, ...fase?.materiales, ...fase?.concepto];
    // const totalSubtareas = [...fase?.manoDeObra, ...fase?.materiales, ...fase?.concepto].length;
    const subtareas =  [...fase?.manoDeObra, ...fase?.concepto];
    const totalSubtareas = [...fase?.manoDeObra, ...fase?.concepto].length;
    
    if (totalSubtareas === 0) {
        return 0;
    }
    
    const sumaPorcentajes = subtareas.reduce((acumulado, subtarea) => {
        return acumulado + (subtarea?.nuevoPorcentajeAvance || 0) + (subtarea?.porcentajeAcumulado || 0); 
    }, 0);
    
    const porcentajeCompletado = parseFloat(sumaPorcentajes / totalSubtareas).toFixed(2);
    
    return Math.min(100, Math.max(0, porcentajeCompletado));
  }   

  const renderColumns = (columns) => {
    return columns.map(({ name, desc, align, width }, key) => {
      let pl;
      let pr;

      if (key === 0) {
        pl = 3;
        pr = 3;
      } else if (key === columns.length - 1) {
        pl = 3;
        pr = 3;
      } else {
        pl = 1;
        pr = 1;
      }

      return (
        <SoftBox
          key={name}
          component="th"
          width={width || "auto"}
          pt={1.5}
          pb={1.25}
          pl={align === "left" ? pl : 3}
          pr={align === "right" ? pr : 3}
          textAlign={align}
          fontSize={size.xxs}
          fontWeight={fontWeightBold}
          color="secondary"
          opacity={0.7}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
        >
          {desc ? desc.toUpperCase() : name.toUpperCase()}
        </SoftBox>
      );
    });
  };

  const getTotal = (fasesPresupuesto) => {
    let total = 0;

    if (fasesPresupuesto?.length > 0) {
      fasesPresupuesto.forEach((fase) => {
        const cantidad = parseInt(fase?.cantidad) || 0;
        // const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;
        let content = [
          ...(fase?.manoDeObra || []),
          // ...(fase?.materiales || []),
          ...(fase?.concepto || []),
        ];
        let subtotal = 0;
        content?.forEach((item) => {
          if (item.horas) {
            subtotal += item.precio * item.horas * cantidad || 0;
          } else if (item.cantidad) {
            subtotal += item.precio * item.cantidad * cantidad || 0;
          } else {
            subtotal += item.precio || 0;
          }
        });
        // subtotal += subtotal * (porcentaje / 100) || 0;
        total += subtotal;
      });
    }

    return total;
  };

  const handleCompletarFase = (fase) => {
    const totalProyecto = getTotal(proyecto?.fases);
    
    const arrayManoObra = fase?.manoDeObra?.map((manoObra)=>{
      // const incTotal = incidenciaTotal(manoObra,fase?.cantidad,totalProyecto);
      // const totalIncParcial =  parseFloat((incTotal * 100) / 100).toFixed(2);

      let subTotal = manoObra?.horas ? (manoObra?.precio * manoObra?.horas * fase?.cantidad) : 0;
      let total =  parseFloat((subTotal * 100) / totalProyecto).toFixed(2);
      return {
        ...manoObra,
        nuevoPorcentajeAvance: parseFloat(100 - (manoObra?.porcentajeAcumulado || 0)),
        incTotal: parseFloat(total),
        incParcial: parseFloat(total),
        montoAvance: manoObra?.horas ? (manoObra?.precio * manoObra?.horas * fase?.cantidad) : 0,
      }
    });
    const arrayConcepto = fase?.concepto?.map((concepto)=>{
      let subtotal = concepto?.precio || 0;
      let total =  parseFloat((subtotal * 100) / totalProyecto).toFixed(2);
      if((concepto?.ordenCompra == true) || fase?.nombreUnidad ){
        return {
          ...concepto,
          nuevoPorcentajeAvance: parseFloat(100 - (concepto?.porcentajeAcumulado || 0)),
          incTotal: parseFloat(total),
          incParcial: parseFloat(total),
          montoAvance: concepto?.precio,
        }
      }else{
        return {
          ...concepto
        }
      }
    });

    const nuevaFase = proyecto?.fases?.map((item)=>{
      if(item?.id === fase?.id){
        return {
          ...item,
          manoDeObra: arrayManoObra,
          concepto: arrayConcepto
        }
      }else{
        return item
      }
    });

    setProyecto({...proyecto, fases: nuevaFase});
  };

  useEffect(()=>{
    if(proyecto){
      setAvanceProyecto(()=>calculoAvanceProyecto());
    }else{
      setAvanceProyecto(0);
    }
  },[proyecto]);

  const calculoAvanceProyecto = ()=>{
    let totalFases = 0;

    if (proyecto?.fases?.length > 0) {
      proyecto?.fases?.forEach((fase) => {
        const arrayFases = [
          ...fase?.manoDeObra || [],
          ...fase?.concepto || []
        ];

        let incParcialFase = 0;
        arrayFases?.forEach((item) => {
          incParcialFase += parseFloat(item?.incParcial) || 0;
        });        
        totalFases += incParcialFase || 0;
      });
    }
   
    totalFases = Math.min(100, Math.max(0, parseFloat(totalFases))) || 0;

    return Math.round(totalFases);
  }
   
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox p={3}>
                <SoftBox display="flex" justifyContent="space-between" mb={2}>
                    <SoftTypography variant="h6">
                        {`Certificado N° ${numeroCertificado}`}
                    </SoftTypography>
                </SoftBox>

                <Card sx={{ mb: 2 }} >
                  <SoftBox p={3}>
                    <SoftBox display="flex" alignItems="center" justifyContent="space-between">
                      <SoftBox>
                        <SoftBox display="flex" alignItems="center">
                          <SoftTypography variant="body2" fontWeight="medium" ml={1}>
                            Avance del Proyecto
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox sx={{ width: "80%" }} textAlign="center">
                        <SoftBox px={2} display="flex-row" justifyContent="center">
                          <SoftTypography variant="body2" fontWeight="medium" mb={1}>
                            {avanceProyecto}%
                          </SoftTypography>
                          <SoftBox
                            sx={{
                              width: "100%",
                            }}
                          >
                            <SoftProgress color="primary" variant="gradient" value={avanceProyecto} />
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
                  {
                    proyecto?.etapas?.map((itemEtapa, index) => {
                      return (
                        <>
                          <SoftBox ml={1} pt={2}>
                            <SoftTypography variant="h6" fontWeight="bold" >
                              {itemEtapa?.nombre}
                            </SoftTypography>
                          </SoftBox>
                          {
                            proyecto?.fases?.map((item, index) => (
                              ((item?.etapa?.id === itemEtapa?.id) ||
                               (itemEtapa?.numeroFases?.some((fase) => fase === item?.numero))) && (
                                <Card key={index} sx={{ mb: 2 }} >
                                  <SoftBox  display="flex" flexDirection="column" justifyContent="space-between">
                                    
                                    <SoftBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                                      <SoftBox display="flex" alignItems="center">
                                        <SoftBox
                                          display="flex"
                                          alignItems="center"
                                          sx={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <SoftCircularProgress
                                            color={"primary"}
                                            icon={null}
                                            value={calcularPorcentajeFase(item)}
                                          />
                                        </SoftBox>

                                        <SoftBox display="flex" flexDirection="column" lineHeight={1} >
                                          <SoftBox px={1} display="flex" alignItems="center">
                                            <SoftTypography variant="h6" fontWeight="bold" >
                                              {item?.nombre}
                                            </SoftTypography>
                                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" >
                                              &nbsp;
                                              {
                                                (!item?.nombreUnidad && item?.concepto?.every((elem)=> elem?.ordenCompra == true)) 
                                                ? "- Orden de compra aprobada" 
                                                : (!item?.nombreUnidad && item?.concepto?.every((elem)=> !elem?.ordenCompra)) 
                                                ? "- No hay orden de compra aprobada"
                                                : (!item?.nombreUnidad && item?.concepto?.some((elem)=> !elem?.ordenCompra))
                                                ? `- Algunos conceptos no tienen orden de compra` 
                                                : null
                                              }
                                            </SoftTypography>
                                          </SoftBox>

                                          <SoftTypography variant="caption" fontWeight="bold" px={1} >
                                            Proveedor:&nbsp;
                                              {
                                                ((item?.presupuesto?.length > 0) && (item?.presupuesto?.every((presupuesto) => presupuesto?.aprobado == false)))
                                                ? "Presupuesto pendiente de aprobación"
                                              : ((item?.presupuesto?.length > 0) && (item?.presupuesto?.some((presupuesto) => presupuesto?.aprobado == true)))
                                                ? item?.presupuesto?.find((presupuesto) => presupuesto?.aprobado == true)?.nombre?.razonSocial 
                                                ? item?.presupuesto?.find((presupuesto) => presupuesto?.aprobado == true)?.nombre?.razonSocial 
                                                : item?.presupuesto?.find((presupuesto) => presupuesto?.aprobado == true)?.nombre?.nombre
                                                : "-"
                                              }
                                          </SoftTypography>
                                          <SoftTypography variant="caption" fontWeight="bold" px={1}>
                                            Inicio estimado: {item?.fechaInicioEstimado ? dayjs(item?.fechaInicioEstimado).format("DD/MM/YYYY") : "-"}
                                          </SoftTypography>
                                          <SoftTypography variant="caption" fontWeight="bold" px={1}>
                                            Fin estimado: {item?.fechaFinEstimado ? dayjs(item?.fechaFinEstimado).format("DD/MM/YYYY") : "-"}
                                          </SoftTypography>                                    
                                        </SoftBox>
                                      </SoftBox>
                                      
                                      <SoftBox display="flex" alignItems="center" gap={1.5}>
                                        {
                                          (
                                            (calcularPorcentajeFase(item) < 100)
                                          ) && (
                                            <SoftBox>
                                              <Tooltip placement="top" title="Completar fase">
                                                <SoftButton 
                                                onClick={()=>handleCompletarFase(item)} 
                                                circular 
                                                iconOnly 
                                                color="success"
                                                disabled={((item?.presupuesto?.every((presupuesto) => presupuesto?.aprobado == false) 
                                                  && (item?.presupuesto?.length > 0))) 
                                                  || (!item?.nombreUnidad && item?.concepto?.every((elem)=> !elem?.ordenCompra)) }
                                                >
                                                  <Icon>check</Icon>
                                                </SoftButton>
                                              </Tooltip>
                                            </SoftBox>
                                          )
                                        }
                                        <SoftBox>
                                          <Tooltip placement="top" title="Ver detalle">
                                            <SoftButton 
                                            onClick={() => setOpenCard(openCard === item?.id ? null : item?.id)}
                                            sx={{cursor: "pointer"}}
                                            circular 
                                            iconOnly 
                                            color="primary"
                                            >
                                              <Icon >
                                                {openCard === item?.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                              </Icon>
                                            </SoftButton>
                                          </Tooltip>
                                        </SoftBox>
                                      </SoftBox>
                                    </SoftBox>

                                    <Collapse in={openCard == item?.id ? true : false} timeout="auto" >
                                      <TableContainer>
                                        <Table>
                                          <SoftBox component="thead">
                                            <TableRow>
                                              {renderColumns(columns)}
                                            </TableRow>
                                          </SoftBox>
                                          <TableBody>
                                          {
                                            item?.manoDeObra?.map((dataManoObra) => {
                                              return (
                                                <TableItem
                                                key={dataManoObra.id}
                                                material={dataManoObra}
                                                cantidad={item?.cantidad}
                                                handleUpdateMaterial={(modManoObra)=>{
                                                  let fases = proyecto?.fases.map((fase)=>{
                                                    if(fase.id === item.id){
                                                      const modifFase = fase?.manoDeObra.map((elem)=>{
                                                        if(elem.id === modManoObra.id){
                                                          return modManoObra
                                                        }else{
                                                          return elem
                                                        }
                                                      });
                                                      return {
                                                        ...fase,
                                                        manoDeObra: modifFase
                                                      }
                                                    }else{
                                                      return fase
                                                    }
                                                  });
                
                                                  setProyecto({
                                                    ...proyecto,
                                                    fases: fases
                                                  });
                                                }}
                                                totalProyecto={getTotal(proyecto?.fases)}
                                                />
                                              )
                                            })
                                          }
                                          {
                                            item?.concepto?.map((dataConcepto) => {
                                              return (
                                                <TableItem
                                                key={dataConcepto.id}
                                                material={dataConcepto}
                                                cantidad={item?.cantidad}
                                                handleUpdateMaterial={(modConcepto)=>{
                                                  let fases = proyecto?.fases.map((fase)=>{
                                                    if(fase.id === item.id){
                                                      const modifFase = fase?.concepto.map((elem)=>{
                                                        if(elem.id === modConcepto.id){
                                                          return modConcepto
                                                        }else{
                                                          return elem
                                                        }
                                                      });
                                                      return {
                                                        ...fase,
                                                        concepto: modifFase
                                                      }
                                                    }else{
                                                      return fase
                                                    }
                                                  });
                
                                                  setProyecto({
                                                    ...proyecto,
                                                    fases: fases
                                                  });
                                                }}
                                                totalProyecto={getTotal(proyecto?.fases)}
                                                disabledPresupuesto={((item?.presupuesto?.every((presupuesto) => presupuesto?.aprobado == false) && (item?.presupuesto?.length > 0))) || (!item?.nombreUnidad && !dataConcepto?.ordenCompra)}
                                                />
                                              )
                                            })
                                          }
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Collapse>  
                                  </SoftBox>
                                </Card>
                              )
                            ))
                          }
                        </>
                      )
                    })
                  }
                <SoftBox display="flex" justifyContent="end" mt={4}>               
                    <SoftBox
                    display={{
                        xs: "flex-row",
                        sm: "flex",
                    }}
                    justifyContent="end"
                    alignItems="center"
                    >
                    <SoftBox
                        mr={{
                        xs: 0,
                        sm: 2,
                        }}
                        mt={{
                        xs: 2,
                        sm: 0,
                        }}
                    >
                        <SoftButton
                        color="primary"
                        circular
                        onClick={() => navigate("/proyectos")}
                        fullWidth={{
                            xs: true,
                            sm: false,
                        }}
                        disabled={loadingCertificadoAll}
                        >
                        <Icon sx={{ fontWeight: "light" }}>
                            close
                        </Icon>
                        &nbsp;Cancelar
                        </SoftButton>
                    </SoftBox>
                    <SoftBox
                      mr={{
                        xs: 0,
                        sm: 2,
                        }}
                        mt={{
                        xs: 2,
                        sm: 0,
                        }}
                    >
                        <SoftButton
                        color="sistemasGris"
                        circular
                        fullWidth={{
                            xs: true,
                            sm: false,
                        }}
                        onClick={()=>setModalPDF(true)}
                        disabled={loadingCertificadoAll || !proyecto?.fases ||
                          proyecto?.fases?.every((fase) => {
                            return fase?.manoDeObra?.every((mano) => !mano?.nuevoPorcentajeAvance || mano?.nuevoPorcentajeAvance == 0 || mano?.nuevoPorcentajeAvance == "") &&
                              fase?.concepto?.every((concepto) => !concepto?.nuevoPorcentajeAvance || concepto?.nuevoPorcentajeAvance == 0 || concepto?.nuevoPorcentajeAvance == "")
                          })
                        }
                        >
                        Vista PDF&nbsp;<Icon sx={{ fontWeight: "light" }}>
                          visibility
                        </Icon>
                        </SoftButton>
                    </SoftBox>
                    <SoftBox
                        mt={{
                        xs: 2,
                        sm: 0,
                        }}
                    >
                        <SoftButton
                        color="sistemasGris"
                        circular
                        fullWidth={{
                            xs: true,
                            sm: false,
                        }}
                        onClick={handleSave}
                        disabled={loadingCertificadoAll || !proyecto?.fases ||
                          proyecto?.fases?.every((fase) => {
                            return fase?.manoDeObra?.every((mano) => !mano?.nuevoPorcentajeAvance || mano?.nuevoPorcentajeAvance == 0 || mano?.nuevoPorcentajeAvance == "") &&
                              fase?.concepto?.every((concepto) => !concepto?.nuevoPorcentajeAvance || concepto?.nuevoPorcentajeAvance == 0 || concepto?.nuevoPorcentajeAvance == "")
                          })
                        }
                        >
                        Guardar&nbsp;
                        {loadingCertificadoAll ? (
                            <CircularProgress size={15} color="inherit" />
                        ) : (
                            <Icon sx={{ fontWeight: "light" }}>
                            save
                            </Icon>
                        )}
                        </SoftButton>
                    </SoftBox>
                    </SoftBox>
                </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
        <ModalMensajeFinal
         open={modalMensajeFinal}
         handleClose={() =>{
           setModalMensajeFinal(false);
           setTextoFinal({
             titulo: "",
             descripcion: "",
           });
         }}
         titulo={textoFinal?.titulo}
         descripcion={textoFinal?.descripcion}
        />

        <ModalPDFCertificado
        open={modalPDF}
        handleClose={()=>{
          setModalPDF(false);
        }}
        proyecto={proyecto}
        guardar={()=>{
          handleSave();
          setModalPDF(false);
        }}
        idProyecto={idProyecto}
        />        
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditarCertificado;
