// React
import { gql, useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Components
import { Card, Collapse, Grid, Icon, Menu, MenuItem, Select, Tooltip } from "@mui/material";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import ModalProyecto from "./components/ModalProyecto";

// Data
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftInput from "components/SoftInput";
import DataTable from "components/Tables/DataTable";
import { MessageManager } from "context";
import { UserContext } from "context/user";
import dataProyectos from "./data/dataProyectos";
import { getUproColor } from "utils/colors";
import ProyectoItem from "./components/ProyectoItem";
import ModalCertificado from "./components/ModalCertificado";
import ModalPresupuestos from "./components/ModalPresupuestos";
import ModalDelete from "components/Modals/Delete";
import ModalReportes from "./components/ModalReportes";
import ModalPlantillas from "./plantillas/index";

function Proyectos() {
  const { handleSnackbar } = useContext(MessageManager);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalCertificado, setOpenModalCertificado] = useState(false);
  const [openModalPresupuesto, setOpenModalPresupuesto] = useState(false);
  const [selectedProyecto, setSelectedProyecto] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "id",
    orden: "DESC",
  });
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalRestaurar, setOpenModalRestaurar] = useState(false);
  const [openModalReportes, setOpenModalReportes] = useState(false);
  const [selectedModalType, setSelectedModalType] = useState(null);
  const [openFilters, setOpenFilters] = useState(false);
  const [filter, setFilter] = useState({ estado: ["Pendiente", "En_Proceso", "Finalizado"] });
  const [openPlantillas, setOpenPlantillas] = useState(false);

  const handleFilter = (event) => {
    const { name, value } = event.target;
    if (value == -1) {
      if (name == "tipo") {
        setFilter((prev) => {
          const updatedFilter = { ...prev };
          delete updatedFilter[name];
          return updatedFilter;
        });
      } else {
        setFilter((prev) => {
          const updatedFilter = { ...prev };
          delete updatedFilter[name];
          return { ...updatedFilter, [name]: ["Pendiente", "En_Proceso", "Finalizado"] };
        });
      }
    } else {
      setFilter((prev) => ({ ...prev, [name]: value == "Inactivo" ? "Cancelado" : value }));
    }
  };

  const handleOpenMenu = (event, item, type) => {
    setAnchorEl(event.currentTarget);
    setSelectedModalType(type);
    setOpenMenu(true);
    setSelectedProyecto(item);
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const { loading, error, data, refetch } = useQuery(
    gql`
      query getProyectos($search: String, $filter: JSON, $page: Int, $order: Order) {
        paginationInfo {
          pages
          total
        }
        proyectos(
          search: $search
          filter: $filter
          pagination: { page: $page, limit: 10 }
          order: $order
        ) {
          id
          nombre
          tipo
          tipoMoneda
          cliente {
            id
            nombre
            apellido
          }
          vendedor {
            id
          }
          responsable {
            id
          }
          localidad {
            id
            provincia {
              id
            }
          }
          direccion
          estado
          presupuestos {
            id
          }
          presupuestoAprobado {
            id
            numero
          }
          presupuestoAprobadoLast {
            id
            numero
            fases{
              id
              nombre
              nombreUnidad
              presupuesto{
                id
                aprobado
              }
            }
          }
          sucursal{
            id 
            nombre
            sede{
              id
              nombre
            }
          }
          certificado{
            id
          }
        }
      }
    `,
    {
      variables: {
        search: search || null,
        filter: filter,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
      },
      fetchPolicy: "no-cache",
    }
  );

  const [saveProyecto] = useMutation(gql`
    mutation saveProyecto($input: ProyectoInput!) {
      saveProyecto(input: $input) {
        id
      }
    }
  `);

  const deleteProyecto = () => {
    saveProyecto({
      variables: {
        input: {
          id: selectedProyecto.id,
          estado: "Cancelado",
        },
      },
    })
      .then(() => {
        handleSnackbar("Proyecto eliminado correctamente", "success");
        refetch();
      })
      .catch((e) => {
        handleSnackbar("Error al eliminar el proyecto", "error");
      });
  };

  const restaurarProyecto = () => {
    saveProyecto({
      variables: {
        input: {
          id: selectedProyecto.id,
          estado: "Pendiente",
        },
      },
    })
      .then(() => {
        handleSnackbar("Proyecto restaurado correctamente", "success");
        refetch();
      })
      .catch((e) => {
        handleSnackbar("Error al restaurado el proyecto", "error");
      });
  };

  useEffect(() => {
    if (data) {
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <SoftTypography variant="h6">Proyectos</SoftTypography>
                    <SoftBox
                      display={{
                        xs: "block",
                        sm: "none",
                      }}
                    >
                      <Tooltip title="Agregar nuevo proyecto" placement="top">
                        <Link to="/proyectos/nuevo">
                          <SoftButton color="primary" circular iconOnly>
                            <Icon>add</Icon>
                          </SoftButton>
                        </Link>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftBox
                      mr={{
                        xs: 0,
                        sm: 2,
                      }}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "auto",
                        },
                      }}
                    >
                      <SoftInput
                        placeholder="Escribe aquí..."
                        icon={{ component: "search", direction: "left" }}
                        onChange={handleSearch}
                      />
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                      mr={2}
                    >
                      <Tooltip title="Administrar plantillas" placement="top">
                        <SoftButton color="sistemasGris" circular onClick={() => setOpenPlantillas(true)}>
                          <Icon>format_list_bulleted</Icon>
                          &nbsp;Plantillas
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                      mr={2}
                    >
                      <Tooltip title="Agregar nuevo proyecto" placement="top">
                        <Link to="/proyectos/nuevo">
                          <SoftButton color="primary" circular>
                            <Icon>add</Icon>
                            &nbsp;Agregar
                          </SoftButton>
                        </Link>
                      </Tooltip>
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                    >
                      <Tooltip
                        title={openFilters ? "Cerrar filtros" : "Ver filtros"}
                        placement="top"
                      >
                        <SoftButton
                          color="sistemasGris"
                          onClick={() => setOpenFilters(!openFilters)}
                          circular
                          iconOnly
                        >
                          <Icon sx={{ fontWeight: "bold" }}>
                            {openFilters ? "filter_alt_off" : "filter_alt"}
                          </Icon>
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <Collapse in={openFilters} timeout="auto" unmountOnExit>
              <SoftBox
                display="flex"
                flexDirection={{
                  xs: "column",
                  sm: "row",
                }}
                justifyContent="end"
                alignItems="center"
                px={3}
                pb={3}
                gap={2}
              >
                <Select
                  name="tipo"
                  value={filter?.["tipo"] || -1}
                  sx={{
                    paddingRight: "35px !important",
                  }}
                  onChange={handleFilter}
                >
                  <MenuItem value={-1}>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SoftBox>
                        <SoftTypography variant="p">Todos</SoftTypography>
                      </SoftBox>
                      <SoftBox>
                        <Icon
                          fontSize="small"
                          sx={{
                            verticalAlign: "middle",
                            color: getUproColor("sistemasGris"),
                          }}
                        >
                          dataset
                        </Icon>
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                  <MenuItem value="Cliente">
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SoftBox>
                        <SoftTypography variant="p">Presupuestos a clientes</SoftTypography>
                      </SoftBox>
                      <SoftBox>
                        <Icon
                          fontSize="small"
                          sx={{
                            verticalAlign: "middle",
                            color: getUproColor("sistemasAmarillo"),
                          }}
                        >
                          person
                        </Icon>
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                  <MenuItem
                    value="Propio"
                    sx={{
                      minWidth: { xs: "70vw", sm: "auto" },
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SoftBox>
                        <SoftTypography variant="p">Presupuestos propios</SoftTypography>
                      </SoftBox>
                      <SoftBox>
                        <Icon
                          fontSize="small"
                          sx={{
                            verticalAlign: "middle",
                            color: getUproColor("sistemasAmarillo"),
                          }}
                        >
                          handyman
                        </Icon>
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                </Select>

                <Select
                  name="estado"
                  value={filter?.["estado"] == "Cancelado" ? "Inactivo" : -1}
                  sx={{
                    paddingRight: "35px !important",
                  }}
                  onChange={handleFilter}
                >
                  <MenuItem value={-1}>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SoftBox>
                        <SoftTypography variant="p">Activos</SoftTypography>
                      </SoftBox>
                      <SoftBox>
                        <Icon
                          fontSize="small"
                          color="success"
                          sx={{
                            verticalAlign: "middle",
                          }}
                        >
                          arrow_upward
                        </Icon>
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                  <MenuItem
                    value="Inactivo"
                    sx={{
                      minWidth: { xs: "70vw", sm: "auto" },
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SoftBox>
                        <SoftTypography variant="p">Inactivos</SoftTypography>
                      </SoftBox>
                      <SoftBox>
                        <Icon
                          fontSize="small"
                          color="error"
                          sx={{
                            verticalAlign: "middle",
                          }}
                        >
                          arrow_downward
                        </Icon>
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                </Select>
              </SoftBox>
            </Collapse>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {loading ? (
                <Loading />
              ) : data?.proyectos.length > 0 ? (
                <Grid container spacing={2} p={3}>
                  {data?.proyectos.map((item) => (
                    <Grid item xs={12} key={item.id}>
                      <ProyectoItem
                        proyecto={item}
                        handleEdit={(type) => {
                          setSelectedModalType(type);
                          setOpenModalAdd(true);
                          setSelectedProyecto({
                            ...item,
                            idSucursal: item?.sucursal ? item?.sucursal.id : null,
                            sucursal: item?.sucursal ? item?.sucursal : null,
                          });
                        }}
                        handleCertificar={() => {
                          setSelectedProyecto(item);
                          setOpenModalCertificado(true);
                        }}
                        handlePresupuesto={() => {
                          setSelectedProyecto(item);
                          setOpenModalPresupuesto(true);
                        }}
                        handleDelete={() => {
                          setSelectedProyecto(item);
                          setOpenModalDelete(true);
                        }}
                        handleRestaurar={() => {
                          setSelectedProyecto(item);
                          setOpenModalRestaurar(true);
                        }}
                        handleReportes={() => {
                          setSelectedProyecto(item);
                          setOpenModalReportes(true);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            <CustomPagination
              loading={loading}
              length={data?.proyectos?.length}
              total={pagesInfo?.total}
              pages={pagesInfo?.pages}
              page={page}
              setPage={setPage}
              noCard
            />
          </Card>
        </SoftBox>
        <ModalProyecto
          open={openModalAdd}
          handleClose={() => {
            setOpenModalAdd(false);
            setSelectedProyecto();
          }}
          refetch={refetch}
          selectedProyecto={selectedProyecto}
          tipo={selectedModalType}
        />
        <ModalCertificado
          open={openModalCertificado}
          handleClose={() => {
            setOpenModalCertificado(false);
            setSelectedProyecto();
          }}
          refetch={refetch}
          selectedProyecto={selectedProyecto}
        />
        <ModalPresupuestos
          open={openModalPresupuesto}
          handleClose={() => {
            setSelectedProyecto();
            setOpenModalPresupuesto(false);
          }}
          refetch={refetch}
          selectedProyecto={selectedProyecto}
        />
        <ModalDelete
          open={openModalDelete || openModalRestaurar}
          handleClose={() => {
            setSelectedProyecto();
            if (openModalRestaurar) {
              setOpenModalRestaurar(false);
            } else {
              setOpenModalDelete(false);
            }
          }}
          nombre={`Proyecto Nº ${selectedProyecto?.id}`}
          functionDelete={() => {
            if (openModalRestaurar) {
              restaurarProyecto();
            } else {
              deleteProyecto();
            }
          }}
          accion={openModalDelete ? "descartar" : "restaurar"}
          texto={
            openModalDelete
              ? "Presione descartar para confirmar"
              : "Presione restaurar para confirmar"
          }
          titulo={openModalDelete ? "Descartar Proyecto" : "Restaurar Proyecto"}
        />
        <ModalPlantillas
          open={openPlantillas}
          handleClose={() => {
            setOpenPlantillas(false);
          }}
        />
        <ModalReportes
          open={openModalReportes}
          handleClose={() => {
            setSelectedProyecto();
            setOpenModalReportes(false);
          }}
          selectedProyecto={selectedProyecto}
        />
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Proyectos;
