const MEDIDAS = [
    {"nombre": "Centímetro (cm)", "abreviatura": "cm"},
    {"nombre": "Centímetro cuadrado (cm²)", "abreviatura": "cm²"},
    {"nombre": "Centímetro cúbico (cm³)", "abreviatura": "cm³"},
    {"nombre": "Hectárea (ha)", "abreviatura": "ha"},
    {"nombre": "Kilogramo (kg)", "abreviatura": "kg"},
    {"nombre": "Litro (L)", "abreviatura": "L"},
    {"nombre": "Metro (m)", "abreviatura": "m"},
    {"nombre": "Metro cuadrado (m²)", "abreviatura": "m²"},
    {"nombre": "Metro cúbico (m³)", "abreviatura": "m³"},
    {"nombre": "Milímetro (mm)", "abreviatura": "mm"},
    {"nombre": "Pulgada (in)", "abreviatura": "in"},
    {"nombre": "Pulgada cuadrada (in²)", "abreviatura": "in²"},
    {"nombre": "Tonelada (t)", "abreviatura": "t"},
    {"nombre": "Unidad (ud.)", "abreviatura": "ud."},
    {"nombre": "Global (gl)", "abreviatura": "gl"}
];

export default MEDIDAS;