/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";
import { makeNiceText } from "utils/formatters";
import 'dayjs/locale/es';


const getRows = (data) => {
  dayjs.locale("es");
  const rows = [];
  if (data) {
    data.forEach((item) => {
        const periodo = dayjs(item?.periodo).format("MMMM YYYY");
        rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: getUproColor("sistemasAmarillo"),
                }}
                fontSize="lg"
              >
                show_chart
              </Icon>
            }
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={"Índice"} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: getUproColor("sistemasAmarillo"),
                  
                  }}
                  fontSize="lg"
                >
                  show_chart
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.id}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.id}
            </SoftTypography>
          </SoftBox>
        ),
        periodo: (
          <SoftBox
            data={
              item?.periodo
                ? makeNiceText(periodo)
                : ""
            }
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {item?.periodo
                ? makeNiceText(periodo)
                : ""}
            </SoftTypography>
          </SoftBox>
        ),
        indice: (
          <SoftBox
            data={item?.general ? item?.general : "Sin información"}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {item?.general ? item?.general : "Sin información"}
            </SoftTypography>
          </SoftBox>
        ),
        porcentajeAumento: (
          <SoftBox
            data={item?.porcentajeAumento ? item?.porcentajeAumento + "%" : "Sin información"}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {item?.porcentajeAumento ? item?.porcentajeAumento + "%" : "Sin información"}
            </SoftTypography>
          </SoftBox>
        ),
        createdAt: (
          <SoftBox
            data={item?.createdAt}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {item?.createdAt ? dayjs(item?.createdAt).format("DD/MM/YYYY HH:mm") : ""}
            </SoftTypography>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", width: "1%", align: "left", noOrder: true  },
  { name: "periodo", desc: "Periodo", align: "left", noOrder: true  },
  { name: "indice", desc: "Índice", align: "left", noOrder: true  },
  { name: "porcentajeAumento", desc: "Variación", align: "left", noOrder: true  },
  { name: "createdAt", desc: "Fecha actualización", align: "left", noOrder: true  },
];

export default { columns, getRows };
