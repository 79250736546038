// React
import PropTypes from "prop-types";

// @mui material components
import { Card, CircularProgress, Fade, Grid, Icon, Modal, Tooltip } from "@mui/material";

import { gql, useLazyQuery } from "@apollo/client";
import DefaultDoughnutChart from "components/Charts/DoughnutCharts/DefaultDoughnutChart";
import DefaultLineChart from "components/Charts/LineCharts/DefaultLineChart";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftProgress from "components/SoftHorarios";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import SoftCircularProgress from "components/SoftCircularProgress";
import { formatMoneyPunto } from "utils/formatters";
import SinDatos from "components/Carteles/SinDatos";
import ModalVerCertificado from "./ModalVerCertificado";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "70%" },
  overflow: "hidden",
};

export default function ModalReportes({ open, handleClose, selectedProyecto }) {
  const [getPresupuestos, { data: presupuestos, loading, refetch: refetchPresupuestos }] =
    useLazyQuery(
      gql`
        query getPresupuestos($idProyecto: ID) {
          presupuestos(filter: { idProyecto: $idProyecto }, currentVersions: false) {
            id
            numero
            version
            createdAt
            fechaInicioEstimada
            fechaFinEstimada
            fases {
              id
              nombre
              cantidad
              porcentajeGanancia
              manoDeObra {
                id
                horas
                precio
              }
              materiales {
                id
                precio
                cantidad
              }
              concepto {
                id
                precio
              }
            }
          }
        }
      `,
      {
        fetchPolicy: "network-only",
        cachePolicy: "no-cache",
      }
    );

  const [getCertificados, { data: certificados, loading: loadingCertificados }] = useLazyQuery(
    gql`
      query getCertificados($idProyecto: ID, $order: Order) {
        certificados(filter: { idProyecto: $idProyecto }, order: $order) {
          id
          fecha
          porcentajeTotal
          fases {
            id
            nombre
            descripcion
            nombreUnidad
            fechaInicioEstimado
            fechaFinEstimado
            cantidad
            porcentajeGanancia
            subtotal
            porcentajeAvance
            numero
            etapa{
              id
              nombre
            }
            manoDeObra {
              id
              nombre
              horas
              tipoMoneda
              precio
              cotizacion
              porcentajeAvance
              porcentajeAcumulado
              incParcial
              montoAvance
            }
            materiales {
              id
              producto {
                id
                nombre
              }
              nombre
              cantidad
              tipoMoneda
              precio
              cotizacion
              porcentajeAvance
              porcentajeAcumulado
              incParcial
              montoAvance
            }
            concepto {
              id
              nombre
              tipoMoneda
              precio
              cotizacion
              porcentajeAvance
              porcentajeAcumulado
              incParcial
              montoAvance
            }
            presupuesto {
              id
              nombre{
                id
                razonSocial
                nombre
                apellido
              }
              file
              aprobado
              motivoAprobacion
              observaciones
              nroPresupuesto
              fechaPago
              tipoMoneda
              precio
              cotizacion
              medioPago
              observacionPago
              montoAnticipo
              porcentajeAnticipo
              porcentajeAvance
              porcentajeAcumulado
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      cachePolicy: "no-cache",
    }
  );
  const [modalVerCertificado,setModalVerCertificado] = useState(false);
  const [certificadoSelect,setCertificadoSelect]=useState(null);
  const [presupuestoInicial, setPresupuestoInicial] = useState(null);
  const [presupuestoActual, setPresupuestoActual] = useState(null);
  const [presupuestosModificados, setPresupuestosModificados] = useState([]);

  useEffect(() => {
    if (selectedProyecto && open) {
      getPresupuestos({ variables: { idProyecto: selectedProyecto.id } });
      getCertificados({ variables: { idProyecto: selectedProyecto.id , order: { field: "id", order: "DESC"}} });
    }
  }, [selectedProyecto, open]);

  useEffect(() => {
    if (presupuestos?.presupuestos && open) {
      setPresupuestoActual(presupuestos.presupuestos[presupuestos.presupuestos.length - 1]);
      const presupuesto = presupuestos.presupuestos.find(
        (presupuesto) => presupuesto.id === selectedProyecto.presupuestoAprobado.id
      );
      setPresupuestoInicial(presupuesto);
      setPresupuestosModificados(
        presupuestos.presupuestos.filter((item) => item?.version >= presupuesto?.version)
      );
    }
  }, [presupuestos]);

  const getTotal = (fasesPresupuesto) => {
    let total = 0;

    if (fasesPresupuesto?.length > 0) {
      fasesPresupuesto.forEach((fase) => {
        const cantidad = parseInt(fase?.cantidad) || 0;
        const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;
        // let content = fase?.manoDeObra?.concat(fase?.materiales) || [];
        let content = [
          ...(fase?.manoDeObra || []),
          ...(fase?.materiales || []),
          ...(fase?.concepto || []),
        ];
        let subtotal = 0;
        content?.forEach((item) => {
          if (item.horas) {
            subtotal += item.precio * item.horas * cantidad || 0;
          } else if (item.cantidad) {
            subtotal += item.precio * item.cantidad * cantidad || 0;
          } else {
            subtotal += item.precio || 0;
          }
        });
        subtotal += subtotal * (porcentaje / 100) || 0;
        total += subtotal;
      });
    }

    return total;
  };

  const getSubtotal = (fasesPresupuesto) => {
    let total = 0;

    if (fasesPresupuesto?.length > 0) {
      fasesPresupuesto.forEach((fase) => {
        const cantidad = parseInt(fase?.cantidad) || 0;
        let content = [
          ...(fase?.manoDeObra || []),
          ...(fase?.materiales || []),
          ...(fase?.concepto || []),
        ];
        let subtotal = 0;
        content?.forEach((item) => {
          if (item.horas) {
            subtotal += item.precio * item.horas * cantidad || 0;
          } else if (item.cantidad) {
            subtotal += item.precio * item.cantidad * cantidad || 0;
          } else {
            subtotal += item.precio || 0;
          }
        });
        total += subtotal;
      });
    }

    return total;
  };

  const calculoAvanceProyecto = (proyecto)=>{
    let totalFases = 0;

    if (proyecto?.fases?.length > 0) {
      proyecto?.fases?.forEach((fase) => {
        const arrayFases = [
          ...fase?.manoDeObra || [],
          // ...fase?.materiales || [],
          ...fase?.concepto || []
        ];

        let incParcialFase = 0;
        arrayFases?.forEach((item) => {
          incParcialFase += parseFloat(item?.incParcial) || 0;
        });        
        totalFases += incParcialFase || 0;
      });
    }
   
    totalFases = Math.min(100, Math.max(0, parseFloat(totalFases))) || 0;

    return Math.round(totalFases);
  }
     
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      disableEnforceFocus
    >
      <Fade in={open}>
          <Card sx={style}>
            <SoftBox p={3}  height="100%" sx={{overflowY:"auto"}} >
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <SoftTypography variant="h6">Reportes</SoftTypography>
                </SoftBox>
                <SoftBox display="flex" justifyContent="flex-end">
                  <SoftBox display="flex" alignItems="center">
                    <Tooltip title="Cerrar" placement="top">
                      <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                        close
                      </Icon>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
              </SoftBox>

              <SoftBox py={3}  >
                <Grid container spacing={2} sx={{overflow:"visible"}}>
                  <Grid item xs={12}>
                    <Card>
                      <SoftBox p={3}>
                        <SoftBox display="flex" alignItems="center" justifyContent="space-between">
                          <SoftBox>
                            <SoftBox display="flex" alignItems="center">
                              <Icon fontSize="small" color="primary">
                                schedule
                              </Icon>
                              <SoftTypography variant="body2" fontWeight="medium" ml={1}>
                                Inicio
                              </SoftTypography>
                            </SoftBox>
                            <SoftTypography variant="body2" fontWeight="medium">
                              {presupuestoInicial?.fechaInicioEstimada
                                ? dayjs(presupuestoInicial?.fechaInicioEstimada).format("DD-MM-YYYY")
                                : "Sin especificar"}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox sx={{ width: "80%" }} textAlign="center">
                            <SoftBox px={2} display="flex-row" justifyContent="center">
                              <SoftTypography variant="body2" fontWeight="medium" mb={1}>
                                40%
                              </SoftTypography>
                              <SoftBox
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <SoftProgress color="primary" variant="gradient" value={40} />
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                          <SoftBox>
                            <SoftBox display="flex" alignItems="center" justifyContent="flex-end">
                              <SoftTypography variant="body2" fontWeight="medium" mr={1}>
                                Fin
                              </SoftTypography>
                              <Icon fontSize="small" color="primary">
                                schedule
                              </Icon>
                            </SoftBox>
                            <SoftTypography variant="body2" fontWeight="medium">
                              {presupuestoInicial?.fechaFinEstimada
                                ? dayjs(presupuestoInicial?.fechaFinEstimada).format("DD-MM-YYYY")
                                : "Sin especificar"}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </Grid>
                  <Grid item xs={6} sx={{overflow:"auto",height: "max-content"}}>
                    <DefaultLineChart
                      title="Variación del total del presupuesto"
                        chart={{
                          labels: presupuestosModificados.map(
                            (presupuesto) => `v${presupuesto.version}`
                          ),
                          datasets: [
                            {
                              label: `Presupuesto Actual`,
                              color: "#F1AF29",
                              data: presupuestosModificados.map((presupuesto) =>
                                getTotal(presupuesto.fases)
                              ),
                            },
                            {
                              label: `Presupuesto Inicial`,
                              color: "#535049",
                              data: presupuestosModificados.map(() =>
                                getTotal(presupuestoInicial?.fases)
                              ),
                            },
                          ],
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{overflow:"auto",height: "max-content"}}>
                    <DefaultDoughnutChart
                        title="Fases del proyecto"
                        chart={{
                          labels: ["En Curso", "Finalizado", "Pendiente"],
                          datasets: {
                            label: "Projects",
                            backgroundColors: ["info", "success", "error"],
                            data: [20, 15, 65],
                          },
                        }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <SoftBox p={3}>
                        <SoftBox display="flex" justifyContent="space-between">
                          <SoftBox>
                            <SoftTypography variant="h6">Comparativa de presupuestos</SoftTypography>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox display="flex" justifyContent="space-between" mt={2}>
                          <SoftBox>
                            <SoftTypography variant="h6" fontWeight="bold">
                              {`Versión inicial (v${presupuestoInicial?.version})`}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox>
                            <SoftTypography variant="h6" fontWeight="bold">
                              {`Versión actual (v${presupuestoActual?.version})`}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox display="flex" justifyContent="space-between" mt={1}>
                          <SoftBox display="flex" alignItems="center">
                            <Icon fontSize="small" color="primary">
                              schedule
                            </Icon>
                            <SoftTypography variant="button" fontWeight="medium" ml={1}>
                              Fases: {presupuestoInicial?.fases?.length}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox display="flex" alignItems="center">
                            <SoftTypography variant="button" fontWeight="medium" mr={1}>
                              Fases: {presupuestoActual?.fases?.length}
                            </SoftTypography>
                            <Icon fontSize="small" color="primary">
                              schedule
                            </Icon>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox display="flex" justifyContent="space-between" mt={1}>
                          <SoftBox display="flex" alignItems="center">
                            <Icon fontSize="small" color="primary">
                              attach_money
                            </Icon>
                            <SoftTypography variant="button" fontWeight="medium" ml={1}>
                              Total: ${formatMoneyPunto(getTotal(presupuestoInicial?.fases))}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox display="flex" alignItems="center">
                            <SoftTypography variant="button" fontWeight="medium" mr={1}>
                              Total: ${formatMoneyPunto(getTotal(presupuestoActual?.fases))}
                            </SoftTypography>
                            <Icon fontSize="small" color="primary">
                              attach_money
                            </Icon>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox display="flex" justifyContent="space-between" mt={1}>
                          <SoftBox display="flex" alignItems="center">
                            <Icon fontSize="small" color="primary">
                              paid
                            </Icon>
                            <SoftTypography variant="button" fontWeight="medium" ml={1}>
                              Ganancia: $
                              {formatMoneyPunto(
                                getTotal(presupuestoInicial?.fases) -
                                  getSubtotal(presupuestoInicial?.fases)
                              )}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox display="flex" alignItems="center">
                            <SoftTypography variant="button" fontWeight="medium" mr={1}>
                              Ganancia: $
                              {formatMoneyPunto(
                                getTotal(presupuestoActual?.fases) -
                                  getSubtotal(presupuestoActual?.fases)
                              )}
                            </SoftTypography>
                            <Icon fontSize="small" color="primary">
                              paid
                            </Icon>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox display="flex" justifyContent="space-between" mt={1}>
                          <SoftBox display="flex" alignItems="center">
                            <Icon fontSize="small" color="primary">
                              calendar_month
                            </Icon>
                            <SoftTypography variant="button" fontWeight="medium" ml={1}>
                              Inicio estimado:{" "}
                              {presupuestoInicial?.fechaInicioEstimada
                                ? dayjs(presupuestoInicial?.fechaInicioEstimada).format("DD-MM-YYYY")
                                : "Sin especificar"}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox display="flex" alignItems="center">
                            <SoftTypography variant="button" fontWeight="medium" mr={1}>
                              Inicio estimado:{" "}
                              {presupuestoActual?.fechaInicioEstimada
                                ? dayjs(presupuestoActual?.fechaInicioEstimada).format("DD-MM-YYYY")
                                : "Sin especificar"}
                            </SoftTypography>
                            <Icon fontSize="small" color="primary">
                              calendar_month
                            </Icon>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox display="flex" justifyContent="space-between" mt={1}>
                          <SoftBox display="flex" alignItems="center">
                            <Icon fontSize="small" color="primary">
                              calendar_today
                            </Icon>
                            <SoftTypography variant="button" fontWeight="medium" ml={1}>
                              Fin estimado:{" "}
                              {presupuestoInicial?.fechaFinEstimada
                                ? dayjs(presupuestoInicial?.fechaFinEstimada).format("DD-MM-YYYY")
                                : "Sin especificar"}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox display="flex" alignItems="center">
                            <SoftTypography variant="button" fontWeight="medium" mr={1}>
                              Fin estimado:{" "}
                              {presupuestoActual?.fechaFinEstimada
                                ? dayjs(presupuestoActual?.fechaFinEstimada).format("DD-MM-YYYY")
                                : "Sin especificar"}
                            </SoftTypography>
                            <Icon fontSize="small" color="primary">
                              calendar_today
                            </Icon>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <SoftBox p={3}>
                        <SoftBox display="flex" justifyContent="space-between">
                          <SoftBox>
                            <SoftTypography variant="h6">Certificados generados</SoftTypography>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox mt={2}>
                          {
                            loadingCertificados ? (
                              <SoftBox display="flex" alignItems="center" justifyContent="center">
                                <CircularProgress color="inherit" size={20} /> 
                              </SoftBox>
                            ) : certificados?.certificados?.length > 0 ? (
                              certificados?.certificados?.map((item, index) => (   
                                <SoftBox key={index} py={2} borderBottom={index !== certificados?.certificados?.length - 1 ? "1px solid #ebebeb" : ""} display="flex" alignItems="center" justifyContent="space-between">
                                  <SoftBox display="flex">
                                    <Tooltip title={"Certificado"} placement="top">
                                      <SoftBox
                                        display="flex"
                                        alignItems="center"
                                        mr={2}
                                        sx={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        <SoftCircularProgress
                                          color={"success"}
                                          icon={"verified"}
                                          value={calculoAvanceProyecto(item) || 0}
                                        />
                                      </SoftBox>
                                    </Tooltip>
                                    <SoftBox lineHeight={1}>
                                      <SoftTypography variant="button">
                                        Certificado {item?.fecha ? dayjs(item?.fecha).format("DD-MM-YYYY") : ""}
                                      </SoftTypography>
                                      <SoftBox display="flex" alignItems="center">
                                        <SoftTypography variant="h5" fontWeight="bold">
                                          {calculoAvanceProyecto(item)}% de avance total
                                        </SoftTypography>
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftBox display="flex" gap={1}>
                                    {
                                      index == 0 && (
                                        <Link
                                        to={`/certificados/editarCertificado`}
                                        state={{
                                          idProyecto: selectedProyecto?.id,
                                          idPresupuesto: presupuestoActual?.id,
                                          numeroCertificado: certificados?.certificados?.length,
                                          certificadoActual: item,
                                          certificadoAnterior: certificados?.certificados[index + 1] ? certificados?.certificados[index + 1] : [],
                                        }}
                                        >
                                          <SoftBox>
                                            <Tooltip title="Editar Certificado" placement="top">
                                              <SoftButton color="sistemasGris" circular iconOnly>
                                                <Icon>edit</Icon>
                                              </SoftButton>
                                            </Tooltip>
                                          </SoftBox>
                                        </Link>
                                      )
                                    }
                                    <SoftBox>
                                      <Tooltip title="Ver Certificado" placement="top">
                                        <SoftButton color="primary" circular iconOnly 
                                        onClick={()=>{
                                          setModalVerCertificado(true);
                                          setCertificadoSelect({...item, certificadoNumero: certificados?.certificados?.length - index});
                                        }}
                                        >
                                          <Icon>picture_as_pdf</Icon>
                                        </SoftButton>
                                      </Tooltip>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              ))
                            ) : (
                              <SinDatos/>
                            )
                          }
                          
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </Grid>                
                </Grid>
              </SoftBox>

              <SoftBox
                display="flex"
                justifyContent="end"
                alignItems="center"
              >
                <SoftBox
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="sistemasGris"
                    circular
                    onClick={handleClose}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>close</Icon>
                    &nbsp;Cerrar
                  </SoftButton>
                </SoftBox>
              </SoftBox>
              <ModalVerCertificado
                open={modalVerCertificado}
                handleClose={()=>{
                  setModalVerCertificado(false);
                  setCertificadoSelect(null);
                }}
                proyecto={certificadoSelect}
                idProyecto={selectedProyecto?.id ? parseInt(selectedProyecto?.id) : null}
              />
            </SoftBox>
          </Card>
      </Fade>
    </Modal>
  );
}

ModalReportes.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedProyecto: PropTypes.object,
};
