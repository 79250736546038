// React
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import CertificadoPDF from "../../PDF/Certificado";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
//   width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
  py: 3,
  px: 4
};

export default function ModalVerCertificado({ open, handleClose, proyecto, idProyecto}) {
  const [file, setFile] = useState(null);
  const [infoCertificado, setInfoCertificado] = useState({});

  const [getProyecto, { loading, error, data: dataProyecto }] = useLazyQuery(
    gql`
      query getProyecto($id: ID!) {
        proyecto(id: $id) {
          id
          nombre
          tipo
          fechaInicioEstimada
          fechaFinEstimada
          vendedor{
            nombre
            apellido
          }
          cliente{
            nombre
            apellido
            tipoCliente
            razonSocial
          }
          direccion
          localidad{
            nombre
            provincia{
                nombre
            }
          }
          responsable{
            nombre
            apellido
          }
          presupuestoAprobadoLast{
            numero
            nombreContacto
            ordenCompra
            nroContrato
            fechaInicioEstimada
            fechaFinEstimada
            terminosPago
            importeDeposito
            version
          }
        }
      }
    `,
    {
      variables: {
        id: idProyecto,
      },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (open) {
        getProyecto();
        formatoProyecto();
    }else{
        setFile(null);
        setInfoCertificado({});
    }
  }, [open]);

  const formatoProyecto = () => {
    let etapas = [];

    proyecto?.fases?.map((fase)=>{
      if(!etapas?.some((item)=> item?.id === fase?.etapa?.id)){
        etapas.push({
          ...fase?.etapa,
          numeroFases: [fase?.numero]
        });
      }else{
        const index = etapas?.findIndex((item)=> item?.id === fase?.etapa?.id);
        etapas[index].numeroFases = [...etapas[index].numeroFases, fase?.numero];
      }
    });

    setInfoCertificado({
        ...proyecto,
        etapas: etapas,
    });
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Certificado de avance de obra - Certificado Nro. {infoCertificado?.certificadoNumero}</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={12}>
                <CertificadoPDF 
                    proyecto={infoCertificado} 
                    dataProyecto={dataProyecto?.proyecto}
                    setFile={setFile} 
                />
              </Grid>
            </Grid>
            
            <SoftBox
            display="flex"
            justifyContent="end"
            alignItems="center"
            mt={2}
            >
                <SoftBox>
                    <SoftButton
                    color="primary"
                    circular
                    onClick={handleClose}
                    sx={{mr:2}}
                    >
                        <Icon sx={{ fontWeight: "light" }}>
                            close
                        </Icon>&nbsp;
                        Cerrar               
                    </SoftButton>
                </SoftBox>
                <SoftButton
                  color="sistemasGris"
                  circular
                  onClick={() => {
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(file);
                    link.download = `Certificado_Nro_${infoCertificado?.certificadoNumero}-${dataProyecto?.proyecto?.nombre}.pdf`;
                    link.click();
                  }}
                  disabled={file == null}
                >
                  Descargar PDF&nbsp;<Icon>download</Icon>
                </SoftButton>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalVerCertificado.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  proyecto: PropTypes.object,
  idProyecto: PropTypes.number
};
