// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { getUproColor } from "utils/colors";
import MEDIDAS from "utils/medidas";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
  p: 3,
};

const columnsMaterial = [
  { name: "material", desc: "Material", align: "left", noOrder: true },
  { name: "unidadMedida", desc: "Ud. de Medida", align: "left", noOrder: true, width: "125px" },
  { name: "cantidad", desc: "Cantidad", align: "left", noOrder: true, width: "150px" },
  { name: "moneda", desc: "Tipo de Moneda", align: "left", noOrder: true, width: "150px" },
  { name: "precio", desc: "Precio por unidad", align: "left", noOrder: true, width: "150px" },
  { name: "cotizacion", desc: "Cotización", align: "left", noOrder: true, width: "125px" },
  { name: "action", desc: " ", align: "left", noOrder: true, width: "1%" },
];

function MaterialesItem({ material, handleUpdate, handleDelete, pt, pb,cotizaciones,tipoMoneda,listProductos }) {
  const { borderWidth } = borders;
  const { size, fontWeightBold } = typography;

  const [materialData, setMaterialData] = useState({
    ...material,
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setMaterialData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleUpdate(materialData);
  }, [materialData]);

  useEffect(() => {
    if (materialData?.producto) {
      setMaterialData({
        ...materialData,
        precio: materialData?.precio ? materialData?.precio : (materialData?.producto?.precio || ""),
      });
    }
  }, [materialData?.producto]);

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pl={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <Select name="producto" 
        // value={materialData?.producto?.id} 
        value={
          materialData?.producto?.id
            ? listProductos?.find((producto) => producto.id === materialData?.producto?.id)
            : ""
        }
        onChange={handleChanges}>
          {listProductos?.map((producto) => (
            <MenuItem key={producto.id} value={producto}>
              {producto.nombre}
            </MenuItem>
          ))}
        </Select>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftInput
        value={materialData?.producto?.unidadMedida || ""}
        name="unidadMedida"
        label="unidadMedida"
        disabled
        />
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
            label="cantidad"
            value={materialData?.cantidad}
            name="cantidad"
            onChange={handleChanges}
            error={materialData?.cantidad === 0}
            icon={{
              component: "shopping_cart",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <Select
        name="tipoMoneda"
        value={materialData?.tipoMoneda || ""}
        onChange={(event)=>{
            setMaterialData({
                ...materialData,
                tipoMoneda: event.target.value,
                cotizacion: cotizaciones?.find(ctz => ctz?.nombre === event.target.value)?.venta || ((event.target.value === "Pesos" && tipoMoneda !== "Pesos") ? cotizaciones?.find(ctz => ctz?.nombre === tipoMoneda)?.venta : 1),
            })
        }}
        >
            <MenuItem value="Pesos">Pesos</MenuItem>
            {
                cotizaciones?.map((ctz) => {
                    return (
                        <MenuItem key={ctz?.nombre} value={ctz?.nombre}>
                            {ctz?.nombre}
                        </MenuItem>
                    )
                }) 
            }
        </Select>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
            label="precio"
            value={materialData?.precio}
            name="precio"
            onChange={handleChanges}
            icon={{
              component: "attach_money",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftInputNumber
          name="cotizacion"
          label="cotizacion"
          value={materialData?.cotizacion || ""}
          onChange={handleChanges}
            icon={{
              component: "currency_exchange",
              direction: "left",
            }}
          />
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pt={pt}
        pb={pb}
        pr={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid light`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"text-top"} variant="caption" color="dark" fontWeight="bold">
          <SoftButton color="error" circular iconOnly onClick={handleDelete}>
            <Icon>delete</Icon>
          </SoftButton>
        </SoftTypography>
      </SoftBox>
    </TableRow>
  );
}

MaterialesItem.propTypes = {
  material: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  pb: PropTypes.number,
  pt: PropTypes.number,
  cotizaciones: PropTypes.array.isRequired,
  tipoMoneda: PropTypes.string,
  listProductos: PropTypes.array.isRequired,
};

export default function ModalCargaMateriales({ open, handleClose, guardarFase ,tipoMoneda}) {
  const [servicio, setServicio] = useState();
  const { handleSnackbar } = useContext(MessageManager);
  const [arrayManoObra, setArrayManoObra] = useState([]);
  const [arrayMateriales, setArrayMateriales] = useState([]);
  const [arrayConceptos, setArrayConceptos] = useState([]);
  const { borderWidth } = borders;
  const { size, fontWeightBold } = typography;
  const { light } = colors;

  const handleChanges = (e) => {
    setServicio({
      ...servicio,
      [e.target.name]: e.target.value,
    });
  };

  const { data: productos } = useQuery(
    gql`
      query getProductos {
        productos {
          id
          nombre
          precio
          unidadMedida
        }
      }
    `
  );

  const [savePlantillaFase, { loading: loadingSave }] = useMutation(
    gql`
      mutation savePlantillaFase($input: PlantillaFaseInput!) {
        savePlantillaFase(input: $input) {
          id
        }
      }
    `
  );

  const resetModal = () => {
    setServicio({});
    setSelectedPlantilla(null);

    setArrayManoObra([]);
    setArrayMateriales([]);
    setArrayConceptos([]);
  };

  const [selectedPlantilla, setSelectedPlantilla] = useState(null);
  const [plantillaSinElegir, setPlantillaSinElegir] = useState(false);

 
  const {data:cotizacion} = useQuery(
    gql`
        query getCotizaciones{
            cotizaciones{
            agencia
            compra
            decimales
            fechaActualizacion
            nombre
            variacion
            variacion
            venta
            }
        }
    `
  );


  useEffect(() => {
    if (selectedPlantilla) {
      setPlantillaSinElegir(false);
    } else {
      setPlantillaSinElegir(true);
    }
  }, [selectedPlantilla]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Materiales aprobados del presupuesto</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        resetModal();
                        handleClose();
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox pb={4} pt={2}>
              <SoftBox mb={3}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                  <SoftTypography variant="h6">Materiales</SoftTypography>
                  <SoftButton
                    color="primary"
                    circular
                    onClick={() => {
                      let newArrayMateriales = [...arrayMateriales];
                      newArrayMateriales.push({
                        id: new Date().getTime(),
                        cantidad: "",
                        tipoMoneda: "Pesos",
                        precio: "",
                        cotizacion: cotizacion?.cotizaciones?.find((ctz)=> ctz?.nombre == tipoMoneda)?.venta || 1,
                      });
                      setArrayMateriales(newArrayMateriales);
                    }}
                  >
                    Agregar &nbsp;
                    <Icon>add</Icon>
                  </SoftButton>
                </SoftBox>
                <SoftBox my={2}>
                  {arrayMateriales.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          <TableRow>
                            {columnsMaterial.map(({ name, desc, align, width }, key) => {
                              let pl;
                              let pr;

                              if (key === 0) {
                                pl = 3;
                                pr = 3;
                              } else if (key === columnsMaterial.length - 1) {
                                pl = 3;
                                pr = 3;
                              } else {
                                pl = 1;
                                pr = 1;
                              }

                              return (
                                <SoftBox
                                  key={name}
                                  component="th"
                                  width={width || "auto"}
                                  pt={1.5}
                                  pb={1.25}
                                  pl={align === "left" ? pl : 3}
                                  pr={align === "right" ? pr : 3}
                                  textAlign={align}
                                  fontSize={size.xxs}
                                  fontWeight={fontWeightBold}
                                  color="secondary"
                                  opacity={0.7}
                                  borderBottom={`${borderWidth[1]} solid ${light.main}`}
                                >
                                  {desc ? desc.toUpperCase() : name.toUpperCase()}
                                </SoftBox>
                              );
                            })}
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          {arrayMateriales.map((material, index) => {
                            return (
                              <MaterialesItem
                                key={material.id}
                                material={material}
                                handleUpdate={(item) => {
                                  let newArrayMateriales = [...arrayMateriales];
                                  newArrayMateriales[index] = item;
                                  setArrayMateriales(newArrayMateriales);
                                }}
                                handleDelete={() => {
                                  let newArrayMateriales = [...arrayMateriales];
                                  newArrayMateriales.splice(index, 1);
                                  setArrayMateriales(newArrayMateriales);
                                }}
                                pb={index === arrayMateriales.length - 1 ? 2 : null}
                                pt={index === 0 ? 2 : null}
                                cotizaciones={cotizacion?.cotizaciones}
                                tipoMoneda={tipoMoneda}
                                listProductos={productos?.productos}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="flex-end"
              alignItems="center"
            >
              <SoftBox
                display={{
                  xs: "flex-row",
                  sm: "flex",
                }}
                justifyContent="end"
                alignItems="center"
              >
                <SoftBox
                  mr={{
                    xs: 0,
                    sm: 2,
                  }}
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="primary"
                    circular
                    onClick={() => {
                      handleClose();
                      resetModal();
                    }}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                    &nbsp;Cancelar
                  </SoftButton>
                </SoftBox>
                <SoftBox
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="sistemasGris"
                    circular
                    // disabled={
                    //   !servicio?.nombre ||
                    //   !servicio?.descripcion ||
                    //   !servicio?.nombreUnidad ||
                    //   (arrayManoObra.length === 0 && arrayMateriales.length === 0 && arrayConceptos.length === 0) ||
                    //   (
                    //     arrayManoObra.length > 0 &&
                    //     arrayManoObra.some(
                    //       (item) => !item.nombre || !item.horas || !item.tipoMoneda || !item.precio || !item.cotizacion || item.horas <= 0
                    //     ) 
                    //   ) ||
                    //   (
                    //     arrayMateriales.length > 0 && 
                    //     arrayMateriales.some(
                    //       (item) => !item.producto || !item.cantidad || !item.tipoMoneda || !item.precio || !item.cotizacion
                    //     ) 
                    //   ) ||
                    //   (
                    //     arrayConceptos.length > 0 && 
                    //     arrayConceptos.some(
                    //       (item) => !item.nombre || !item.tipoMoneda || !item.precio || !item.cotizacion
                    //     )
                    //   )
                    // }
                    onClick={() => {
                      handleClose();
                    }}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    {loadingSave ? (
                      <CircularProgress size={15} color="inherit" />
                    ) : (
                      <Icon sx={{ fontWeight: "light" }}>save</Icon>
                    )}
                    &nbsp;Guardar
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalCargaMateriales.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  guardarFase: PropTypes.func,
  tipoMoneda: PropTypes.string,
};
