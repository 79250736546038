import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import colors from "assets/theme/base/colors";
import { Icon } from "@mui/material";

function SoftCircularProgress(props) {
  const { gradients } = colors;

  return (
    <Box sx={{ position: "relative", display: "flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={gradients.primary.main} />
            <stop offset="100%" stopColor={gradients.primary.state} />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        variant="determinate"
        disableShrink
        sx={{
          "svg circle": { stroke: "url(#my_gradient)" },
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {props?.icon ? (
            <Icon
              color={props.color}
              fontSize="small"
              sx={{
                verticalAlign: "middle",
              }}
            >
              {props?.icon}
            </Icon>
          ) : (
            `${Math.round(props.value)}%`
          )}
        </Typography>
      </Box>
    </Box>
  );
}

SoftCircularProgress.defaultProps = {
  color: "primary",
};

SoftCircularProgress.propTypes = {
  icon: PropTypes.string,
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
};

export default SoftCircularProgress;
