// React
import PropTypes from "prop-types";

// @mui material components
import {
  Card,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Tooltip
} from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import { useEffect, useState } from "react";
import { formatMoneyPunto } from "utils/formatters";
import resumenData from "../../data/resumenData";
import { gql, useLazyQuery, useQuery} from "@apollo/client";
import { getUproColor } from "utils/colors";
import ModalAddEtapa from "./ModalAddEtapa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
 
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalCantidadFase({ open, handleClose, fase, guardarFase,plantilla,etapas }) {
  const [dataFase, setDataFase] = useState({
    cantidad: 0,
    porcentajeGanancia: 0,
  });
  const [rows, setRows] = useState([]);
  const { getRows, columns } = resumenData;
  const [plantillaSelect, setPlantillaSelect] = useState(null);
  const [infoEtapas,setInfoEtapas] = useState([]);
  const [modalAddEtapa,setModalAddEtapa] = useState(false);

  useEffect(() => {
    if (fase) {
      let content = [
        ...(fase?.manoDeObra || []), 
        ...(fase?.materiales || []), 
        ...(fase?.concepto || [])
      ];  
      content = content?.sort((a, b) => {
        if (a.horas && b.horas) return 0;
        if (a.horas) return 1;
        if (b.horas) return -1;
        return 0;
      });
      if(fase?.concepto?.length > 0 && fase?.manoDeObra?.length == 0 && fase?.materiales?.length == 0){
        setDataFase({
          ...dataFase,
          cantidad: 1,
        });
        setRows(getRows(content, 1));
      }else{
        setDataFase({
          ...dataFase,
        })
        setRows(getRows(content, dataFase.cantidad));
      }
    }else{
      if(plantillaSelect){
        let content = [
          ...(plantillaSelect?.manoDeObra || []), 
          ...(plantillaSelect?.materiales || []), 
          ...(plantillaSelect?.concepto || [])
        ];
        content = content?.sort((a, b) => {
          if (a.horas && b.horas) return 0;
          if (a.horas) return 1;
          if (b.horas) return -1;
          return 0;
        });
        setRows(getRows(content, dataFase.cantidad));
      }
    }
  }, [fase, dataFase.cantidad, plantillaSelect]);

  useEffect(() => {
    if(open && plantilla){
      getPlantillas();
    }
    if(open){
      setInfoEtapas(etapas);
    }else{
      setDataFase({
        cantidad: 0,
        porcentajeGanancia: 0,
        idEtapa: "",
      });
    }
  }, [open]);

  const getTotal = () => {
    let total = 0;
    // let content = fase ? fase?.manoDeObra?.concat(fase?.materiales) : plantillaSelect ? plantillaSelect?.manoDeObra?.concat(plantillaSelect?.materiales) : [];
    let content = [];
    if(fase){
      content = [
        ...(fase?.manoDeObra || []), 
        ...(fase?.materiales || []), 
        ...(fase?.concepto || [])
      ];
    }else{
      content = [
        ...(plantillaSelect?.manoDeObra || []), 
        ...(plantillaSelect?.materiales || []), 
        ...(plantillaSelect?.concepto || [])
      ];
    }
    content?.forEach((item) => {
      if (item.horas) {
        total += item.precio * item.horas * dataFase.cantidad || 0;
      } else if (item.cantidad) {
        total += item.precio * item.cantidad * dataFase.cantidad || 0;
      } else {
        total += item.precio || 0;
      }
    }
    );
    total += total * (dataFase?.porcentajeGanancia / 100) || 0;
    return total;
  };

  const [getPlantillas, { data: dataPlantillas, loading: loadingPlantillas }] = useLazyQuery(
    gql`
      query GetPlantillas($filter: JSON) {
        plantillasFases(filter: $filter) {
          id
          nombre
          descripcion
          nombreUnidad
          manoDeObra {
            id
            tipoMoneda
            precio
            cotizacion
            nombre
            horas
          }
          materiales {
            id
            nombre
            cantidad
            tipoMoneda
            precio
            cotizacion  
            producto{
              id
              nombre
              precio
              unidadMedida
            }
          }
        }
      }
    `
  );

  const {data:cotizacion} = useQuery(
    gql`
        query getCotizaciones{
            cotizaciones{
            agencia
            compra
            decimales
            fechaActualizacion
            nombre
            variacion
            variacion
            venta
            }
        }
    `,{
      fetchPolicy: 'no-cache'
    }
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPlantilla, setSelectedPlantilla] = useState(null);
  const [openPlanillaMenu, setOpenPlanillaMenu] = useState(false);

  const handleOpenPlanillaMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPlanillaMenu(true);
  };
  const handleClosePlanillaMenu = () => {
    setAnchorEl(null);
    setOpenPlanillaMenu(false);
  };

  const renderPlantillaMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openPlanillaMenu)}
      onClose={handleClosePlanillaMenu}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          maxHeight: "300px",
          overflowY: "scroll",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
    >
      {dataPlantillas?.plantillasFases?.map((plantilla, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            // setPlantillaSelect(plantilla);
            setPlantillaSelect(()=>{
              return {
                ...plantilla,
                manoDeObra: plantilla?.manoDeObra?.length > 0 ? handleActualizarMoneda(plantilla?.manoDeObra) : [],
                materiales: plantilla?.materiales?.length > 0 ? handleActualizarMoneda(plantilla.materiales) : [] ,
              }
            });
            setSelectedPlantilla(plantilla.id);
            handleClosePlanillaMenu();
          }}
          sx={{
            minWidth: { xs: "75vw", sm: "auto" },
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox mr={2}>
              <SoftTypography variant="p">{plantilla.nombre}</SoftTypography>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color:
                    plantilla.id === selectedPlantilla
                      ? getUproColor("sistemasAmarillo")
                      : getUproColor("sistemasGris"),
                }}
              >
                auto_awesome_mosaic
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
      ))}
    </Menu>
  );

  const actualizarMoneda = (detalleFase) => {
    const cotizacionActual = cotizacion?.cotizaciones?.find((ctz)=> ctz?.nombre == detalleFase?.tipoMoneda)?.venta || (detalleFase?.tipoMoneda == "Pesos CAC" ? detalleFase?.cotizacion : 1);
    let precioActual = (detalleFase?.tipoMoneda == "Pesos" || detalleFase?.tipoMoneda == "Pesos CAC") ? detalleFase?.precio : detalleFase?.precio * (cotizacionActual / detalleFase?.cotizacion);
    
    return({
      tipoMoneda: detalleFase?.tipoMoneda,
      precio: precioActual ? parseFloat(precioActual).toFixed(2) : 0,
      cotizacion: cotizacionActual
    })
  }

  const handleActualizarMoneda = (array)=>{
    let arrayActualizado = [];

    array.forEach(element => {
      arrayActualizado.push({
        ...element,
        ...actualizarMoneda(element)
      })
    });

    return arrayActualizado;
  }

  const resetModal = () => {
    setSelectedPlantilla(null);
    setPlantillaSelect(null);
    setOpenPlanillaMenu(false);
    setAnchorEl(null);
    setRows([]);
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Cantidad de items</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" 
                    onClick={() => {
                      handleClose();
                      resetModal();
                    }}
                    sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            {
              plantilla && (
                <SoftBox my={1}>
                  <Grid container spacing={2}>
                    <>
                      {renderPlantillaMenu}
                      <Grid item xs={12} sm={12}>
                        <SoftBox
                          display="flex"
                          alignItems="center"
                          onClick={
                            dataPlantillas?.plantillasFases?.length > 0 ? handleOpenPlanillaMenu : null
                          }
                          sx={{ maxWidth: "max-content" }}
                        >
                          <Icon color="primary"> auto_awesome_mosaic</Icon>
                          <SoftTypography
                            variant="h6"
                            sx={{
                              cursor: "pointer",
                            }}
                            ml={1}
                          >
                            {loadingPlantillas ? (
                              <Skeleton width={100} />
                            ) : dataPlantillas?.plantillasFases?.find(
                                (plantilla) => plantilla.id === selectedPlantilla
                              )?.nombre ? (
                              dataPlantillas?.plantillasFases?.find(
                                (plantilla) => plantilla.id === selectedPlantilla
                              ).nombre
                            ) : dataPlantillas?.plantillasFases?.length > 0 ? (
                              "Seleccione una Plantilla"
                            ) : (
                              "No hay plantillas disponibles"
                            )}
                          </SoftTypography>
                          <Icon color="dark">
                            {openPlanillaMenu ? "arrow_drop_up" : "arrow_drop_down"}
                          </Icon>
                        </SoftBox>
                      </Grid>
                    </>
                  </Grid>
                </SoftBox>
              )
            }

            <SoftBox mb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <InputLabel htmlFor="cantidad">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Etapa
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <InputLabel htmlFor="cantidad">
                      <SoftTypography
                        variant="caption"
                        color="text"
                        onClick={() => setModalAddEtapa(true)}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        Agregar etapa &nbsp;
                        <Icon
                          sx={{
                            verticalAlign: "middle",
                          }}
                          color="primary"
                        >
                          add
                        </Icon>
                      </SoftTypography>
                    </InputLabel>
                  </SoftBox>
                  <Select
                   label="Etapa"
                   name="etapa"
                   value={dataFase?.idEtapa || -1 }
                   onChange={(e) => {
                    setDataFase({
                      ...dataFase,
                      idEtapa: e.target.value,
                    });
                  }}
                  >
                    <MenuItem value={-1} disabled>Seleccione una etapa</MenuItem>
                    {
                      infoEtapas?.map((etapa, index) => (
                        <MenuItem key={index} value={etapa.id}>{etapa.nombre}</MenuItem>
                      ))
                    }
                  </Select>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="cantidad">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Cantidad {fase?.nombreUnidad ? `(${fase?.nombreUnidad})` : plantillaSelect?.nombreUnidad ? `(${plantillaSelect?.nombreUnidad})` : ""}
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="cantidad"
                    placeholder="Ingrese la cantidad"
                    type="number"
                    name="cantidad"
                    icon={{
                      component: "shopping_cart",
                      direction: "left",
                    }}
                    value={dataFase?.cantidad || ""}
                    error={dataFase?.cantidad != "" && dataFase?.cantidad <= 0}
                    onChange={(e) => {
                      setDataFase({
                        ...dataFase,
                        cantidad: e.target.value,
                      });
                    }}
                    disabled={(fase?.concepto?.length > 0 && fase?.manoDeObra?.length == 0 && fase?.materiales?.length == 0) || (plantillaSelect?.concepto?.length > 0 && plantillaSelect?.manoDeObra?.length == 0 && plantillaSelect?.materiales?.length == 0)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="porcentajeGanancia">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Margen ganancia (%)
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="porcentajeGanancia"
                    placeholder="Ingrese el porcentaje de ganancia"
                    type="number"
                    name="porcentajeGanancia"
                    icon={{
                      component: "percent",
                      direction: "left",
                    }}
                    value={dataFase?.porcentajeGanancia || ""}
                    error={dataFase?.porcentajeGanancia != "" && dataFase?.porcentajeGanancia < 0}
                    onChange={(e) => {
                      setDataFase({
                        ...dataFase,
                        porcentajeGanancia: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox mb={3}>
              <SoftBox mb={2}>
                <SoftTypography variant="h6">Materiales, Mano de obra y Conceptos</SoftTypography>
              </SoftBox>
              <SoftBox>
                <SoftBox
                  mb={2}
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                    minHeight: "100px",
                  }}
                >
                  <DataTable columns={columns} rows={rows} />
                </SoftBox>
                <SoftBox display="flex" justifyContent="flex-end">
                  <SoftBox>
                    <SoftTypography variant="h6" fontWeight="bold">
                      Total
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox ml={2}>
                    <SoftTypography variant="h6" fontWeight="bold">
                      {`$${formatMoneyPunto(getTotal())}`}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="flex-end"
              alignItems="center"
            >
              <SoftBox
                display={{
                  xs: "flex-row",
                  sm: "flex",
                }}
                justifyContent="end"
                alignItems="center"
              >
                <SoftBox
                  mr={{
                    xs: 0,
                    sm: 2,
                  }}
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="primary"
                    circular
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                    onClick={()=>{
                      handleClose();
                      resetModal();
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                    &nbsp;Cancelar
                  </SoftButton>
                </SoftBox>
                <SoftBox
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="sistemasGris"
                    circular
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                    onClick={() => {
                      if(plantilla){
                        guardarFase({
                          ...plantillaSelect, 
                          ...dataFase,
                          etapas: infoEtapas
                        });
                      }else{
                        guardarFase({
                          ...fase, 
                          ...dataFase,
                          etapas: infoEtapas
                        });
                      }
                      resetModal();
                    }}
                    disabled={((dataFase?.cantidad <= 0 || dataFase?.porcentajeGanancia < 0 || !dataFase?.idEtapa) && !plantilla) || (plantilla && (!plantillaSelect || (dataFase?.cantidad <= 0 || dataFase?.porcentajeGanancia < 0 || !dataFase?.idEtapa)))}
                  >
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                    &nbsp;Guardar
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>

          <ModalAddEtapa
          open={modalAddEtapa}
          handleClose={()=>{
            setModalAddEtapa(false);
          }}
          guardarEtapa={(nuevaEtapa)=>{
            setInfoEtapas([...infoEtapas, nuevaEtapa]);
            setDataFase({
              ...dataFase,
              idEtapa: nuevaEtapa?.id,
            });
            setModalAddEtapa(false);
          }}
          etapas={infoEtapas}
          />
        </Card>
      </Fade>
    </Modal>
  );
}

ModalCantidadFase.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  fase: PropTypes.object,
  guardarFase: PropTypes.func,
  plantilla: PropTypes.bool,
  etapas: PropTypes.array,
};
